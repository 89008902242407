import TripPackageCard from "components/TripPackage/TripPackageCard";
import { suggestedPackageList } from "controller/savedListController";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { setRelatedPackageList } from "store/states/relatedPackagesState";
import { httpErrorAction } from "utils/controllerResponseHandler";

export default function SuggestedPackageList() {
  const relatedPackageList = useAppSelector(
    (state) => state.relatedPackage.list
  );
  const [fetchingPackages, setFetchinPackages] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  async function handleSuggestPackageList() {
    setFetchinPackages(true);
    const res = await suggestedPackageList();
    setFetchinPackages(false);
    if (!instanceOfHttpResponse(res)) {
      dispatch(setRelatedPackageList(res));
    } else {
      httpErrorAction(res, navigate);
    }
  }
  useEffect(() => {
    if (relatedPackageList.length === 0) {
      handleSuggestPackageList();
    }
  }, [relatedPackageList.length]);
  if (fetchingPackages) {
    return (
      <div className="flex justify-center">
        <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-10 w-10 mt-2"></div>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {relatedPackageList.length > 0 &&
        relatedPackageList.map((data) => (
          <TripPackageCard key={data.id} data={data} />
        ))}
    </div>
  );
}
