import axios from 'axios';
import React, { useEffect, useState } from 'react'

export interface Statistic {
        id: string;
        heading: string;
        subHeading: string;
      }
 const PrivacypolicyPage = () => {

//         const [description,setDescription] = useState("")
//         const [heading_one,setHeading_one] = useState("")
//         const [heading_two,setHeading_two] = useState("")
//         const [heading_three,setHeading_three] = useState("")
//         const [heading_four,setHeading_four] = useState("")
//         const [heading_five,setHeading_five] = useState("")
//         const [heading_six,setHeading_six] = useState("")
//         const [heading_seven,setHeading_seven] = useState("")
//         const [heading_eight,setHeading_eight] = useState("")
//         const [content_one,setContent_one] = useState("")
//         const [content_two,setContent_two] = useState("")
//         const [content_three,setContent_three] = useState("")
//         const [content_four,setContent_four] = useState("")
//         const [content_five,setContent_five] = useState("")
//         const [content_six,setContent_six] = useState("")
//         const [content_seven,setContent_seven] = useState("")
//         const [content_eight,setContent_eight] = useState("")

//         useEffect(()=>{
//                 axios.get("/wft_admin/privacypolicy-list").then((response)=>{
//                   setDescription(response.data[0].description)
//                   setHeading_one(response.data[0].heading_one)
//                   setHeading_two(response.data[0].heading_two)
//                   setHeading_three(response.data[0].heading_three)
//                   setHeading_four(response.data[0].heading_four)
//                   setHeading_five(response.data[0].heading_five)
//                   setHeading_six(response.data[0].heading_six)
//                   setHeading_seven(response.data[0].heading_seven)
//                   setHeading_eight(response.data[0].heading_eight)
//                   setContent_one(response.data[0].content_one)
//                   setContent_two(response.data[0].content_two)
//                   setContent_three(response.data[0].content_three)
//                   setContent_four(response.data[0].content_four)
//                   setContent_five(response.data[0].content_five)
//                   setContent_six(response.data[0].content_six)
//                   setContent_seven(response.data[0].content_seven)
//                   setContent_eight(response.data[0].content_eight)
//                   console.log(response.data)
//                   console.log(heading_one,"Privacy Policy")
//                 }).catch((error)=>{
//                   alert(error)
//                 })
//               },[])
//               const FOUNDER_DEMO: Statistic[] = [
//                 {
//                   id: "1",
//                   heading: heading_one ,
//                   subHeading:content_one,
                    
//                 },
//                 {
//                   id: "2",
//                   heading: heading_two,
//                   subHeading: content_two,
//                 },
//                 {
//                   id: "3",
//                   heading: heading_three,
//                   subHeading: content_three
                    
//                 },
//                 {
//                   id: "4",
//                   heading: heading_four,
//                   subHeading: content_four
                    
//                 },
//                 {
//                   id: "5",
//                   heading: heading_five,
//                   subHeading: content_five
                    
//                 },
//                 {
//                   id: "6",
//                   heading: heading_six,
//                   subHeading: content_six
                    
//                 },
//                 {
//                   id: "7",
//                   heading: heading_seven,
//                   subHeading: content_seven
                    
//                 },
//                 {
//                   id: "8",
//                   heading: heading_eight,
//                   subHeading: content_eight
                    
//                 },
//               ];
  return (
    <div className='relative py-16 px-16'>
      <div className="container relative space-y-20 mb-24 lg:space-y-15 lg:mb-28">
        <div className='flex flex-col justify-center items-center'>
          <div className='not-italic font-bold text-5xl text-[#303A42]'>Privacy Policy</div>
          <div className='font-normal text-lg h-max mt-11 text-[#000000] break-words'>
            <div><span className='font-bold'>Wayfind Trips Private Limited </span> respects your privacy and is committed to protecting your personal data. This privacy policy is designed to tell you about our information collection practices – that is, the ways we collect information; what kinds of information we collect; why we collect information; how we use information we collect; with whom we share information we collect; how you can access, amend, and delete information we collect from you; and what kinds of security we use to protect information you provide.</div>
            <div className='mt-7'>It is important that you read this privacy policy together with any other privacy policy or fair processing notice that we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</div>
            <div className='mt-7'><span className='font-bold'>Wayfind Trips Private Limited </span> is the data controller and responsible for your personal data (referred to as “Wayfind Trips”, “we”, “us” or “our” in this privacy policy).</div>
            <div className='mt-7'>We have appointed a Data Privacy Manager who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights please contact the Data Privacy Manager using the details set out below.</div>
          </div>
        </div>
        <div>
        <div className='not-italic font-semibold text-xl text-[#303A42]'>Third-party links</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>THE DATA WE COLLECT ABOUT YOU</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</div>
          <ul className="list-disc font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5 md:ml-[10rem]">
            <li> <span className='font-semibold'>Identity Data</span> includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</li>
            <li> <span className='font-semibold'>Contact Data</span> includes billing address, delivery address, email address and telephone numbers.</li>
            <li> <span className='font-semibold'>Financial Data</span> includes bank account and payment card details.</li>
            <li> <span className='font-semibold'>Transaction Data</span> includes details about payments to and from you and other details of travel services you have purchased from us.</li>
            <li> <span className='font-semibold'>Technical Data</span> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
            <li> <span className='font-semibold'>Profile Data</span> includes your username and password, details of previous enquiries, itineraries or bookings made by you, your interests, preferences, feedback and survey responses.</li>
            <li> <span className='font-semibold'>Usage Data</span> includes information about how you use our website, products and services.</li>
            <li> <span className='font-semibold'>Marketing and Communications Data</span> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
          </ul>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>We also collect, use and share <span className='font-semibold'>Aggregated Data</span> such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</div>
          </div>
          <div>
          <div className='not-italic font-semibold text-xl text-[#303A42] mt-11'>Special category data :</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-7'><span className='font-bold'>Health/mobility data :</span> When you make an enquiry for a travel booking with us, we will ask you to/you may disclose any medical conditions/health issues or any restrictions on your mobility. This is to enable us (and the operators/suppliers of your holiday) to provide for your health, safety and welfare during your trip, which is our legal responsibility.  Any information of this type that you provide to us constitutes special category data under applicable data protection laws and so when you provide it to us, you give your consent to us using such information for the purpose of making your travel arrangements including our sharing of your information with the suppliers of your trip for this purpose (see section 5 Disclosure of your Personal Data).
If you choose not to disclose this information to us or your consent to use it for the outlined purposes, we may not be able to provide the services you have requested due to our legal responsibility to provide you with a safe holiday.
</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'><span className='font-bold'>Religious beliefs :</span> We will also ask you to disclose to us any dietary requirements that you have and you may also choose to disclose to us your religious beliefs if this informs your particular travel itinerary choices/interests. Religious beliefs constitute special category data. Accordingly, when you provide this information you confirm your consent to us using such information for the purpose of making your travel arrangements, including our sharing of your information with the suppliers of your trip for this purpose.
          </div>
          <div className='not-italic font-semibold text-xl text-[#303A42] mt-11'>IF YOU FAIL TO PROVIDE PERSONAL DATA</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</div>
          <div className='not-italic font-semibold text-xl text-[#303A42] mt-11'>HOW WE USE YOUR PERSONAL DATA </div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-2'>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</div>
          <ul className="list-disc font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5 md:ml-[5rem]">
            <li>Where we need to perform the contract we are about to enter into or have entered into with you (i.e. in order to service your travel enquiry or your booking).</li>
            <li> Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
            <li> Where we need to comply with a legal obligation.</li>
          </ul>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending direct marketing communications to you if you have not previously enquired or booked a holiday with us and in order to share your personal data with any third party for their marketing communications. </div>
          <div className='not-italic font-semibold text-xl text-[#303A42] mt-11'>PROMOTIONAL OFFERS FROM US</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).
              You will receive marketing communications from us if you have previously opted into receiving them from us, or if you have previously asked for a quote or made a booking with us, and you have not opted out of receiving that marketing.</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'><span className='font-bold'>Profiling : </span>As indicated in the above table, we may use your personal data to personalise and tailor your experience by using your previous requests for quotes, previous purchases, communications with Wayfind Trip staff and providers which have fulfilled part of your Wayfind travel bookings, your browsing activity, and other publicly available information (such as demographic data provided by an external provider), to make recommendations and special offers to you about products and services we think may be of interest to you based on your profile. If you do not want us to profile and process your information in this way, please contact our Data Privacy Manager (see section 1 Contact Details).</div>
          <div className='not-italic font-semibold text-xl text-[#303A42] mt-11'>COOKIES</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. </div>
          <div className='not-italic font-semibold text-xl text-[#303A42] mt-11'>CHANGES TO THIS POLICY</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>We reserve the right to change this Policy at any time and in our sole discretion, for example to take account of new data protection legislation, and to reflect our current business practices. If we do change the Policy, the new Policy will be posted here, along with its revision date. We encourage you to check this Policy periodically and – at a minimum – before each instance in which you provide personally identifiable information to us, in addition to any fair processing notice that we provide.</div>
</div>
    </div>
    </div>
    
  )
}

export default PrivacypolicyPage
