import { TripPackageType } from "core/Constants";
import { duration } from "moment";
import React, { Dispatch, SetStateAction } from "react";

interface Params {
  packageType: { packageType: TripPackageType; duration: string };
  setPackageType: Dispatch<
    SetStateAction<{ packageType: TripPackageType; duration: string }>
  >;
}

export default function TripPackageFilter({
  packageType,
  setPackageType,
}: Params) {
  return (
    <div className="overflow-hidden">
      <ul className="flex overflow-y-auto">
        <li
          className={`mx-4 ${
            packageType.packageType === TripPackageType.Economy &&
            "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button
            onClick={() =>
              setPackageType({
                ...packageType,
                packageType: TripPackageType.Economy,
              })
            }
          >
            Economy Packages
          </button>
        </li>
        <li
          className={`mx-4 ${
            packageType.packageType === TripPackageType.Delight &&
            "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button
            onClick={() =>
              setPackageType({
                ...packageType,
                packageType: TripPackageType.Delight,
              })
            }
          >
            Delight Packages
          </button>
        </li>
        <li
          className={`mx-4 ${
            packageType.packageType === TripPackageType.Luxury &&
            "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button
            onClick={() =>
              setPackageType({
                ...packageType,
                packageType: TripPackageType.Luxury,
              })
            }
          >
            Luxury Packages
          </button>
        </li>

        <li
          className={`mx-4 ${
            packageType.packageType === TripPackageType.Classic &&
            "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button
            onClick={() =>
              setPackageType({
                ...packageType,
                packageType: TripPackageType.Classic,
              })
            }
          >
            Classic Packages
          </button>
        </li>
        <li
          className={`mx-4 ${
            packageType.packageType === TripPackageType.Premium &&
            "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button
            onClick={() =>
              setPackageType({
                ...packageType,
                packageType: TripPackageType.Premium,
              })
            }
          >
            Premium Packages
          </button>
        </li>
        <li
          className={`mx-4 ${
            packageType.packageType === TripPackageType.Elite &&
            "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button
            onClick={() =>
              setPackageType({
                ...packageType,
                packageType: TripPackageType.Elite,
              })
            }
          >
            Elite Packages
          </button>
        </li>
      </ul>
    </div>
  );
}
