import React from 'react'
import FAQPage from './FAQPage'
import TicketPage from './TicketPage'

const PNRPage = () => {
  return (
    <div>
      <div className='relative py-16 px-16'>
        <TicketPage />
      </div>
      <div className='relative py-16 px-16'>
        <FAQPage />
      </div>
    </div>
  )
}

export default PNRPage
