export interface JobModel {
  id: number;
  title: string;
  description: string;
  location: string;
  jobType: string;
  postType: string;
  salary: string;
  responsibility: string;
  qualification: string;
}

export function createJobObj(data: any): JobModel {
  const result: JobModel = {
    id: data.id,
    title: data.title,
    description: data.description,
    location: data.location,
    jobType: data.job_type,
    postType: data.post_type,
    salary: data.salary,
    responsibility: data.responsibility,
    qualification: data.quialification,
  };

  return result;
}
