import React, { useEffect, useState } from 'react'
import FAQMain from "./FAQMain.js"
import axios from 'axios';

export interface FAQPageProps {
  className?: string;
}


/*const FAQData =[
  {
      id:1,
      question: 'What is Webflow and why is it the best website builder?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:2,
      question: 'What is your favorite template from BRIX Templates?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:3,
      question: 'What is your favorite template from BRIX Templates?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:4,
      question: 'How do you clone a template from the Showcase?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:5,
      question: 'How do you clone a template from the Showcase?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:6,
      question: 'What is Webflow and why is it the best website builder?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:7,
      question: 'Why is BRIX Templates the best Webflow agency?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:8,
      question: 'Why is BRIX Templates the best Webflow agency?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:9,
      question: 'When was Webflow officially launched?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:10,
      question: 'When was Webflow officially launched?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:11,
      question: 'How do you integrate Jetboost with Webflow?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:12,
      question: 'How do you integrate Jetboost with Webflow?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },

]*/

function FAQPage() {

  const [value,setValue] = useState<string[]>([])
  useEffect(()=>{
    axios.get("/wft_admin/faq-list?category=FAQ").then((response)=>{
      setValue(response.data)
      console.log(response.data)
      console.log(value,"FAQ Data")
    }).catch((error)=>{
      alert(error)
    })
  },[])
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='not-italic font-bold text-5xl text-[#303A42]'>FAQs</div>
      <div className='xl:grid xl:grid-cols-2 md:grid md:grid-cols-2 gap-11 mt-11'>
        {
          value.map((elem)=> {
            return (
              <FAQMain elem={elem}/>
            )
          })
        } 
      </div>
    </div>
  )
}

export default FAQPage 