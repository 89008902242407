import React, { useState } from "react";
import { ActivityModel } from "models/BookTripModel/ActivityModel";
import BookTripActivityListItem from "./BookTripActivityListItem";
import arrowSvg from "../../../images/arrow.svg";

export default function BookTripDayActivity({
  activity,
}: {
  activity: ActivityModel;
}) {
  const [visible, setVisible] = useState(false);
  return (
    <div className=" border-2 border-[#E5E7EB] rounded-2xl bg-[#FFFFFF] p-4 ">
      <button
        className="font-semibold text-lg sm:text-xl w-full text-left flex items-center justify-between"
        onClick={() => setVisible(!visible)}
      >
        <span>Day {activity.day}</span>
        <img src={arrowSvg} alt="arrow" height="25" width="25" className={`${visible && "transform rotate-180"}`} />
      </button>
      <div className={`overflow-hidden ${visible ? "h-auto" : "h-0"}`}>
        {activity.activityList.length > 0 &&
          activity.activityList.map((data, index) => (
            <div className="mt-8 border-b border-gray-300 pb-8" key={index} >
              <BookTripActivityListItem activity={data} />
            </div>
          ))}
      </div>
    </div>
  );
}
