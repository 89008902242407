import React from "react";
import jollyBuoyImg from "images/discoverplaces/jollybuoy/jollybouy.jpg";

export default function JollyBuoyIsland() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">
          Jolly Buoy Island
        </h1>
        <p className="mt-6 text-sm sm:text-base">
          Jolly Buoy Island is one of the most popular tourist destinations in
          the Andaman and Nicobar Islands. The island is famous for its clear
          blue waters, vibrant coral reefs, and diverse marine life, making it a
          paradise for snorkelling and scuba diving enthusiasts.
        </p>
      </div>
      <img
        src={jollyBuoyImg}
        alt="jollyBuoy"
        width="1080"
        height="800"
        className="w-full object-cover  h-96 md:h-[800px] rounded-md my-14"
      />
      <div className="w-3/4 mx-auto text-gray-700 text-sm sm:text-base space-y-4">
        <p>
          Located in the Mahatma Gandhi Marine National Park, Jolly Buoy Island
          is accessible only by boat, which adds to its allure as a remote and
          untouched destination. The island is surrounded by crystal-clear
          waters and pristine white sand beaches, making it a perfect place to
          relax and soak up the sun.
        </p>
        <p>
          One of the most popular activities on Jolly Buoy Island is Glass
          Bottom Ride. The island's clear waters and diverse marine life make it
          an ideal place to explore the underwater world. Visitors can book the
          glass bottom ride from the tour operators, and explore the coral reefs
          and schools of colourful fish that call these waters home.
        </p>
        <p>
          One of the highlights of visiting Jolly Buoy Island is the chance to
          see the exotic marine life that inhabits these waters. Visitors can
          spot a variety of marine creatures, including sea turtles, dolphins,
          and several species of colourful fish.
        </p>
        <p>
          In addition to its stunning natural beauty, Jolly Buoy Island is also
          home to a variety of plant and animal species, including several rare
          and endangered species. The island is a part of the Mahatma Gandhi
          Marine National Park, which was established in 1983 to protect the
          unique marine ecosystem of the Andaman and Nicobar Islands.
        </p>
        <p>
          To protect the fragile ecosystem of Jolly Buoy Island, visitors are
          only allowed to stay on the island for a limited period of time. The
          island is open for tourism from November to May, and only a limited
          number of visitors are allowed each day. Visitors are also not allowed
          to bring any plastic items or food to the island, to minimize the
          impact of tourism on the local environment.
        </p>
        <p>
          To reach Jolly Buoy Island, visitors must first obtain a permit from
          the Andaman and Nicobar Islands administration. The permit can be
          obtained from the Forest Department in Port Blair or from tour
          operators who organize trips to the island.
        </p>
        <p>
          In conclusion, Jolly Buoy Island is a must-visit destination for
          anyone traveling to the Andaman and Nicobar Islands. Its stunning
          natural beauty, diverse marine life, and serene atmosphere make it a
          perfect place to relax and unwind. Visitors should be mindful of the
          island's fragile ecosystem and take steps to minimize their impact on
          the local environment. With its crystal-clear waters, vibrant coral
          reefs, and exotic marine life, Jolly Buoy Island is truly a paradise
          on earth.
        </p>
      </div>
    </div>
  );
}
