import React from "react";
import bookMarkLine from "../../images/bookmark-line.svg";
import bookMarkFill from "../../images/bookmark-fill.svg";

export default function BookMark({
  isBookMarked,
  onClick,
}: {
  isBookMarked: boolean;
  onClick: () => void;
}) {
  return (
    <button onClick={onClick}>
      {!isBookMarked ? (
        <img src={bookMarkLine} alt="bookmark" height="20" width="20" />
      ) : (
        <img src={bookMarkFill} alt="bookmark" height="20" width="20" />
      )}
    </button>
  );
}
