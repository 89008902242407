import React from "react";
import { sanitize } from "dompurify";

const sanitizeHTML = (data: string) => {
  const pureHTML = JSON.parse(data);
  const sanitizedDOM = sanitize(pureHTML);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedDOM }} />;
};

export default function ViewRichText({ data }: { data: string }) {
  return (
    <div className="prose prose-sm text-justify prose-p:my-0">
      {sanitizeHTML(data)}
    </div>
  );
}
