import { fetchProfileInfo } from "controller/profileController";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { setProfileData } from "store/states/profileInfoState";
import { httpErrorAction } from "utils/controllerResponseHandler";

export default function useFetchProfileInfo() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLogin } = useAppSelector((state) => state.login);
  const { email } = useAppSelector((state) => state.profileInfo);

  async function handleProfileInfo() {
    const res = await fetchProfileInfo();
    if (!instanceOfHttpResponse(res)) {
      dispatch(setProfileData(res));
    } else {
      httpErrorAction(res, navigate);
    }
  }

  useEffect(() => {
    if (email === "" && isLogin === true) {
      handleProfileInfo();
    }
  }, [isLogin, email]);
  return <div>useFetchProfileInfo</div>;
}
