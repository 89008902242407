import React, { useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import Portfolio from "images/Portfolio.png";
import axios from "axios";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const data = {
    name: name,
    email: email,
    message: message,
    page_name: "contact",
  };

  const handleAPI = () => {
    axios
      .post("/wft_user/contactform-create", data)
      .then((res) => {})
      .catch((err) => {
        alert("Server Error");
      });
  };
  return (
    <div>
      <div className="relative flex flex-col lg:flex-row lg:items-center mt-8">
        <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
          <div className="font-semibold text-3xl sm:text-4xl">📄Contact Form</div>
          <div className="mt-8">
            <label className="font-normal text-xl">Full Name</label>
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Your Name"
              className="mt-2"
            />
          </div>
          <div className="mt-4">
            <label className="font-normal text-xl">Email address</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Your Email"
              className="mt-2"
            />
          </div>
          <div className="mt-4">
            <label className="font-normal text-xl mt-11">Message</label>
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              id="notes"
              name="quick_notes"
              placeholder="Describe your query here"
              className="mt-2 h-auto"
            />
          </div>
          <div
            onClick={handleAPI}
            className="w-[210px] h-[50px] border-2 rounded-full bg-[#0E1B43] mt-5 justify-center items-center flex cursor-pointer"
          >
            <div className="text-white">Send Message</div>
          </div>
        </div>
        <div>
          <NcImage src={Portfolio} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
