import { TripPackageModel } from 'models/TripPackageModel'


const OverviewCard = ({tripPackage}:{tripPackage:TripPackageModel}) => {


  return (
    <div>
      <div className="border-2 border-[#E5E7EB] rounded-2xl bg-[#FFFFFF] mt-11">
      <div className='m-8 font-semibold text-2xl text-[#111827]'>Activity</div>
      <div className="inline-grid  grid-cols-2 sm:grid-cols-3 gap-8 m-8">
       {tripPackage.activity.split(",").map((data,index)=><div key={index} className=' rounded-3xl text-center text-sm sm:text-base md:text-lg'>{data}</div>)}
        {/* ---- 
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block ml-[5rem]">
            <img className="las la-bath text-lg" src={TV} alt=''/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Television
          </span>
        </div>

        {/* ----
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block ml-[7rem]">
            <img className="las la-expand-arrows-alt text-lg" src={Harmony} alt=''/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Harmony
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <img className="las la-bed text-lg" src={Shampoo} alt=''/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Shampoo, conditioner
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block ml-[5rem]">
            <img className="las la-bed text-lg" src={Towel} alt=''/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Towel
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block ml-[7rem]">
            <img className="las la-bed text-lg" src={Toothpaste} alt=''/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Toothpaste
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <img className="las la-bed text-lg" src={Soap} alt=''/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Soap
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block ml-[5rem]">
            <img className="las la-bed text-lg" src={Dryer} alt=''/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Dryer
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block ml-[7rem]">
            <img className="las la-bed text-lg" src={Internet} alt=''/>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
          Internet
          </span>
        </div>*/}
      </div>
      {/*<div className='xl:w-[245px] xl:h-[50px] md:w-[245px] md:h-[50px] w-[151px] h-[50px] border-2 ml-8 mb-8 rounded-full justify-center items-center border-[#D1D5DB]'>
        <div className='text-base font-medium text-[#374151] xl:ml-6 xl:mt-3 md:ml-6 md:mt-3 ml-6 mt-0.5'>View more 20 amenities </div>
      </div>*/}
      </div>
    </div>
  )
}

export default OverviewCard
