import { message } from "antd";
import BookMark from "components/bookmark/BookMark";
import { bookMarkPackage } from "controller/TripPackageController";
import { TripPackageModel } from "models/TripPackageModel";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/reduxHooks";
import { httpErrorAction } from "utils/controllerResponseHandler";

export default function BookMarkPackage({
  packageData,
  removeBookMarkAction,
}: {
  packageData: TripPackageModel;
  removeBookMarkAction?: (data:TripPackageModel) => void;
}) {
  const [bookMarked, setBookMarked] = useState(packageData.isBookMarked);
  const navigate = useNavigate();
  const { isVefified } = useAppSelector((state) => state.profileInfo);
  const { isLogin } = useAppSelector((state) => state.login);

  async function handlePackageBookMark() {
    if (isVefified && isLogin) {
      const res = await bookMarkPackage(packageData.id);
      if (res.statusCode === 200) {
        message.success(res.success);
        setBookMarked(!bookMarked);
        removeBookMarkAction && removeBookMarkAction(packageData);
      } else {
        httpErrorAction(res, navigate);
      }
    } else if (!isVefified && isLogin) {
      navigate("/email-verification");
    } else {
      navigate("/login");
    }
  }

  return <BookMark isBookMarked={bookMarked} onClick={handlePackageBookMark} />;
}
