import GallerySlider from "components/GallerySlider/GallerySlider";
import { placeHolderImages } from "data/placeHolderImages";
import { TaxonomyType } from "data/types";
import React from "react";

export default function HotelCard({ data }: { data: TaxonomyType  }) {

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
      data-nc-id="PropertyCardH"
    >
      <div className="h-full w-full flex flex-col sm:flex-row">
        <div className="flex-shrink-0 p-3 w-full sm:w-64">
          <GallerySlider
            ratioClass="aspect-w-1 aspect-h-1"
            galleryImgs={data.thumbnail ? [data.thumbnail] : placeHolderImages}
            className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
            uniqueID={`PropertyCardH_${data.id}`}
            href={data.href}
          />

        </div>

        <div className="flex-grow p-3 sm:pr-6 flex flex-col justify-between  h-full">
          <div>
            <div className="flex flex-col ">
              {data.location && <p className="text-sm text-[#6B7280]">{data.location}</p>}
              <h1 className="text-xl font-semibold">{data.name}</h1>
            </div>
              {data.desc && (
                <h2 className="text-sm font-medium capitalize mt-4">
                  <span className="line-clamp-2 text-[#6B7280]">
                    {data.desc}
                  </span>
                </h2>
              )}
          
          </div>
          {/* <div className="sm:mt-0 mt-12 flex justify-end">
            <button className="px-6 py-2 border border-slate-800 hover:bg-slate-800 hover:text-white transition-all duration-300">See package</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
