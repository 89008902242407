const domain = "/wft_admin";

export const signUpAPI = `/auth/user/register`;
export const signInAPI = "/auth/user/login";
export const emailVerificationAPI = "auth/user/email/verification";
export const resendEmailVerificationOtpAPI = "auth/user/resend/otp";
export const forgotPasswordOtpSendAPI = "auth/user/forgot/password";
export const forgotPasswordVerifyOtpAPI = "auth/user/forgot/password/verify";
export const resetPasswordAPI = "auth/user/reset/password";
export const profileInfoAPI = "auth/user/profile";
export const bookMarkPackageAPI = "/wft_user/user-bookmark";
export const profileInfoUpdateAPI = "auth/user/profile/update";
export const bookTripDayDetailsApi = `${domain}/itinerary-detail`;
export const blogApi = `${domain}/blog-list?blog_type=Live`;
export const blogDetailApi = `${domain}/blog-detail`;
export const sightSeedingApi = `${domain}/sightseeing-list`;
export const hotelApi = `${domain}/accommodation-list`;
export const packageApi = `${domain}/itinerary-list`;
export const bookMarkedPackageListAPI = "wft_user/user-bookmark-list";
export const suggestedPackageListAPI = "wft_user/user-package-suggestion";
export const userTripListAPI = "wft_user/user-trip-list";
export const cancelTripAPI = "wft_user/trip-cancel-request";
export const jobListAPI = "wft_user/join-us-list";
export const jobDetailsAPI = "wft_user/join-us-detail";
export const sendJobApplicantDetailAPI = "wft_user/join-apply-create";
