import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  isLogin: boolean | null;
}

const initialState: InitialState = {
  isLogin: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
  },
});

export default loginSlice.reducer;

export const { setIsLogin } = loginSlice.actions;
