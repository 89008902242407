import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import footerLogoImg from "images/footerlogo.png";
import { Link } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Company",
    menus: [
      { href: "/about", label: "About Us" },
      { href: "/contactus", label: "Contact Us" },
      // { href: "/portfolio", label: "Portfolio" },
      { href: "/faq", label: "FAQ’s" },
      { href: "/career", label: "Career" },
    ],
  },
  {
    id: "1",
    title: "Resource",
    menus: [
      { href: "/review", label: "Write a Review" },
      { href: "/cancellationpolicy", label: "Cancellation Policy" },
      { href: "/privacy", label: "Privacy Policy" },
      { href: "/termscondition", label: "Terms & Conditions" },
    ],
  },
  {
    id: "2",
    title: "Contact Details ",
    menus: [
      { href: "#", label: "example@gmail.com" },
      { href: "#", label: "+91- 99999-99999" },
      { href: "#", label: "+91- 00000-00000" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {item.label === "example@gmail.com" ? (
                <a href="mailto:hello@wayfindtrips.com">
                  <button className="flex gap-2">
                    <img
                      src="Mail.png"
                      alt=""
                      className="w-[15px] h-[13px]"
                    ></img>{" "}
                    {"hello@wayfindtrips.com"}
                  </button>
                </a>
              ) : item.label === "+91- 99999-99999" ? (
                <a href="https://api.whatsapp.com/send/?phone=917400013770">
                  <button className="flex gap-2">
                    <img
                      src="Whatsapp.png"
                      alt=""
                      className="w-[15px] h-[15px]"
                    ></img>{" "}
                    {"+91-7400027937"}
                  </button>
                </a>
              ) : item.label === "+91- 00000-00000" ? (
                <a href="tel:+91-7400027947">
                  <button className="flex gap-2">
                    <img
                      src="Mobile.png"
                      alt=""
                      className="w-[11px] h-[17px]"
                    ></img>{" "}
                    {"+91-7400027947"}
                  </button>
                </a>
              ) : (
                <Link to={item.href}>{item.label}</Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container mb-4">
        <img src={footerLogoImg} alt="footerlogo" height="24" width="150" />
      </div>
      <div className="container lg:flex items-center justify-between">
        <div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        <div className=" grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8  md:gap-x-8 md:mt-6 mt-6 ">
          {widgetMenus.map(renderWidgetMenuItem)}
        </div>
      </div>
      <div className="container mt-20">
        <h4 className="text-center text-gray-600">
          Wayfindtrips 2023 @ All Right Reserved
        </h4>
      </div>
    </div>
  );
};

export default Footer;
