import { SightSeeingModel } from "models/BookTripModel/sightSeeingModel";
import mapPin from "../../../images/map-pin.svg";

export default function BookTripSightSeen({
  sightSeen,
}: {
  sightSeen: SightSeeingModel;
}) {
  return (
    <div>
      <div className="flex items-center">
        <img src={mapPin} alt="mapPin" height="15" width="15" />
        <p className="ml-2">
          {sightSeen.name}-{sightSeen.location}-{sightSeen.duration} hr
        </p>
      </div>
      {sightSeen.heading !== null && <p className="text-sm leading-1 text-gray-600 mt-2">{sightSeen.heading}</p>}
      <div>
        <div className="my-4">
          <img
            src={
              sightSeen.image !== null
                ? sightSeen.image
                : "https://picsum.photos/400/300"
            }
            alt="img"
            width="400"
            height="300"
            className="rounded-sm"
          />
        </div>
        <div className="ml-2">
          <h1 className="font-semibold text-2xl">About</h1>
          <p className="text-gray-600 text-sm mt-2">{sightSeen.description}</p>
        </div>
      </div>
    </div>
  );
}
