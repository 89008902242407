import portBlairImg from "images/discoverplaces/portblair/Portblair.jpg";
export default function PortBlair() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">Port Blair</h1>
        <p className="mt-6 text-sm sm:text-base text-justify">
          Port Blair is the capital city of the Andaman and Nicobar Islands,
          located in the Bay of Bengal. It is the largest city in the
          archipelago and serves as the administrative, cultural, and economic
          hub of the islands. Port Blair is known for its rich history, diverse
          culture, and stunning natural beauty.
        </p>
      </div>
      <img
        src={portBlairImg}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">Location and How to Reach</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base">
          Port Blair is located on the southern end of South Andaman Island, the
          largest island in the Andaman and Nicobar archipelago. The city is
          easily accessible by air and sea. Visitors can take a flight from
          major cities in India, such as Delhi, Kolkata, Chennai, and Bangalore,
          to Veer Savarkar International Airport in Port Blair. Alternatively,
          they can take a ferry from the Indian mainland to Port Blair.
        </p>
        <h2 className="text-xl font-semibold mt-14">Attractions</h2>
        <p className="mt-2 text-justif text-sm sm:text-base">
          Port Blair is known for its rich history, diverse culture, and
          stunning natural beauty. The city offers a range of attractions for
          visitors to explore. Some of the most popular attractions in Port
          Blair include:
        </p>
        <ol className=" list-decimal mt-2 space-y-2">
          <li>
            <span className="font-semibold">Cellular Jail - </span>The Cellular
            Jail, also known as Kala Pani, is a historical landmark in Port
            Blair. It was built by the British in the late 19th century and was
            used as a prison for Indian freedom fighters. Today, the jail is a
            national monument and a museum, where visitors can learn about the
            history of the Andaman and Nicobar Islands and the struggle for
            Indian independence.
          </li>
          <li>
            <span className="font-semibold">Ross Island - </span>Ross Island is
            a small island located near Port Blair. It was once the
            administrative headquarters of the British in the Andaman and
            Nicobar Islands. Today, the island is a popular tourist destination,
            known for its colonial-era ruins and natural beauty.
          </li>
          <li>
            <span className="font-semibold">
              Mahatma Gandhi Marine National Park -{" "}
            </span>
            The Mahatma Gandhi Marine National Park is located near Wandoor,
            about 30 kilometers from Port Blair. It is home to a variety of
            marine life, including corals, fish, and sea turtles. Visitors can
            go snorkeling and scuba diving to explore the underwater world or
            take a glass-bottom boat ride to see the marine life without getting
            wet.
          </li>
          <li>
            <span className="font-semibold">Anthropological Museum - </span>The
            Anthropological Museum is located in Port Blair and is dedicated to
            the indigenous tribes of the Andaman and Nicobar Islands. The museum
            has a collection of artifacts and exhibits that showcase the history
            and culture of the indigenous tribes, including their art, crafts,
            and way of life.
          </li>
          <li>
            <span className="font-semibold">Chatham Saw Mill - </span>The
            Chatham Saw Mill is located in Port Blair and is one of the oldest
            and largest sawmills in Asia. It was established in 1883 by the
            British and was used to process timber from the Andaman and Nicobar
            Islands. Today, the sawmill is a popular tourist attraction, where
            visitors can learn about the history of timber processing in the
            islands.
          </li>
          <li>
            <span className="font-semibold">Corbyn's Cove Beach - </span>
            Corbyn's Cove Beach is a popular beach in Port Blair, known for its
            white sand and clear water. Visitors can swim, sunbathe, and enjoy
            water sports, such as jet skiing and banana boat rides.
          </li>
          <li>
            <span className="font-semibold">
              Mount Harriet National Park -{" "}
            </span>
            The Mount Harriet National Park is located near Port Blair and is
            known for its stunning natural beauty. The park has a variety of
            flora and fauna, including rare species of birds and butterflies.
            Visitors can go hiking and trekking to explore the park and enjoy
            the views of the surrounding hills and forests.
          </li>
        </ol>
        <h2 className="text-xl font-semibold mt-14">Culture</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Port Blair is home to a diverse mix of cultures and ethnicities. The
            city has a significant population of Bengalis, Tamils, Telugus, and
            Malayalis, as well as indigenous tribes, such as the Great
            Andamanese, Onge, and Jarawa.
          </p>
          <p>
            Port Blair is also called a melting pot of different cultures and
            traditions, reflecting the diverse mix of ethnicities and
            communities that call the Andaman and Nicobar Islands home. The
            city's culture is a fusion of Indian, British, and indigenous
            influences, making it a unique and fascinating place to visit.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Indigenous Tribes</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            The Andaman and Nicobar Islands are home to several indigenous
            tribes, including the Great Andamanese, Onge, and Jarawa. These
            tribes have a rich and unique culture that has evolved over
            thousands of years of living in isolation on the islands.
          </p>
          <p>
            The Great Andamanese are the largest tribe in the Andaman Islands,
            with a population of around 50 people. They are believed to have
            lived on the islands for over 26,000 years and have a rich culture
            that includes traditional music, dance, and art. The Onge, who
            number around 100, are another indigenous tribe in the Andaman
            Islands. They are known for their unique language, which is not
            related to any other language in the world.
          </p>
          <p>
            The Jarawa are an indigenous tribe in the Andaman Islands who have
            been living in isolation for thousands of years. They are believed
            to be one of the last remaining hunter-gatherer tribes in the world
            and have a unique culture that includes traditional hunting methods,
            music, and dance. The Indian government has imposed restrictions on
            visiting their territory to protect their way of life.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Festivals</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Port Blair is home to a vibrant and diverse range of festivals,
            reflecting the city's multi-ethnic and multi-cultural makeup. Some
            of the most popular festivals in Port Blair include:
          </p>
          <ol className="list-decimal space-y-2">
            <li>
              <span className="font-semibold">Diwali - </span>Diwali is the festival of lights and is one
              of the most popular festivals in India. It is celebrated in Port
              Blair with great enthusiasm and includes lighting diyas (lamps),
              exchanging sweets and gifts, and setting off fireworks.
            </li>
            <li>
              <span className="font-semibold">Eid - </span>Eid is a Muslim festival that marks the end of
              Ramadan, the month of fasting. It is celebrated in Port Blair with
              feasting and prayers, and is a time for Muslims to come together
              and celebrate.
            </li>
            <li>
              <span className="font-semibold">Christmas - </span>Christmas is a popular festival in Port
              Blair, and is celebrated with decorations, carols, and feasting.
              The city's Christian population comes together to attend church
              services and exchange gifts.
            </li>
            <li>
              <span className="font-semibold">Pongal - </span>Pongal is a harvest festival that is
              celebrated in Tamil Nadu and other parts of South India. It is
              celebrated in Port Blair with traditional foods, such as pongal
              (sweet rice) and sugarcane, as well as traditional dances and
              music.
            </li>
            <li>
              <span className="font-semibold">Durga Puja - </span>Durga Puja is a Hindu festival that
              celebrates the victory of goddess Durga over the demon
              Mahishasura. It is celebrated in Port Blair with traditional puja
              (worship) ceremonies, cultural programs, and feasting.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
