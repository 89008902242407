import { message } from "antd";
import {
  resendEmailVerificationOtp,
  verifyEmail,
} from "controller/authController";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useAppSelector } from "store/reduxHooks";
import { setProfileData, setUserEmail } from "store/states/profileInfoState";
import { httpErrorAction } from "utils/controllerResponseHandler";
export default function EmailVerification() {
  const { email } = useAppSelector((state) => state.profileInfo);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleEmailVerification() {
    if (email !== "" && otp !== "") {
      const res = await verifyEmail(email, otp);
      if (!instanceOfHttpResponse(res)) {
        dispatch(setProfileData(res));
        message.success("Verified sucessfuly");
        navigate("/");
      } else {
        httpErrorAction(res, navigate);
      }
    } else {
      message.error("Please provide all inforamtion");
    }
  }

  async function handleResendEmailVerificationOtp() {
    const res = await resendEmailVerificationOtp(email);
    if (res.statusCode === 200) {
      message.success(res.success);
    } else {
      httpErrorAction(res, navigate);
    }
  }

  return (
    <div className="container mb-24 lg:mb-32">
      <h2 className="mb-6 mt-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Verify Email
      </h2>
      <div className="max-w-md mx-auto space-y-6 ">
        <div className="grid grid-cols-1 gap-6">
          {email === "" && (
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={email}
                onChange={(e) => dispatch(setUserEmail(e.target.value))}
              />
            </label>
          )}
          <label className="block">
            <Input
              type="password"
              placeholder="Paste your OTP"
              className="mt-1"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </label>
          <ButtonPrimary onClick={handleEmailVerification}>
            Verify
          </ButtonPrimary>
          <ButtonPrimary onClick={handleResendEmailVerificationOtp}>
            Resend OTP
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
}
