/***
 *  This function will covert string like this "6N7D" to
 *  ["6 nights", "7 days"]
 *
 */

export function stringToTripDayAndNight(data: string) {
  const timeArr = data.split(/[N,D]+/);
  return [`${timeArr[0]} Nights`, `${timeArr[1]} Days`];
}
