import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InitialState {
  detailsSent: boolean;
  peoples: {
    adult: number;
    infant: number;
    child: number;
  };
  data: {
    [key: string]: string;
  };

}

const initialState: InitialState = {
  detailsSent: false,
  peoples: {
    adult: 0,
    infant: 0,
    child: 0,
  },
  data: {},

};

const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    setDetailsSend: (state, action: PayloadAction<boolean>) => {
      state.detailsSent = action.payload;
    },
    setPeoples: (
      state,
      action: PayloadAction<{
        adult: number;
        infant: number;
        child: number;
      }>
    ) => {
      state.peoples = action.payload;
    },
    setData: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.data = action.payload;
    },

    resetEnquiryState:()=>initialState
  
  },
});

export default enquirySlice.reducer;
export const { setData, setDetailsSend, setPeoples,resetEnquiryState } =
  enquirySlice.actions;
