import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import { TaxonomyType } from "data/types";
import NextPrev from "shared/NextPrev/NextPrev";
import havelockImg from "images/discoverplaces/havelock/havelock-sm.jpg";
import neilImg from "images/discoverplaces/neil/neil-sm.jpg";
import rossImg from "images/discoverplaces/ross/ross-sm.jpg";
import portBlairImg from "images/discoverplaces/portblair/portblair-sm.jpg";
import jollybouyImg from "images/discoverplaces/jollybuoy/jollybouy-sm.jpg";
import digilpurImg from "images/discoverplaces/digilpur/diglipur-sm.jpg";
import baratangImg from "images/discoverplaces/baratang/Baratag-sm.jpg";
import longIsland from "images/discoverplaces/longisland/longIsland-sm.jpg";
import mountImg from "images/discoverplaces/mountharriet/mountharriet-sm.jpg";
import northBayImg from "images/discoverplaces/northbay/northbayisland-sm.jpg";
import ranghatImg from "images/discoverplaces/ranghat/rangat-sm.jpg";
const places: TaxonomyType[] = [
  {
    id: "1",
    href: "/discover/havelock-island",
    name: "Havelock Island ",
    taxonomy: "category",
    count: 17288,
    thumbnail: havelockImg,
  },
  {
    id: "2",
    href: "/discover/neil-island",
    name: "Neli Islands",
    taxonomy: "category",
    count: 2118,
    thumbnail: neilImg,
  },
  {
    id: "3",
    href: "/discover/ross-island",
    name: "Ross Island",
    taxonomy: "category",
    count: 36612,
    thumbnail: rossImg,
  },
  {
    id: "4",
    href: "/discover/port-blair",
    name: "Port Blair",
    taxonomy: "category",
    count: 188288,
    thumbnail: portBlairImg,
  },
  {
    id: "5",
    href: "/discover/jolly-buoy",
    name: "Jolly Buoy Island",
    taxonomy: "category",
    count: 188288,
    thumbnail: jollybouyImg,
  },
  {
    id: "6",
    href: "/discover/diglipur",
    name: "Diglipur",
    taxonomy: "category",
    count: 188288,
    thumbnail: digilpurImg,
  },
  {
    id: "7",
    href: "/discover/baratang-island",
    name: "Baratang Island",
    taxonomy: "category",
    count: 188288,
    thumbnail: baratangImg,
  },
  {
    id: "8",
    href: "/discover/long-island",
    name: "Long Island",
    taxonomy: "category",
    count: 188288,
    thumbnail: longIsland,
  },
  {
    id: "9",
    href: "/discover/mount-harriet",
    name: "Mount Harriet",
    taxonomy: "category",
    count: 188288,
    thumbnail: mountImg,
  },
  {
    id: "10",
    href: "/discover/north-bay-island",
    name: "North Bay Island",
    taxonomy: "category",
    count: 188288,
    thumbnail: northBayImg,
  },
  {
    id: "11",
    href: "/discover/rangat",
    name: "Rangat",
    taxonomy: "category",
    count: 188288,
    thumbnail: ranghatImg,
  },
];

export default function DiscoverPlaces() {
 
  return (
    <div>
   
        <SectionSliderNewCategories
          categories={places}
          heading="Discover Places in Andaman"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome2_s2"
        >
          <NextPrev className="justify-center mt-16" />
        </SectionSliderNewCategories>
    
    </div>
  );
}
