import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BlogModel } from "models/BlogModel/BlogModel";

interface InitialState {
  popularBlogList: BlogModel[];
  blogList: BlogModel[];
  blogListComplete: boolean; // This will indicate that all blogs are fetched
  blogListPage: number; // Hold the current page number. This will use as query param in the api for pagination
}

const initialState: InitialState = {
  popularBlogList: [],
  blogList: [],
  blogListComplete: false,
  blogListPage: 1,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setPopularBlogList: (state, action: PayloadAction<BlogModel[]>) => {
      state.popularBlogList = action.payload;
    },
    setBlogList: (state, action: PayloadAction<BlogModel[]>) => {
      state.blogList = [...state.blogList,...action.payload];
    },
    setBlogListComplete: (state, action: PayloadAction<boolean>) => {
      state.blogListComplete = action.payload;
    },
    setBlogListPage: (state, action: PayloadAction<number>) => {
      state.blogListPage = action.payload;
    },
  },
});

export default blogSlice.reducer;
export const {
  setBlogList,
  setPopularBlogList,
  setBlogListComplete,
  setBlogListPage,
} = blogSlice.actions;
