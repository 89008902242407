export enum Activities {
  ACCOMMODATION = "accommodation",
  SIGHTSEEING = "sightseeing",
  TRAVEL_BY_CAR = "travel_car",
  TRAVEL_BY_BUS = "travel_bus",
  TRAVEL_BY_FLIGHT = "travel_flight",
  TRAVEL_BY_FERRY = "travel_ferry",
  LUNCH = "meal_lunch",
  DINNER = "meal_dinner",
  ASSISTANCE = "assistance",
}

export enum TripPackageType {
  Empty = "",
  Luxury = "Luxury",
  Elite = "Elite",
  Economy= "Economy",
  Delight= "Delight",
  Classic= "Classic",
  Premium= "Premium",
  Popular= "Popular"

}
