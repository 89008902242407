import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Contact from "images/Contactus.png"

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Way Find Trips</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Still have queries 
Contact us
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
          <div>
              <form className="grid grid-cols-1 gap-6" action="#" method="post">
                <div className="flex space-x-5">
                  <label className="block">
                    <Label>First name</Label>

                    <Input
                      placeholder="First name"
                      type="text"
                      className="mt-1 bg-gray-200"
                    />
                  </label>
                  <label className="block">
                    <Label>Last name</Label>

                    <Input
                      placeholder="Last name"
                      type="text"
                      className="mt-1 bg-gray-200"
                    />
                  </label>
                </div>
                <label className="block">
                  <Label>Email / Phone Number</Label>

                  <Input
                    type="email"
                    placeholder="Enter your email or phone number"
                    className="mt-1 bg-gray-200"
                  />
                </label>
                <label className="block">
                  <Label>What can we help you with ?</Label>

                  <Textarea className="mt-1 bg-gray-200" rows={6} placeholder="Type here your message"/>
                </label>
                <div>
                  <ButtonPrimary type="submit" className="bg-[#41C5F2]">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
            <div>
              <img src={Contact} alt="" className="sm:block hidden"/>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/*<div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
  </div>*/}
    </div>
  );
};

export default PageContact;
