import { AccommodationModel } from "models/BookTripModel/AccomodationModel";
import hotelSvg from "../../../images/hotel.svg";

export default function BookTripAccommodation({
  accommodation,
}: {
  accommodation: AccommodationModel;
}) {
  return (
    <div>
      <div className="flex items-center">
        <img src={hotelSvg} alt="hotel" height={30} width={30} />
        <p className="ml-2">Hotel at {accommodation.area}</p>
      </div>
      {accommodation.heading !== null && <p className="text-sm leading-1 text-gray-600 mt-2">{accommodation.heading}</p>}
      <div className="mt-6 flex sm:flex-row flex-col">
        <img
          src={
            accommodation.image !== null
              ? accommodation.image
              : "https://picsum.photos/150/100"
          }
          alt="img"
          width="150"
          height="150"
          className="rounded-sm"
        />
        <div className="sm:ml-4">
          <h1 className="text-xl font-semibold sm:mt-0 mt-2">{accommodation.name}</h1>
          <p className="mt-2 text-gray-600 text-sm">{accommodation.area}</p>
          <div className="flex items-center mt-4">
            <div className="flex flex-col items-center">
              <p className="mt-2 text-gray-600 text-sm">Check In</p>
              <p className="mt-2 font-semibold text-sm">
                {accommodation.checkIn}
              </p>
            </div>
            <div className="flex flex-col items-center ml-8">
              <p className="mt-2 text-gray-600 text-sm">Check Out</p>
              <p className="mt-2 font-semibold text-sm">
                {accommodation.checkOut}
              </p>
            </div>
          </div>
        </div>
      </div>
      {accommodation.description !== null && (
        <div className="mt-6 flex flex-col ">
          <h4 className="font-semibold">Room Inclusion</h4>
          <p>{accommodation.description}</p>
        </div>
      )}
    </div>
  );
}
