import React from 'react'

const CancellationPolicy = () => {
  return (
    <div className='relative py-16 px-16'>
      <div className="container relative space-y-20 mb-24 lg:space-y-15 lg:mb-28">
        <div className='flex flex-col justify-center items-center'>
          <div className='not-italic font-bold text-4xl text-[#303A42]'>BOOKING CONDITIONS & CANCELLATION POLICIES: </div>
          <div className='font-normal text-lg h-max mt-11 text-[#000000] break-words'>
            <div>Thank you for visiting www.wayfindtrips.com. This site is owned and operated by WayFind Trips Private Limited group which has its registered office address at B-128, 1st Floor, Eastern Business DistrictNeptune Magnet Mall, LBS Marg, Bhandup (W) Mumbai: <span className='font-semibold'>400078 </span>
              CIN: <span className='font-semibold'>U63040MH2023PTC396754 </span> / GST: <span className='font-semibold'>27AADCW4503C1ZP </span> / PAN: <span className='font-semibold'>AADCW4503C</span>
            </div>
            <div className='mt-5'>When you make a booking for a Holiday/Vacations, your contract is with WayFind Trips Private Limited. In these terms and conditions “we” “us” or “our” mean WayFind Trips Private Limited and they form the basis of your contract with us. Please read them carefully as they are legally binding.</div>
          </div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>CONTRACT</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>
            <div>All our bookings are tailored to your requirements and so bookings (hotel/flights etc) are subject to availability. Before a booking is confirmed, you will receive a confirmation email where you will be inviting you to view full details of your proposed trip.</div>
            <div className='mt-5'>Once you have confirmed to us that you happy with the proposal, we will proceed to take payment and issue you with a booking confirmation. Please check that all names are the same as the relevant identity cards, that dates and timings and all other elements of your trip are correct. Do let us know for any apparent discrepancies in the confirmed arrangements. Hereby you will be responsible for any additional charges that may arise as a result of alterations that have to be made to the booking.</div>
            <div className='mt-5'>A binding contract between us and you (the lead name on the booking) is only formed when we issue you with our booking confirmation after having received advance payment from you. By making the payment  you accept that you have the authority to bind all members of your party to these terms and conditions of booking and you take responsibility as the lead name on the booking to make payment and to receive documentation on behalf of your party. </div>
          </div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>PRICE AND PAYMENT</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>
            <div>We reserve the right to alter the prices of any trips shown on our website or set out in our written proposal to you at any time before your booking is confirmed. You will be advised of the current price before your booking is confirmed. </div>
            <div className='mt-5'>The price of your experience is inclusive of taxes and where applicable, all additional fees and charges and other costs. Where any costs cannot be reasonably calculated by us in advance of the conclusion of your contract, we will give you an indication at the time of booking of the type of additional costs which you may still have to bear. You will be responsible for any bank charges that may be incurred due to your payment to us.</div>
            <div className='mt-5'>Where bookings include flights with airlines that require full payment in advance of booking, the full price of the flight(s) will be included in the booking deposit and will be chargeable in the event of cancellation as a cancellation fee.</div>
            <div className='mt-5'>In all cases, a booking will not be confirmed until we are in receipt of cleared funds as mentioned to you at the time of booking.</div>
          </div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>HEALTH FORMALITIES</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>
            <div>Not all trips and activities are appropriate for persons with a disability, reduced mobility or medical condition or allergies of any sort. If you or any member of your party have any disability, reduced mobility or medical condition or allergies of any sort, it is extremely important that you tell us before you book in order that we can ensure that the travel experience you are interested in is appropriate to and safe for your needs and make the necessary arrangements to make your experience go safely and smoothly.</div>
            <div className='mt-5'>All health and mobility information will only be used in order to advise you in relation to your booking and for passing to suppliers of your travel experience in accordance with our Privacy Policy. 
            Occasionally some of our trips or certain activities may have minimum or maximum age restrictions. This is usually for safety and insurance purposes. Where they do, we may need to ask the ages of your party to confirm whether the trip/activity is appropriate.</div>
          </div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>INFORMATION</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>Whilst we make every effort to ensure that the information on our website is accurate and not misleading, it may have been published many months before your travel experience takes place and may be subject to change. We reserve the right to make changes to the website and any information it contains at any time.</div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>ASSISTANCE</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>Whether or not we are liable for compensation, we will always provide you with appropriate assistance without undue delay if you are in difficulty. This will include providing appropriate information on health services, local authorities and consular assistance, assisting you to make distance communications and helping you to find alternative travel arrangements. We may charge you a reasonable fee for such assistance if the difficulty is caused intentionally by you or through your negligence. </div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>JURISDICTION</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>This contract is governed and construed in accordance with the laws of Mumbai, Maharashtra (state) applicable to contracts made and to be wholly performed within such state, without giving effect to any conflict of law’s provisions thereof. The Federal and state courts located in Mumbai, Maharashtra (state) shall have sole and exclusive jurisdiction over any disputes arising under, or in any way connected with or related to, the terms of this Agreement.</div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>AMENDMENT, TRANSFERS AND CANCELLATION BY YOU</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>Any cancellation or amendment request must be sent to us in writing to our postal address or by email to hello@wayfindtrips.com and will not take effect until received by us.</div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>Amendment</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>If after our booking confirmation has been issued, you wish to change your travel arrangements in any way, we will do our best to assist you in amending your arrangements after booking, but, we cannot guarantee that this will always be possible.
Any request for changes must be in writing from the person who made the booking. You may be asked to pay an administration charge of INR 1500 + GST  in addition to the costs we incur in making those amendments. If we are unable to make the amendments and you decide to cancel, we may require you to pay us cancellation charges as mentioned below.</div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>Transfers</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>You may transfer your booking to another person who satisfies all the conditions applicable to your travel contract.  We must receive at least 10 days’ written notice from you in order to make such a transfer. Any additional fees, charges or other costs incurred as a result of transferring your booking will be passed on to you with these costs being advised to you before we effect any transfer. We will also charge an amendment fee of INR 1000 + GST for any transfer. You and the person to whom you transfer your booking shall be jointly liable to us for the payment of the balance due and for any such additional fees, charges or other costs. </div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>Cancellation</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>You, or any member of your party, may cancel your travel arrangements at any time before the start of the package. The cancellation will take effect from the date that we receive written notification of cancellation from you. Cancellations may incur cancellation charges depending on how much notice you give us prior to the departure date.</div>
        
          <table className="border-collapse border border-slate-400 mt-11">
  <thead>
    <tr>
      <th className="border border-slate-300 px-6 py-3">Tenure (If cancelled from)</th>
      <th className="border border-slate-300 px-6 py-3">Amount to be charged</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="border border-slate-300 px-6 py-4">0 days to 15 days before the arrival</td>
      <td className="border border-slate-300 px-6 py-4">100% of the Package amount</td>
    </tr>
    <tr>
      <td className="border border-slate-300 px-6 py-4">15 days to 30 days before the arrival date</td>
      <td className="border border-slate-300 px-6 py-4">50% of the Package amount
</td>
    </tr>
    <tr>
      <td className="border border-slate-300 px-6 py-4">30 days or more before the arrival date</td>
      <td className="border border-slate-300 px-6 py-4">25% of the Package amount</td>
    </tr>
  </tbody>
</table>
<div className='not-italic font-semibold text-xl text-[#303A42] mt-11'>Important : </div>
<ul className="list-disc font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5 md:ml-[4rem]">
            <li>No refund for cancellation received on bookings from 15th Dec to 15th Jan.</li>
            <li>No refunds for unused nights or early departure.</li>
            <li>The above may vary based on any specific cancellation charges as charged by the third-party service provider.</li>
          </ul>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'> Cancellation charges are likely to increase the closer to departure date that the cancellation is made and may well be up to 100% of the total price of your booking and you should contact us as soon as possible. Cancellation charges for your trip can be confirmed to you on request at any time including before booking. Please ask for confirmation of the amount of any cancellation charge before cancellation. </div>
        </div>
        <div>
          <div className='not-italic font-semibold text-xl text-[#303A42]'>SPECIAL REQUESTS</div>
          <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5'>Whilst we will endeavour to comply with any special requests we receive (such as specific airline seating, dietary requirements or specific rooms) and will pass any special requests to the relevant supplier. However, we are unable to guarantee compliance with such requests and are not liable for any loss suffered in the event of such requests not being complied with.</div>
        </div>
      </div>
    </div>
  )
}

export default CancellationPolicy
