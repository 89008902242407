import { JobModel } from "models/JobModel";
import React from "react";
import moneyBagSvg from "../../images/career/moneybag.svg";
import clockSvg from "../../images/career/clock.svg";
import locationSvg from "../../images/career/location.svg";
import { Link } from "react-router-dom";

export default function JobCard({ job }: { job: JobModel }) {
  return (
    <div className="p-4 rounded-md shadow-md transform hover:scale-105 transition duration-300 cursor-pointer hover:shadow-xl">
      <h1 className="text-xl font-semibold">{job.title}</h1>

      <div className="mt-4">
        <div className="flex items-center space-x-6">
          <div className="flex items-center space-x-2">
            <img src={moneyBagSvg} alt="salary" width="10" height="10" />
            <p className="text-xs text-gray-600">{job.salary}</p>
          </div>
          <div className="flex items-center space-x-2">
            <img src={clockSvg} alt="job type" width="10" height="10" />
            <p className="text-xs text-gray-600">{job.jobType}</p>
          </div>
        </div>
        <div className="flex items-center mt-2">
          <div className="flex items-center space-x-2">
            <img src={locationSvg} alt="location" width="10" height="10" />
            <p className="text-xs text-gray-600">{job.location}</p>
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <Link to={`/career/${job.id}`}>
          <button className=" bg-orange-400 text-white px-6 py-1 text-sm rounded">
            Apply
          </button>
        </Link>
      </div>
    </div>
  );
}
