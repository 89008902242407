import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HavelockIsland from "./HavelockIsland";
import NeilIsland from "./NeilIsland";
import RossIsland from "./RossIsland";
import PortBlair from "./PortBlair";
import JollyBuoyIsland from "./JollyBuoyIsland";
import Diglipur from "./Diglipur";
import BaratangIsland from "./BaratangIsland";
import LongIsland from "./LongIsland";
import MountHarriet from "./MountHarriet";
import NorthBayIsland from "./NorthBayIsland";
import Ranghat from "./Ranghat";

export default function DiscoverPlace() {
  const { placeId } = useParams();
  const navigate = useNavigate();

  function renderPage(placeId: string) {
    switch (placeId) {
      case "havelock-island":
        return <HavelockIsland />;
      case "neil-island":
        return <NeilIsland />;
      case "ross-island":
        return <RossIsland />;
      case "port-blair":
        return <PortBlair />;
      case "jolly-buoy":
        return <JollyBuoyIsland />;
      case "diglipur":
        return <Diglipur />;
      case "baratang-island":
        return <BaratangIsland />;
      case "long-island":
        return <LongIsland />;
      case "mount-harriet":
        return <MountHarriet/>  
      case "north-bay-island":
        return <NorthBayIsland/>
      case "rangat":
        return <Ranghat/>
      default:
          return <p className="text-center">No page found🥹</p>      
    }
  }

  return (
    <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
      <div
        onClick={() => navigate(-1)}
        className="  cursor-pointer mt-6 text-gray-600"
      >
        <div>
          <KeyboardBackspaceIcon />
          <span className="ml-2">Back</span>
        </div>
      </div>
      <div className="mt-8">{placeId && renderPage(placeId)}</div>
    </div>
  );
}
