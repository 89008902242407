import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { setIsLogin } from "store/states/loginState";
import { getToken } from "utils/tokenhandler";

interface props {
  children: JSX.Element;
}

export default function AuthenticatedComponent({ children }: props) {
  const dispatch = useAppDispatch();
  const { isLogin } = useAppSelector((state) => state.login);
  
  useEffect(() => {
    if (isLogin === null) {
      const token = getToken();
      if (token !== null) {
        dispatch(setIsLogin(true));
      } else {
        dispatch(setIsLogin(false));
      }
    }
  }, []);

  if (isLogin) {
    return children;
  } else {
    return <></>;
  }
}
