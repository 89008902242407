import React, { FC, useState, useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategory3Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory3: FC<CardCategory3Props> = ({
  className = "",
  taxonomy,
}) => {
  const { count, name, href = "/", thumbnail, id } = taxonomy;
  return (
    <Link
      to={href}
      className={`nc-CardCategory3 flex flex-col ${className} h-96 sm:h-auto`}
      data-nc-id="CardCategory3"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-3/4 sm:h-auto rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={thumbnail}
          className=" object-fill w-full h-full rounded-2xl"
        />
        {/* <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-red-600 hover:bg-red-600 hover:bg-red-600 text-red-600 dark:text-red-600`}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 ml-[11rem]"
       /> */}
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 ">
        <h2
          className={`text-sm sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium break-all`}
        >
          {name}
        </h2>
        <span
          className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
        >
          {id === "1"
            ? "Just Fun Activities"
            : id === "2"
            ? "More Fun Activities"
            : id === "3"
            ? "Adventures in Andaman "
            : id === "4"
            ? "Enough time for Andaman"
            : id === "5"
            ? "Trips on your Tips"
            :id === "6"
            ?"Explore Andaman inch by inch" 
            : "Count " + convertNumbThousand(count || 0)}
        </span>
      </div>
    </Link>
  );
};

export default CardCategory3;
