import { TripPackageType } from "core/Constants";
import {
  bookMarkPackageAPI,
  cancelTripAPI,
  packageApi,
} from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { HttpResponse, instanceOfHttpResponse } from "models/HttpStatusModel";
import {
  TripPackageModel,
  createTripPackageObject,
} from "models/TripPackageModel";
import { bookTripDayDetailsApi } from "core/backend-api";
import {
  ActivityModel,
  createActivityObject,
} from "models/BookTripModel/ActivityModel";

export async function getAllPackages(
  type: TripPackageType,
  duration: string = "3N4D",
  sendToken: boolean
) {
  const res = await asyncHandler(
    type !== TripPackageType.Popular
      ? `${packageApi}?itinerary_type=${type}&duration=${duration}`
      : `${packageApi}?itinerary_type=${type}`,
    "GET",
    null,
    sendToken
  );
  const result: [TripPackageModel[] | null, HttpResponse | null] = [null, null];
  if (!instanceOfHttpResponse(res)) {
    const tripPackageArr: TripPackageModel[] = [];
    for (let data of res.data) {
      const tripPackage = createTripPackageObject(data);
      tripPackageArr.push(tripPackage);
    }
    result[0] = tripPackageArr;
  } else {
    result[1] = res;
  }
  return result;
}

/**
 *
 * @returns [ActivityModel[] | null, HttpStatus | null]
 *
 * fetch all of the activities
 *
 */

export async function getActivities(id: string) {
  const res = await asyncHandler(`${bookTripDayDetailsApi}/${id}`, "GET");
  const result: [ActivityModel[] | null, HttpResponse | null] = [[], null];
  if (!instanceOfHttpResponse(res)) {
    const resData = res.data.tour_detail;

    if (resData !== null && resData.length > 0) {
      const activityArr: ActivityModel[] = [];
      for (let x = 0; x < resData.length; x++) {
        const activity = createActivityObject(resData[x], x + 1);
        activityArr.push(activity);
      }
      result[0] = activityArr;
    }
  } else {
    result[1] = res;
  }
  return result;
}

export async function getTripPackageData(id: string, sendToken: boolean) {
  const res = await asyncHandler(
    `${bookTripDayDetailsApi}/${id}`,
    "GET",
    null,
    sendToken
  );
  const result: [TripPackageModel | null, HttpResponse | null] = [null, null];
  if (!instanceOfHttpResponse(res)) {
    const resData = res.data;
    const tripPackage = createTripPackageObject(resData);
    const activityArr: ActivityModel[] = [];
    const tourDetail = resData.tour_detail;
    if (tourDetail !== null && tourDetail.length > 0) {
      for (let x = 0; x < tourDetail.length; x++) {
        const activity = createActivityObject(tourDetail[x], x + 1);
        activityArr.push(activity);
      }
    }
    tripPackage.activities = activityArr;
    result[0] = tripPackage;
  } else {
    result[1] = res;
  }
  return result;
}

export async function bookMarkPackage(packageId: number) {
  const res = await asyncHandler(
    bookMarkPackageAPI,
    "POST",
    { package: packageId },
    true
  );
  if (!instanceOfHttpResponse(res)) {
    const result: HttpResponse = {
      type: "httpStatus",
      statusCode: res.status,
      error: null,
      success: res.data.message,
    };
    return result;
  } else {
    return res;
  }
}

export async function cancelTrip(tripId: number, cancelReason: string) {
  const res = await asyncHandler(
    `${cancelTripAPI}/${tripId}`,
    "POST",
    { cancel_reason: cancelReason },
    true
  );
  if (!instanceOfHttpResponse(res)) {
    const result: HttpResponse = {
      type: "httpStatus",
      statusCode: res.status,
      error: null,
      success: res.data.message,
    };
    return result;
  } else {
    return res;
  }
}
