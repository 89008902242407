import { message } from "antd";
import { HttpResponse } from "models/HttpStatusModel";
import { NavigateFunction } from "react-router-dom";




export function httpErrorAction(res: HttpResponse, navigate: NavigateFunction) {
  if (res.statusCode === 401) {
    navigate("/login");
  } else {
    message.error(res.error);
  }
}
