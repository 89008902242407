import baratangImg from "images/discoverplaces/baratang/Baratag.jpg";
export default function BaratangIsland() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">
          Baratang Island{" "}
        </h1>
        <p className="mt-6 text-sm sm:text-base">
          Baratang Island is a hidden gem of the Andaman and Nicobar Islands,
          offering visitors a chance to explore the natural beauty and unique
          attractions of this stunning archipelago. From the limestone caves to
          the mud volcanoes and the dense mangrove forests, Baratang Island has
          something to offer every traveller.
        </p>
      </div>
      <img
        src={baratangImg}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">Myths & Believes</h2>
        <div className="mt-2 text-justify  text-sm sm:text-base space-y-4">
          <p>
            The origin of the name Baratang Island is not entirely clear. Some
            believe that the name Baratang comes from the
            <span className="font-semibold">
              {" "}
              Burmese word "Bara Tang"{" "}
            </span>{" "}
            which means "the land of the big fig tree." This is because the
            island is home to a large number of fig trees that grow abundantly
            in the dense forests.
          </p>
          <p>
            Another theory is that the name Baratang is derived from the
            <span className="font-semibold">
              {" "}
              Hindi word "Barah Tang"{" "}
            </span>{" "}
            which means "12 channels." This is because the island is located in
            an area that has several channels or creeks that wind their way
            through the mangrove forests. These creeks are a popular attraction
            for tourists as they offer a unique opportunity to explore the rich
            biodiversity of the island.
          </p>
          <p>
            Yet another theory is that the name Baratang is derived from the
            local Nicobarese language. According to this theory, the name
            <span className="font-semibold">
              Baratang means "the place where the wind blows."{" "}
            </span>{" "}
            This is because the island is known for its strong winds that blow
            throughout the year, making it a popular destination for wind
            surfing and other water sports.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Historical Significance</h2>
        <div className="mt-2 text-justify  text-sm sm:text-base space-y-4">
          <p>
            The history of Baratang Island dates back to the prehistoric era, as
            it is believed that the island was inhabited by various indigenous
            tribes for thousands of years. The earliest recorded history of the
            island can be traced back to the 9th century when it was mentioned
            in the accounts of Arab traders who visited the Andaman and Nicobar
            Islands.
          </p>
          <p>
            During the colonial era, the British established a penal colony on
            the Andaman and Nicobar Islands in 1858. The purpose of the penal
            colony was to exile Indian freedom fighters who were fighting
            against the British rule. The British used the dense forests on
            Baratang Island to extract timber and set up a sawmill to process
            the wood.
          </p>
          <p>
            After India gained independence in 1947, the Andaman and Nicobar
            Islands became a part of India, and the Indian government took over
            the administration of the islands. The government established a
            number of settlements on the islands to provide livelihood to the
            local communities and to promote tourism.
          </p>
          <p>
            In the 1980s, the Indian government started a resettlement program
            for the indigenous tribes of the Andaman and Nicobar Islands. The
            program aimed to provide better living conditions and education to
            the tribes who were living in remote areas of the islands. The
            program faced opposition from some of the tribes who preferred to
            live in isolation and did not want to be integrated into the
            mainstream society.
          </p>
          <p>
            In 2004, Baratang Island was hit by a devastating tsunami that was
            triggered by a massive earthquake in the Indian Ocean. The tsunami
            caused extensive damage to the island, and many people lost their
            lives. The Indian government launched a massive relief and
            rehabilitation program to help the affected communities and rebuild
            the infrastructure on the island.
          </p>
          <p>
            Today, Baratang Island is a popular tourist destination in the
            Andaman and Nicobar Islands, attracting thousands of tourists every
            year. The island is known for its natural beauty, dense forests,
            mangrove creeks, stunning beaches, and unique limestone caves. The
            island is also home to various indigenous tribes who continue to
            preserve their traditional way of life and culture. The Indian
            government has taken several initiatives to promote tourism on the
            island while also preserving its fragile ecosystem and protecting
            the rights of the indigenous communities.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Location</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base ">
          Located about 100 km north of Port Blair, the capital of the Andaman
          and Nicobar Islands, Baratang Island is a small island that is
          approximately 45 km long and 15 km wide, with a total area of around
          243 square kilometers. The island is home to a diverse range of flora
          and fauna, including several endangered species, making it a perfect
          destination for nature lovers.
        </p>
        <h2 className="text-xl font-semibold mt-14">Tourist Attractions</h2>
        <div className="mt-2 text-justify  text-sm sm:text-base space-y-4">
          <p>
            Baratang Island is a nature lover's paradise, and there are many
            attractions on the island that tourists can visit. Some of the
            popular tourist attractions on the island are:
          </p>
          <ol className="space-y-2 list-decimal">
            <li>
              <span className="font-semibold">Limestone Caves - </span>The
              limestone caves on Baratang Island are a natural wonder that
              attracts tourists from all over the world. These caves are formed
              by the action of natural forces over millions of years and are
              considered to be one of the most beautiful caves in Asia. To reach
              the caves, tourists have to take a boat ride through the mangrove
              creeks, followed by a short trek through the forest.
            </li>
            <li>
              <span className="font-semibold">Mud Volcano - </span>The mud
              volcano on Baratang Island is another natural wonder that tourists
              can visit. The mud volcano is located in the middle of a dense
              forest and is a popular attraction for tourists who love nature
              and adventure.
            </li>
            <li>
              <span className="font-semibold">Parrot Island - </span>Parrot
              Island is a small island near Baratang Island, known for its
              stunning natural beauty and a large population of parrots. The
              island is covered with lush greenery, and tourists can witness a
              breathtaking sunset on the island.
            </li>
            <li>
              <span className="font-semibold">Baludera Beach - </span>Baludera
              Beach is one of the most popular beaches on Baratang Island, known
              for its pristine white sand and crystal clear water. The beach is
              ideal for swimming, sunbathing, and relaxing.
            </li>
            <li>
              <span className="font-semibold">Mangrove Creek - </span>The
              mangrove creek on Baratang Island is a must-visit attraction for
              nature lovers. Tourists can take a boat ride through the creek and
              witness the stunning beauty of the mangrove forest.
            </li>
          </ol>
        </div>
        <h2 className="text-xl font-semibold mt-14">Culture</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base">
          In addition to its natural attractions, Baratang Island also has a
          rich cultural heritage. The island is home to the indigenous tribes of
          the Andaman and Nicobar Islands, including the Jarwa and Onge tribes.
          Visitors can learn about the local culture and traditions by visiting
          the different villages on the island and interacting with the local
          people.
        </p>
        <h2 className="text-xl font-semibold mt-14">How to reach</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base">
          Getting to Baratang Island is easy, as it is connected to the mainland
          of the Andaman and Nicobar Islands by road and waterways. Visitors can
          take a private operated Cars from Port Blair to Baratang Island, which
          takes around 2-3 hours.
        </p>
      </div>
    </div>
  );
}
