import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe3 from "components/SectionSubscribe2/SectionSubscribe3";
import SectionMagazine5 from "./SectionMagazine5";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import { getBlogList } from "controller/blogController";
import { BlogModel } from "models/BlogModel/BlogModel";
import { getDateFromTimeStamp } from "utils/getDateFromTimeStamp";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { setBlogList, setPopularBlogList, setBlogListComplete, setBlogListPage } from "../../store/states/blogState";

const BlogPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { blogList, popularBlogList, blogListComplete, blogListPage } = useAppSelector(
    (state) => state.blog
  );
  const [loadingMorePage,setLoadingMorePage] = useState(false);
  const fetchList = useCallback(async (page:number) => {
    const res = await getBlogList(page);
    if (res[0] !== null && res[0].length > 0) {
      if(res[0].length < 20){
        dispatch(setBlogListComplete(true));
      }
      const setPopularList = res[0].splice(0, 4);
      dispatch(setPopularBlogList(setPopularList));
      res[0].length > 0 && dispatch(setBlogList(res[0]));
    
    }else{
      if(res[1] !== null && res[1].statusCode === 404){
        dispatch(setBlogListComplete(true));
      }
    }
  }, [dispatch]);

  const fetchMorePage =  async()=>{
      setLoadingMorePage(true);
      const res = await getBlogList(blogListPage + 1);
      setLoadingMorePage(false);
      if (res[0] !== null && res[0].length > 0) {
        dispatch(setBlogList(res[0]));
        dispatch(setBlogListPage(blogListPage + 1));
        if(res[0].length < 20){
          dispatch(setBlogListComplete(true));

        }
      }else{
        if(res[1] !== null && res[1].statusCode === 404){
          dispatch(setBlogListComplete(true));
      

        }
      }
  }
  useEffect(() => {
    if (popularBlogList.length === 0) {
      fetchList(blogListPage);
    }
  }, [popularBlogList.length, fetchList,blogListPage]);



  const RenderPostRelated = ({ post }: { post: BlogModel }) => {
    return (
      <Link to={`/blog-single/${post.id}`}>
        <div
          key={post.id}
          className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
        >
          <NcImage
            className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
            src={post.imageOne}
          />
          <div>
            <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
          </div>
          <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
            <Badge name={post.area} />
            <h2 className="block text-lg font-semibold text-white ">
              <span className="line-clamp-2">{post.heading}</span>
            </h2>

            <div className="flex">
              <span className="block text-neutral-200 hover:text-white font-medium truncate">
                {post.authorName}
              </span>
              <span className="mx-1.5 font-medium">·</span>
              <span className="font-normal truncate">
                {getDateFromTimeStamp(post.date)}
              </span>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Way Find</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative">
        {/* === SECTION 1 === */}
        <div className="pt-12 pb-16 ">
          <h1 className="font-semibold text-xl my-2 md:text-3xl">
            📚 Popular Blogs
          </h1>

         {popularBlogList.length > 0 &&  <SectionMagazine5 posts={popularBlogList} />}
        </div>

        <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16   lg:mt-24">
          <div className="container pb-16">
            <h2 className="text-3xl font-semibold">
              Collection of blogs by travel vloggers <br></br> around the world
              🌎
              <br></br>
              <span className="text-sm font-normal">
                The below blogs where sponsored by wayfindtrips
              </span>
            </h2>
            <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
              {blogList.length > 0 &&
                blogList.map((blog) => (
                  <RenderPostRelated post={blog} key={blog.id} />
                ))}
            </div>
          </div>
        </div>

        {/* === SECTION 8 === */}
        {/* <SectionLatestPosts className="py-16 lg:py-28" /> */}
        {/* === SECTION 1 === */}
        {!blogListComplete && (
          <div className="flex justify-center flex-col items-center">
            <button onClick={fetchMorePage} className=" transition-all duration-300 px-6 hover:px-12 hover:bg-violet-700 py-3 text-lg bg-violet-600 text-white rounded-3xl">
              more blogs
            </button>
           {loadingMorePage &&  <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-6 w-6 mt-2"></div>}
          </div>
        )}
        <div className="mt-28">
          <SectionSubscribe3 className="pb-16" />
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
