import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import { BlogModel } from "models/BlogModel/BlogModel";
import CardBadge from "components/blog/CardBadge";
import placeHolderImage from "../../images/placeholder.jpg"
export interface Card12Props {
  className?: string;
  post: BlogModel;
}

const Card12: FC<Card12Props> = ({ className = "h-full", post }) => {
  return (
    <div
      className={`nc-Card12 group relative flex flex-col ${className}`}
      data-nc-id="Card12"
    >
      <Link
        to={`/blog-single/${post.id}`}
        className="block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden"
      >
        <NcImage
          containerClassName="absolute inset-0"
          src={post.imageOne}
          alt={post.heading}
        />
      </Link>
      <div className=" mt-8 pr-10 flex flex-col">
        <h2
          className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl`}
        >
          <Link
            to={`/blog-single/${post.id}`}
            className="line-clamp-2"
            title={post.heading}
          >
            {post.heading}
          </Link>
        </h2>
        <span className="hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400">
          <span className="line-clamp-2"> {post.description}</span>
        </span>
        <div className="mt-4 flex flex-wrap">
          {post.tagList !== null && post.tagList.length > 0 ?
            post.tagList.map((data, index) => (
              <CardBadge value={data} index={index} key={index} />
            )):""}
        </div>
        <div className="mt-4 flex items-center">
          <img
            src={
              post.authorImage ? post.authorImage : placeHolderImage
            }
            alt={post.authorName}
            height="40"
            width="40"
            className="rounded-full"
          />
          <p className="text-sm font-semibold ml-2">{post.authorName}</p>
        </div>
      </div>
    </div>
  );
};

export default Card12;
