import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { getHotelList } from "../../controller/hotelController";
import { TaxonomyType } from "data/types";
import HotelType from "./HotelType";
import HotelCard from "components/Hotel/HotelCard";
import HotelHero from "components/Hotel/HotelHero";

export default function Hotel() {
  const [hotelList, setHotelList] = useState<TaxonomyType[]>([]);
  const [hotelType, setHotelType] = useState<string>("Port Blair");
  const [fetchingHotel, setFetchingHotel] = useState(false);

  const fetchList = useCallback(async (type: string) => {
    setFetchingHotel(true);
    const res = await getHotelList(type);
    setFetchingHotel(false);
    if (res[0] !== null) {
      setHotelList(res[0]);
    } else {
      if (res[1] !== null) {
        message.warning(res[1].error !== null && res[1].error);
      }
    }
  }, []);

  useEffect(() => {
    fetchList(hotelType);
  }, [hotelType]);

  return (
    <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
      <div className=" mt-24">
        <HotelHero />
      </div>
      <div className="mt-12">
        <HotelType hotelType={hotelType} setHotelType={setHotelType} />
        {fetchingHotel && (
          <div className="mt-12 flex justify-center">
            <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-12 w-12 mt-2"></div>
          </div>
        )}
        {!fetchingHotel && (
          <div className=" mt-16 grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2">
            {hotelList.length > 0 &&
              hotelList.map((data) => <HotelCard data={data} key={data.id} />)}
          </div>
        )}
      </div>
    </div>
  );
}
