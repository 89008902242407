import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProfileInfo } from "models/ProfileInfoModel";

const initialState: ProfileInfo = {
  name: "",
  phoneNumber: "",
  profileImageUrl: "",
  about: "",
  email: "",
  isVefified: null,
};

const profileInfoSlice = createSlice({
  name: "profileInfo",
  initialState,
  reducers: {
    setProfileData: (state, action: PayloadAction<ProfileInfo>) => {
      state.name = action.payload.name;
      state.phoneNumber = action.payload.phoneNumber;
      state.profileImageUrl = action.payload.profileImageUrl;
      state.about = action.payload.about;
      state.email = action.payload.email;
      state.isVefified = action.payload.isVefified;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setUserPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setUserProfileImage: (state, action: PayloadAction<string>) => {
      state.profileImageUrl = action.payload;
    },
    setUserAbout: (state, action: PayloadAction<string>) => {
      state.about = action.payload;
    },
    setUserVerified: (state, action: PayloadAction<boolean>) => {
      state.isVefified = action.payload;
    },
  },
});

export default profileInfoSlice.reducer;
export const {
  setUserAbout,
  setUserName,
  setUserPhoneNumber,
  setUserProfileImage,
  setProfileData,
  setUserEmail,
  setUserVerified,
} = profileInfoSlice.actions;
