import { Activities } from "core/Constants";

export interface AccommodationModel {
  type: Activities.ACCOMMODATION;
  id: number;
  heading:string|null;
  area: string;
  description: null | string;
  name: string;
  accommodation_type: string;
  image: string | null;
  checkIn: string;
  checkOut: string;
}

export function instanceOfAccommodationModel(
  data: any
): data is AccommodationModel {
  return data.type === Activities.ACCOMMODATION;
}


/**
 * 
 * @param data:any response data from the server
 * @returns This will return an object based on AccommodationModel
 */

export function createAccommodationObject(data: any):AccommodationModel {
  const result: AccommodationModel = {
    type: Activities.ACCOMMODATION,
    heading:data.heading,
    id: data.accommodation_detail[0].id,
    area: data.accommodation_detail[0].area,
    description: data.accommodation_detail[0].accommodation_description,
    name: data.accommodation_detail[0].name,
    accommodation_type: data.accommodation_detail[0].accommodation_type,
    image: data.accommodation_detail[0].image,
    checkIn: data.check_in,
    checkOut: data.check_out,
  };
  return result;
}
