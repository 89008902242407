import { userTripListAPI } from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import {
  TripPackageModel,
  createTripPackageObject,
} from "models/TripPackageModel";

export async function getUserTrips(
  tripType: "upcoming" | "previous" | "canceled"
) {
  const res = await asyncHandler(
    userTripListAPI,
    "GET",
    {
      trip_type: tripType,
    },
    true
  );
  if (!instanceOfHttpResponse(res)) {
    const dataArr = res.data;
    const packageArr: TripPackageModel[] = [];
    for (let x of dataArr) {
      const arr: TripPackageModel = {
        ...createTripPackageObject(x.package_detail),
        cancelPayment: x.cancel_payment ? x.cancel_payment : false,
        cancelPaymentamount: x.cancel_payment_amount
          ? x.cancel_payment_amount
          : "",  
        cancelPaymentDate:x.cancel_payment_date?x.cancel_payment_date:"",
        cancelPaymentTransaction:x.cancel_payment_transaction?x.cancel_payment_transaction:"",
        cancelPaymentRemark:x.cancel_payment_remark?x.cancel_payment_remark:""  
      };
      packageArr.push(arr);
    }
    return packageArr;
  } else {
    return res;
  }
}
