import { message } from "antd";
import { getJobDetails } from "controller/careerController";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import { JobModel } from "models/JobModel";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import locationSvg from "../../images/career/location.svg";
import ViewRichText from "components/RichText/ViewRichText";
import bagSvg from "../../images/career/moneybag.svg";
import clockSvg from "../../images/career/clock.svg";

export default function JobDetails() {
  const { jobId } = useParams();
  const navigate = useNavigate()
  const [jobData, setJobData] = useState<JobModel>({
    id: 0,
    description: "",
    jobType: "",
    location: "",
    postType: "",
    qualification: "",
    responsibility: "",
    salary: "",
    title: "",
  });

  async function handleJobDetails(id: string) {
    const res = await getJobDetails(id);
    if (!instanceOfHttpResponse(res)) {
      setJobData(res);
    } else {
      message.error(res.error);
    }
  }

  useEffect(() => {
    if (jobId) {
      handleJobDetails(jobId);
    }
  }, [jobId]);

  return (
    <div className="container relative  mb-24 space-y-16 lg:mb-28">
       <button
        className="px-6 py-2 rounded-md border text-sm border-gray-100 mt-12 text-white bg-gray-800"
        onClick={() => navigate(-1)}
      >
        <span className="mr-2">&larr;</span>
        back
      </button>
      <div className=" bg-[#FFE7CC] rounded-lg text-center py-10 mt-24">
        <h1 className="text-2xl font-semibold">{jobData.title}</h1>
        <h4 className="font-semibold mt-4">
          {jobData.postType}-{jobData.jobType}
        </h4>
        <div className="flex justify-center items-center mt-4">
          <img src={locationSvg} alt="location" width="10" height="10" />
          <span className="text-xs ml-2">{jobData.location}</span>
        </div>
      </div>

      <div className="mx-12 md:flex justify-between ">
        <div>
          <div>
            <h4 className="font-semibold">About the role</h4>
            {jobData.description !== "" && (
              <ViewRichText data={jobData.description} />
            )}
            <div className="flex items-center space-x-4">
              <div className="flex space-x-2">
                <img src={bagSvg} alt="money" height="10" width="10" />
                <span className="text-xs">{jobData.salary}</span>
              </div>
              <div className="flex space-x-2">
                <img src={clockSvg} alt="clock" height="10" width="10" />
                <span className="text-xs">{jobData.jobType}</span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h4 className="font-semibold"> Responsibilites</h4>
            {jobData.responsibility !== "" && (
              <ViewRichText data={jobData.responsibility} />
            )}
          </div>
          <div>
            <h4 className="font-semibold"> Qualification</h4>
            {jobData.qualification !== "" && (
              <ViewRichText data={jobData.qualification} />
            )}
          </div>
          <div>
            <h4 className="font-semibold">Our values</h4>
            <div className="mt-6">
              <h5 className=" text-xs font-semibold">Make It Happen</h5>
              <ol className=" list-disc text-xs mt-2 md:w-2/4 text-justify ml-6">
                <li>
                  We strive to deliver results with the highest impact and are
                  committed to following through. We continuously optimise
                  through collaboration to achieve the best collective outcomes.
                </li>
              </ol>
            </div>
            <div className="mt-6">
              <h5 className=" text-xs font-semibold">Be Proactive</h5>
              <ol className=" list-disc text-xs mt-2 md:w-2/4 text-justify ml-6">
                <li>
                  We seize the initiative and are always ooking or continual
                  improvement, or ourselves, our team and the cities we serve.
                  We relish the chance for action even if it leads us outside of
                  our normal scope.
                </li>
              </ol>
            </div>
            <div className="mt-6">
              <h5 className=" text-xs font-semibold">
                Trust Facts Over Opinions
              </h5>
              <ol className=" list-disc text-xs mt-2 md:w-2/4 text-justify ml-6">
                <li>
                  Our decisions are evidence-based. We assess situations fairly,
                  wherever possible using reason and logic rather than
                  unverified opinions.
                </li>
              </ol>
            </div>
            <div className="mt-6">
              <h5 className=" text-xs font-semibold">Stay Curious</h5>
              <ol className=" list-disc text-xs mt-2 md:w-2/4 text-justify ml-6">
                <li>
                  We ask questions, challenge assumptions, learn from mistakes
                  and are ready to leave behind what we thought we knew.
                </li>
              </ol>
            </div>
            <div className="mt-6">
              <h5 className=" text-xs font-semibold">Do More With Less</h5>
              <ol className=" list-disc text-xs mt-2 md:w-2/4 text-justify ml-6">
                <li>
                  We understand that both time and resources are finite. We
                  balance our priorities and think strategically about how we
                  can maximise our impact and the return on investment.
                </li>
                <li>
                  If you are passionate about making a real-time impact and want
                  the opportunity to play an instrumental part in our growth
                  story, we want you riding for our team!
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="mt-12 md:mt-0">
          <Link to={`/applicant-form/${jobId}`}>
            <button className=" bg-gray-800 px-6 py-2 text-white whitespace-nowrap text-xs rounded-md">
              Apply now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
