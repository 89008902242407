import { message } from 'antd';
import axios from 'axios';
import CardCategory6 from 'components/CardCategory6/CardCategory6';
import { TaxonomyType } from 'data/types';
import React, { useEffect, useState } from 'react'


const GalleryPage = () => {

    const [blog_data,setBlog_data] = useState("")
    const [blog_data1,setBlog_data1] = useState("")
    const [blog_data2,setBlog_data2] = useState("")
    const [blog_data3,setBlog_data3] = useState("")
    const [heading,setHeading] = useState("")
    const [heading1,setHeading1] = useState("")
    const [heading2,setHeading2] = useState("")
    const [heading3,setHeading3] = useState("")

    useEffect(()=>{
        axios.get("/wft_user/user-dashboard").then((response)=>{
          setBlog_data(response.data.blog_data[0].image_one)
          setBlog_data1(response.data.blog_data[1].image_one)
          setBlog_data2(response.data.blog_data[2].image_one)
          setBlog_data3(response.data.blog_data[3].image_one)
          setHeading(response.data.blog_data[0].heading)
          setHeading1(response.data.blog_data[1].heading)
          setHeading2(response.data.blog_data[2].heading)
          setHeading3(response.data.blog_data[3].heading)
        }).catch((error)=>{
          message.error(error.response.statusText);
        })
      },[])

    const DEMO_CATS_2: TaxonomyType[] = [
        {
          id: "1",
          href: "/blog-single",
          name: heading,
          taxonomy: "category",
          count: 188288,
          thumbnail: blog_data,
        },
        {
          id: "2",
          href: "/blog-single",
          name: heading1,
          taxonomy: "category",
          count: 188288,
          thumbnail: blog_data1
        },
        {
          id: "3",
          href: "/blog-single",
          name: heading2,
          taxonomy: "category",
          count: 188288,
          thumbnail: blog_data2
        },
        {
          id: "4",
          href: "/blog-single",
          name: heading3,
          taxonomy: "category",
          count: 188288,
          thumbnail: blog_data3
        },
        {
          id: "5",
          href: "/blog-single",
          name: heading2,
          taxonomy: "category",
          count: 188288,
          thumbnail: blog_data2,
        },
      ];
  return (
    <div>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
            <CardCategory6 taxonomy={DEMO_CATS_2[0]} />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
            <CardCategory6 taxonomy={DEMO_CATS_2[1]} />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
            <CardCategory6 taxonomy={DEMO_CATS_2[2]} />
            <CardCategory6 taxonomy={DEMO_CATS_2[3]} />
          </div>
        </div>
    </div>
  )
}

export default GalleryPage
