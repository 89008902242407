import {
  bookMarkedPackageListAPI,
  suggestedPackageListAPI,
} from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import {
  TripPackageModel,
  createTripPackageObject,
} from "models/TripPackageModel";

export async function savePackageList() {
  const res = await asyncHandler(bookMarkedPackageListAPI, "GET", null, true);
  if (!instanceOfHttpResponse(res)) {
    const resultArr: TripPackageModel[] = [];
    for (let x of res.data) {
      const packageObj = createTripPackageObject(x.package_detail);
      resultArr.push(packageObj);
    }
    return resultArr;
  } else {
    return res;
  }
}

export async function suggestedPackageList() {
  const res = await asyncHandler(suggestedPackageListAPI, "GET", null, true);
  if (!instanceOfHttpResponse(res)) {
    const resultArr: TripPackageModel[] = [];
    for (let x of res.data) {
      resultArr.push(createTripPackageObject(x));
    }
    return resultArr;
  } else {
    return res;
  }
}
