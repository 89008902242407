import React from "react";
// import isLandSvg from "../../../images/island.svg";
import carSvg from "../../../images/car.svg";
import { RoadTravelModel } from "models/BookTripModel/roadTravelModel";
import carImg from "../../../images/activity/car_travel.png";

export default function BookTripRoadTravel({roadTravel}:{roadTravel:RoadTravelModel}) {
  return (
    <div>
      {/* <p>
        Date: <span className="ml-1">{roadTravel.date}</span>
      </p> */}
      {/* <div className="mt-6 ml-8 flex items-center">
        <img src={isLandSvg} alt="isLand" height="30" width="30" />
        <p className="font-semibold ml-2">Port Blair Island - 1 Days 1 Night</p>
      </div> */}
      <div className="mt-6  flex items-center">
        <img src={carSvg} alt="car" height="30" width="30" />
        <p className="ml-2 text-sm">{roadTravel.source} to {roadTravel.dest}</p>
      </div>
      {roadTravel.heading !== null && <p className="text-sm leading-1 text-gray-600 mt-2">{roadTravel.heading}</p>}
      <div className="mt-6 flex sm:flex-row flex-col">
        <img
          src={carImg}
          alt="img"
          width="150"
          height="150"
          className="rounded-sm"
        />
        <div className="sm:ml-4 sm:mt-0 mt-2 flex flex-col justify-between">
          <div>
            <h1 className="text-xl font-semibold">
              Private Transfer/Sedan - AC
            </h1>
            <p className="mt-2 text-gray-600 text-sm">
             {roadTravel.carName}
            </p>
          </div>
          <p className="font-medium text-sm">
            {roadTravel.noOfSeaters} seaters | {roadTravel.acNon} | {roadTravel.luggageSpace} Luggage Bags {roadTravel.primaryMedKit === "Yes" && "| First Aid"}
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col ">
        <h4 className="font-semibold">Inclusion</h4>
        <p>{roadTravel.description}</p>
      </div>
    </div>
  );
}
