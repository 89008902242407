import digilpurImg from "images/discoverplaces/digilpur/diglipur.jpg";
export default function Diglipur() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">Diglipur</h1>
        <div className="mt-6 text-sm sm:text-base">
          <p>
            Diglipur is a picturesque town located on the northern part of the
            Andaman Islands in India. It is the largest town in the North
            Andaman Island group and is home to around 50,000 people. Diglipur
            is known for its stunning beaches, lush green forests, and rich
            marine life, making it a popular tourist destination.
          </p>
          <p>
            Geography and Climate Diglipur is located approximately 290 km from
            Port Blair, the capital city of the Andaman and Nicobar Islands. The
            town is situated on the northern side of the Andaman Islands, and is
            surrounded by lush green forests, hills, and the sea. The highest
            peak in the Andaman Islands, Saddle Peak, is located near Diglipur.
          </p>
        </div>
      </div>
      <img
        src={digilpurImg}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">Myths & Believes</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base">
          The origin of the name Diglipur is believed to have come from the
          Bengali language. In Bengali language the words, "Digha" which means
          'island' and "Pur" which means 'city' or 'town.' The combination of
          these two words is believed to have given rise to the name Diglipur,
          which means 'town of the islands' or 'island city.'
        </p>
        <h2 className="text-xl font-semibold mt-14">History</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base">
          Diglipur was once a small village that was largely dependent on
          agriculture and fishing for its livelihood. However, with the growth
          of tourism in the Andaman Islands, Diglipur has emerged as a popular
          tourist destination. The town was named by a British administrator
          named Sir Daniel Ross, who discovered the area during his explorations
          of the Andaman Islands in the 19th century.
        </p>
        <h2 className="text-xl font-semibold mt-14">Climate</h2>
        <div className="mt-2 text-justify  text-sm sm:text-base space-y-4">
          <p>
            The climate in Diglipur is tropical and humid, with temperatures
            ranging from 20°C to 32°C. The best time to visit Diglipur is
            between November and May, when the weather is dry and the skies are
            clear.
          </p>
          <p>
            Tourist Attractions Diglipur is known for its beautiful beaches,
            crystal clear waters, and stunning coral reefs, making it an ideal
            destination for water sports and adventure activities. Here are some
            of the top tourist attractions in Diglipur
          </p>
          <ol className="space-y-2 list-decimal">
            <li>
              <span className="font-semibold">Ross and Smith Islands - </span>
              Ross and Smith Islands are two small islands connected by a narrow
              sandbar. The sandbar disappears during high tide, creating two
              separate islands. The beaches on these islands are pristine and
              offer a stunning view of the surrounding blue waters.
            </li>
            <li>
              <span className="font-semibold">Saddle Peak - </span>Saddle Peak
              is the highest point in the Andaman Islands, and offers a
              breathtaking view of the surrounding forests and the sea. The trek
              to the peak is challenging, but the view from the top is worth the
              effort.
            </li>
            <li>
              <span className="font-semibold">Kalipur Beach - </span>Kalipur
              Beach is a secluded beach located near Diglipur, and is known for
              its black sand and beautiful sunsets. The beach is also a nesting
              ground for turtles, and visitors can watch the turtles hatch and
              make their way to the sea during the nesting season.
            </li>
            <li>
              <span className="font-semibold">Ramnagar Beach - </span>Ramnagar
              Beach is a popular destination for water sports and adventure
              activities, such as snorkeling, scuba diving, and sea-walking. The
              beach is also known for its clear waters and beautiful coral
              reefs.
            </li>
            <li>
              <span className="font-semibold">Mud Volcano - </span> The Mud
              Volcano is a natural wonder located near Diglipur, and is known
              for its bubbling mud pools and hot springs. The mud in the volcano
              is believed to have medicinal properties and is used by locals for
              skin treatments.
            </li>
            <li>
              <span className="font-semibold">Alfred Caves - </span>The Alfred
              Caves are a group of limestone caves located near Diglipur. The
              caves are home to a variety of bats, and visitors can explore the
              underground world and admire the beautiful stalactites and
              stalagmites.
            </li>
          </ol>
          <p>
            Apart from these tourist attractions, Diglipur is also home to
            several beautiful waterfalls, such as the Limestone Caves Waterfall,
            and the Kala Pathar Waterfall.
          </p>
          <p>
            Culture and Lifestyle The people of Diglipur are predominantly
            Bengali and Tamil, and the culture of the town is a mix of Bengali,
            Tamil, and Andamanese. The locals are friendly and welcoming, and
            the town has a laid-back and relaxed vibe.
          </p>
          <p>
            The main occupation of the people in Diglipur is fishing, and the
            town is known for its fresh seafood. The cuisine in Diglipur is a
            mix of Bengali and Tamil food, with a focus on seafood.
          </p>
          <p>
            Transportation and Accommodation Diglipur is well-connected to Port
            Blair and other parts of the Andaman Islands by road and sea.
            Regular bus services and private taxis are available from Port Blair
            to Diglipur, and the journey takes approximately 10 hours.
          </p>
        </div>
      </div>
    </div>
  );
}
