export function storeToken(token: string, key: string = "token") {
  localStorage.setItem(key, token);
}

export function getToken(key: string = "token") {
  return localStorage.getItem(key);
}

export function removeToken(key: string = "token") {
  localStorage.removeItem(key);
}
