import React from "react";
import northBayImg from "images/discoverplaces/northbay/northbayisland.jpg";
export default function NorthBayIsland() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">
          North Bay Island
        </h1>
        <p className="mt-6 text-sm sm:text-base">
          North Bay Island is a small but beautiful island located near Port
          Blair in the Andaman and Nicobar Islands. It is one of the most
          popular tourist destinations in the Andaman and Nicobar Islands due to
          its crystal-clear waters, pristine beaches, and abundance of marine
          life.
        </p>
      </div>
      <img
        src={northBayImg}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">Historical Significance</h2>
        <div className="mt-2 text-sm sm:text-base space-y-4">
          <p>
            North Bay Island, like the rest of the Andaman and Nicobar Islands,
            has a rich history that dates back thousands of years. The
            indigenous tribes of the Andaman and Nicobar Islands, including the
            Great Andamanese, Onge, and Jarawa, are believed to have lived on
            these islands for tens of thousands of years. The exact history of
            North Bay Island, however, is not well-documented.
          </p>
          <p>
            During the colonial era, the Andaman and Nicobar Islands came under
            the control of the British, who established a penal colony on the
            islands. Thousands of Indian freedom fighters were sent to the
            Andaman and Nicobar Islands as prisoners, where they were subjected
            to brutal treatment and hard labor. North Bay Island, like many
            other islands in the Andaman and Nicobar archipelago, was used as a
            site for the penal colony. It is believed that prisoners were forced
            to work on the island, clearing the land and building infrastructure
            for the British colonizers.
          </p>
          <p>
            After India gained independence in 1947, the Andaman and Nicobar
            Islands became a part of the Indian Union. The penal colony was
            eventually closed down, and the islands were opened up for tourism.
            Today, North Bay Island is a popular tourist destination, known for
            its crystal-clear waters, pristine beaches, and abundance of marine
            life.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">
          Location and How to Reach
        </h2>
        <p className="mt-2 text-sm sm:text-base">
          North Bay Island is located about 15 kilometers from Port Blair, the
          capital city of the Andaman and Nicobar Islands. It is easily
          accessible by boat or ferry from Port Blair. Visitors can take a ferry
          from the Phoenix Bay Jetty, which takes about 30 minutes to reach
          North Bay Island.
        </p>
        <h2 className="text-xl font-semibold mt-14">Attractions</h2>
        <div className="mt-2 text-sm sm:text-base space-y-2">
          <p>
            North Bay Island is known for its clear and calm waters, which are
            ideal for snorkeling and scuba diving. Visitors can see a variety of
            marine life, including colorful fish, sea turtles, and even
            dolphins. The island is also home to a variety of coral reefs, which
            attract divers and snorkelers from all over the world.
          </p>
          <p>
            In addition to snorkeling and scuba diving, North Bay Island offers
            a range of other activities. Visitors can take a glass-bottom boat
            ride to see the underwater world without getting wet. They can also
            go for a ride on a banana boat or enjoy parasailing for a bird's eye
            view of the island and the surrounding ocean.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Beaches</h2>
        <div className="mt-2 text-sm sm:text-base space-y-4">
          <p>
            North Bay Island is known for its stunning beaches, which are
            perfect for swimming, sunbathing, and relaxing. The beaches here are
            very clean, and the water is crystal clear, making it an ideal place
            for beach lovers. Some of the most popular beaches on North Bay
            Island include:
          </p>
          <ol className="space-y-2 list-decimal">
            <li>
              <span className="font-semibold">North Bay Beach - </span>This is
              the main beach on the island and is known for its white sand and
              crystal-clear water. It is an ideal place for swimming and
              sunbathing.
            </li>
            <li>
              <span className="font-semibold">Coral Beach - </span>This beach is
              famous for its coral formations, which are visible from the shore.
              It is an excellent place for snorkeling and scuba diving.
            </li>
          </ol>
        </div>
        <h2 className="text-xl font-semibold mt-14">Activities</h2>
        <div className="mt-2 text-sm sm:text-base space-y-2">
          <p>
            North Bay Island offers a range of activities for visitors to enjoy.
            Some of the most popular activities on the island include:
          </p>
          <ol className="space-y-2 list-decimal">
            <li>
              <span className="font-semibold">Snorkeling - </span>North Bay
              Island is known for its excellent snorkeling opportunities.
              Visitors can rent snorkeling gear and explore the coral reefs and
              marine life just below the surface of the water.
            </li>
            <li>
              <span className="font-semibold">Scuba Diving - </span> For those
              who want to explore the underwater world in more depth, scuba
              diving is a must-do activity on North Bay Island. Visitors can
              take a scuba diving course and explore the coral reefs and marine
              life with an experienced guide.
            </li>
            <li>
              <span className="font-semibold">Glass Bottom Boat Rides - </span>
              Visitors who don't want to get wet can take a glass-bottom boat
              ride to see the underwater world. The boats have a glass bottom,
              allowing passengers to see the coral reefs and marine life without
              getting wet.
            </li>
            <li>
              <span className="font-semibold">Banana Boat Rides - </span>For
              those who love a bit of excitement, banana boat rides are a
              popular activity on North Bay Island. Visitors can ride on a
              banana-shaped inflatable boat, towed by a speedboat.
            </li>
            <li>
              <span className="font-semibold">Parasailing - </span>For a bird's
              eye view of the island and the surrounding ocean, visitors can go
              parasailing. A speedboat tows a parasail, which lifts visitors up
              into the air for a thrilling ride.
            </li>
          </ol>
        </div>
        <h2 className="text-xl font-semibold mt-14">Accommodation</h2>
        <p className="mt-2 text-sm sm:text-base">
          There are no accommodation options available on North Bay Island.
          Visitors can stay in Port Blair, which has a range of accommodation
          options to suit all budgets. There are hotels, guesthouses, and
          resorts in Port Blair that cater to both domestic and international
          tourists.
        </p>
        <h2 className="text-xl font-semibold mt-14">Travel Tips</h2>
        <ol className="mt-2 text-sm sm:text-base space-y-2 list-disc">
          <li>
            Visitors are advised to wear comfortable clothes and shoes, as the
            island has rocky terrain.
          </li>
          <li>
            It is important to carry sunscreen, hats, and sunglasses to protect
            oneself from the sun.
          </li>
          <li>
            Visitors must obtain a permit from the Forest Department in Port
            Blair before visiting North Bay Island.
          </li>
          <li>
            It is recommended to book snorkeling and scuba diving activities in
            advance to avoid last-minute hassle.
          </li>
          <li>
            Visitors are not allowed to collect corals or shells from the
            island, as it is a protected area.
          </li>
          <li>
            The island has limited food and water facilities, so visitors are
            advised to carry their own food and water.
          </li>
        </ol>
      </div>
    </div>
  );
}
