import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Book & Relax",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Smart Checklist",
    desc: "Smartly organize your trip with our intelligent checklist for stress-free planning"
  },
  {
    id: 3,
    img: HIW3img,
    title: "Save More",
    desc: "Our deals and discounts will take you places, Save more travel more",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      
      <h1 className="m-0 text-gray-700 text-2xl font-semibold sm:text-3xl lg:text-4xl mb-4 text-center">Start to End</h1>
      <div className="flex justify-center items-center font-normal text-sm sm:text-base text-[#6B7280] text-center">Let us guide you from the start of your journey to the very end.</div>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block"
                  src={item.img}
                  className=" h-36 w-36 object-contain"
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                  className="  h-36 w-36 object-contain"
                />
              </>
            ) : (
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={item.img}
                className="  h-36 w-36 object-contain"
              />
            )}
            <div className="text-center mt-auto">
              <h3 className=" text-lg sm:text-xl font-semibold">{item.title}</h3>
              <span className=" sm:text-base text-sm block mt-2 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
