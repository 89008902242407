
import axios from "axios";
import React, { FC, useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PathName } from "routers/types";
import DialogActions from "@mui/material/DialogActions";
import { Box, MenuItem, Modal } from "@mui/material";
import { Button, Input, Select, message } from "antd";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import CheckBox from "newCom/CheckBox";
import rightarrow from "images/Arrow.png"


interface Props {
  href?: PathName;
}

const ButtonSubmit: FC<Props> = ({ href = "/listing-stay-map" }) => {

  const [open, setOpen] = useState(false);
  const [checked, setCheck] = useState(false);
  const [count, setCount] = useState(1)
  const arr = ["Wardrobe","Cloth hook","Extra cushion","Gas stove","Toilet paper","Free toiletries","Makeup table","Hot pot","Bathroom heaters"
  ,"Kettle","Dishwasher","BBQ grill","Toaster","Towel","Dining table"]

  const arr2 = ["Fire siren","Fire extinguisher","Anti-theft key"]



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleNext() {
    if (count <= 10) {
      setCount(count + 1)
    }
  }

  function handlePrev() {
    if (count > 1) {
      setCount(count - 1)
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 10,
    p:2,
  };
  
  

  return (
    <div>
    <Link
      to={""}
      type="button"
      className="h-14 md:h-16 w-full md:w-16 rounded-full bg-[#F19838] hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
      onClick={handleClickOpen}
    >
      <span className="mr-3 md:hidden">Search</span>
      <img src={rightarrow} alt=""/>
    </Link>
    <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Box sx={style}>
            {count === 1 ? <div className="p-4">
              <div className="flex font-bold text-2xl"><h1>01</h1><div className="font-normal mt-1 text-lg">/10</div></div>
              <div className="mx-4 flex flex-col">
                <h1 className="lg:text-[24px] text-xl mt-4 mb-8 font-bold md:whitespace-nowrap">Choosing listing categories</h1>
                <label htmlFor="#label" className="text-lg">Choose a property type</label>
                <Select className="max-w-[370px]" id="label" value={"Hotel"}>
                  <MenuItem value="Hotel">Hotel</MenuItem>
                </Select>
                <div className="text-sm mb-2"> Hotel: Professional hospitality businesses that usually have a unique style <br />
                  or theme defining their brand and decor</div>
                <label htmlFor="#label1" className="text-lg">Places name</label>
                <Input id="label1" className="max-w-[370px] border-gray-500 text-gray-600" placeholder="Places name" />
                <div className="text-sm mb-2">A catchy name usually includes: House name + Room name + Featured property + Tourist destination</div>
                <label htmlFor="#label3" className="text-lg">Rental form</label>
                <Select className="max-w-[370px]" id="label3" value={"Entire place"}>
                  <MenuItem value="Entire place">Entire place</MenuItem>
                </Select>
                <div className="text-sm ">Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces.
                  A bedroom, bathroom, and kitchen are usually included.</div>

                <DialogActions>
                  <div onClick={handleClose} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Close</div>
                  <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>
                </DialogActions>
              </div>
            </div> : count === 2 ?

              <div className="p-4 md:min-w-[600px]">
                <div className="flex font-bold text-2xl"><h1>02</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                <div className="mx-4 flex flex-col gap-1">
                  <h1 className="lg:text-[24px] text-xl mt-4 mb-8 font-bold md:whitespace-nowrap">Your place location</h1>

                  <Input className="max-w-[370px] border-gray-500 mt-8 text-gray-600" placeholder="Your Currect Location" />
                  <label htmlFor="#label" className="text-sm">Country/Region</label>
                  <Select className="max-w-[370px]" id="label" value={"Việt Nam"}>
                    <MenuItem value="Việt Nam">Việt Nam</MenuItem>
                  </Select>
                  <label htmlFor="#label4" className="text-sm">Street</label>
                  <Input id="label4" className="text-gray-600 border-gray-500" placeholder="Your Currect Location" />
                  <label htmlFor="#label4" className="text-sm">Room number (optional)</label>
                  <Input id="label4" className="max-w-[370px] border-gray-500 text-gray-600" placeholder="Your Currect Location" />
                  <div className="md:flex gap-2 mt-4">
                    <div className="flex flex-col">
                      <label htmlFor="#label5" className="text-sm">City</label>
                      <Input id="label5" className="text-gray-600 border-gray-500" />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="#label6" className="text-sm">State</label>
                      <Input id="label6" className="text-gray-600 border-gray-500" />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="#label7" className="text-sm">Postal code</label>
                      <Input id="label7" className="text-gray-600 border-gray-500" />
                    </div>

                  </div>


                </div>

                <DialogActions>
                  <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                  <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>

                </DialogActions>
              </div>
              : count === 3 ?
                <div className="p-4 md:min-w-[600px]">
                  <div className="flex font-bold text-2xl"><h1>03</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                  <div className="mx-4 flex flex-col gap-1">
                    <h1 className="lg:text-[24px] text-xl mt-4 mb-8 font-bold md:whitespace-nowrap">Size of your location</h1>
                    <label htmlFor="#label" className="text-sm">Acreage (m2)</label>
                    <Select className="max-w-[370px] mb-3" id="label" value={"100"}>
                      <MenuItem value="100">100</MenuItem>
                    </Select>
                    <div className="flex flex-col gap-4">
                      <NcInputNumber
                        className="w-full"
                        // defaultValue={guestAdultsInputValue}
                        // onChange={(value) => handleChangeData(value, "guestAdults")}
                        max={10}
                        min={1}
                        label="Guests"
                      />
                      <NcInputNumber
                        className="w-full"
                        // defaultValue={guestAdultsInputValue}
                        // onChange={(value) => handleChangeData(value, "guestAdults")}
                        max={10}
                        min={1}
                        label="Bedroom"
                      />
                      <NcInputNumber
                        className="w-full"
                        // defaultValue={guestAdultsInputValue}
                        // onChange={(value) => handleChangeData(value, "guestAdults")}
                        max={10}
                        min={1}
                        label="Beds"
                      />
                      <NcInputNumber
                        className="w-full"
                        // defaultValue={guestAdultsInputValue}
                        // onChange={(value) => handleChangeData(value, "guestAdults")}
                        max={10}
                        min={1}
                        label="Bathroom"
                      />
                      <NcInputNumber
                        className="w-full"
                        // defaultValue={guestAdultsInputValue}
                        // onChange={(value) => handleChangeData(value, "guestAdults")}
                        max={10}
                        min={1}
                        label="Kitchen"
                      />
                    </div>

                  </div>

                  <DialogActions>
                    <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                    <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>

                  </DialogActions>
                </div>
                : count === 4 ?
                  <div className=" md:min-w-[600px]">
                    <div className="flex font-bold text-2xl"><h1>04</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                    <div className="mx-4 flex flex-col gap-1">
                      <h1 className="lg:text-[24px] text-xl font-bold md:whitespace-nowrap">Amenities</h1>
                      <div className="text-sm font-thin mb-2">Many customers have searched for accommodation based on amenities criteria</div>
                      <h4 className="font-semibold">General amenities</h4>
                      <div className="grid grid-cols-3">
                        <CheckBox checked={true} label="Wifi" />
                        <CheckBox label="Internet" />
                        <CheckBox label="TV" />
                     
                        <CheckBox checked={true} label="Air conditioning" />
                        <CheckBox label="Fan" />
                        <CheckBox label="Private entrance" />
                     
                        <CheckBox checked={true} label="Dryer" />
                        <CheckBox label="Heater" />
                        <CheckBox label="Washing machine" />

                        <CheckBox label="Detergent/Laundry liquid" />
                        <CheckBox checked={true} label="Clothes dryer" />
                        <CheckBox label="Suitable for young children" />

                        <CheckBox label="Baby cot" />
                        <CheckBox label="Desk" />
                        <CheckBox label="Fridge" />
                      </div>
                      <h4 className="font-semibold">General amenities</h4>
                      <div className="grid grid-cols-3">
                      {arr.map(data=>(<CheckBox key={data} checked={true} label={data} />))}
                      </div>
                      <h4 className="font-semibold">Safe amenities</h4>
                      <div className="grid grid-cols-3">
                      {arr2.map(data=>(<CheckBox key={data} label={data} />))}
                      </div>
                    </div>

                    <DialogActions>
                      <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                      <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>

                    </DialogActions>
                  </div>
                  : count === 5 ?
                    <div className="p-4 md:min-w-[600px]">
                      <div className="flex font-bold text-2xl"><h1>05</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                      <div className="mx-4 flex flex-col gap-1 mb-4">
                        <h1 className="lg:text-[24px] text-xl mt-4 font-bold md:whitespace-nowrap">Set house rules for your guests</h1>
                        <div className="text-sm font-thin mb-6">Guests must agree to your house rules before they book.</div>
                        <h4 className="font-semibold">Smoking</h4>
                        <div className="flex justify-between">
                        <CheckBox 
                        label="Do not allow"/>
                        <CheckBox 
                        label="Allow"/>
                        <CheckBox 
                        label="Charge"/>
                        </div>
                        <h4 className="font-semibold">Pet</h4>
                        <div className="flex justify-between">
                        <CheckBox 
                        label="Do not allow"/>
                        <CheckBox 
                        label="Allow"/>
                        <CheckBox 
                        label="Charge"/>
                        </div>
                        <h4 className="font-semibold">Party organizing</h4>
                        <div className="flex justify-between">
                        <CheckBox 
                        label="Do not allow"/>
                        <CheckBox checked
                        label="Allow"/>
                        <CheckBox 
                        label="Charge"/>
                        </div>
                        <h4 className="font-semibold">Cooking</h4>
                        <div className="flex justify-between">
                        <CheckBox 
                        label="Do not allow"/>
                        <CheckBox checked
                        label="Allow"/>
                        <CheckBox 
                        label="Charge"/>
                        </div>
                        <h4 className="font-semibold">Additional rules</h4>
                        <div className="flex gap-2">
                        <Input className="border-gray-500" />
                        <Button style={{borderRadius:20}} className="whitespace-nowrap ">+ Add tag</Button>
                        </div>
                      </div>

                      <DialogActions>
                        <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                        <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>

                      </DialogActions>
                    </div>
                    : count === 6 ?
                      <div className="p-4 md:min-w-[600px]">
                        <div className="flex font-bold text-2xl"><h1>06</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                        <div className="mx-4 flex flex-col gap-1">
                          <h1 className="lg:text-[24px] text-xl mt-4 font-bold md:md:whitespace-nowrap">Your place description for client</h1>
                          <div className="text-sm font-thin mb-6">Mention the best features of your accommodation, any special amenities like fast Wi-Fi or parking, as well as things you like about the neighborhood.</div>
                          <textarea className="border border-gray-400 mb-10 h-48" />
                        </div>

                        <DialogActions sx={{ marginTop: 5 }}>
                          <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                          <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>

                        </DialogActions>
                      </div>
                      : count === 7 ?
                        <div className="p-4 md:min-w-[600px]">
                          <div className="flex font-bold text-2xl"><h1>07</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                          <div className="mx-4 flex flex-col gap-1">
                            <h1 className="lg:text-[24px] text-xl mt-4 font-bold md:whitespace-nowrap">Pictures of the place</h1>
                            <div className="text-sm font-thin mb-6">A few beautiful photos will help customers have more sympathy for your property.</div>
                            <div className="flex flex-col items-start gap-2">
                              <h4 className="font-semibold ">Cover image</h4>
                              <label htmlFor="img1" className="px-8 flex cursor-pointer flex-col items-center py-4 rounded-xl border-2 border-gray-500 border-dotted" >
                                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M21 5H5C3.93913 5 2.92172 5.42143 2.17157 6.17157C1.42143 6.92172 1 7.93913 1 9V29M1 29V33C1 34.0609 1.42143 35.0783 2.17157 35.8284C2.92172 36.5786 3.93913 37 5 37H29C30.0609 37 31.0783 36.5786 31.8284 35.8284C32.5786 35.0783 33 34.0609 33 33V25M1 29L10.172 19.828C10.9221 19.0781 11.9393 18.6569 13 18.6569C14.0607 18.6569 15.0779 19.0781 15.828 19.828L21 25M33 17V25M33 25L29.828 21.828C29.0779 21.0781 28.0607 20.6569 27 20.6569C25.9393 20.6569 24.9221 21.0781 24.172 21.828L21 25M21 25L25 29M29 5H37M33 1V9M21 13H21.02" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div className="text-sm"><span className="text-blue-500 ">Upload a file</span><span>or drag and drop</span></div>
                                <span className="text-gray-500 text-xs">PNG, JPG, GIF up to 10MB</span>

                                <input type="file" id="img1" accept="image/*" hidden />
                              </label>
                              <h4 className="font-semibold ">Pictures of the placec</h4>
                              <label htmlFor="img2" className="px-8 flex cursor-pointer flex-col items-center py-4 rounded-xl border-2 border-gray-500 border-dotted" >
                                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M21 5H5C3.93913 5 2.92172 5.42143 2.17157 6.17157C1.42143 6.92172 1 7.93913 1 9V29M1 29V33C1 34.0609 1.42143 35.0783 2.17157 35.8284C2.92172 36.5786 3.93913 37 5 37H29C30.0609 37 31.0783 36.5786 31.8284 35.8284C32.5786 35.0783 33 34.0609 33 33V25M1 29L10.172 19.828C10.9221 19.0781 11.9393 18.6569 13 18.6569C14.0607 18.6569 15.0779 19.0781 15.828 19.828L21 25M33 17V25M33 25L29.828 21.828C29.0779 21.0781 28.0607 20.6569 27 20.6569C25.9393 20.6569 24.9221 21.0781 24.172 21.828L21 25M21 25L25 29M29 5H37M33 1V9M21 13H21.02" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div className="text-sm"><span className="text-blue-500 ">Upload a file</span><span>or drag and drop</span></div>
                                <span className="text-gray-500 text-xs">PNG, JPG, GIF up to 10MB</span>

                                <input type="file" id="img2" accept="image/*" hidden />
                              </label>
                            </div>
                          </div>

                          <DialogActions>
                            <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                            <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>

                          </DialogActions>
                        </div>
                        : count === 8 ?
                          <div className="p-4 md:min-w-[600px]">
                            <div className="flex font-bold text-2xl"><h1>08</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                            <div className="mx-4 flex flex-col gap-1">
                              <h1 className="lg:text-[24px] text-xl mt-4 font-bold md:whitespace-nowrap">Price your space</h1>
                              <div className="text-sm font-thin mb-6">The host's revenue is directly dependent on the setting of rates and regulations on the number of guests, the number of nights, and the cancellation policy.</div>
                              {/* <h4 className="font-semibold">General amenities</h4> */}
                              <label htmlFor="#label" className="text-sm">Currency</label>
                              <Select className="max-w-[370px]" id="label" value={"USD"}>
                                <MenuItem value="USD">USD</MenuItem>
                              </Select>
                              <label htmlFor="#label4" className="text-sm"> Base price  (Monday -Thuday)</label>
                              <div className="relative z-0 md:mr-auto"><svg className="absolute scale-125 z-10 top-3 left-2" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.378 9.326C7.378 9.774 7.266 10.2033 7.042 10.614C6.818 11.0153 6.48667 11.3513 6.048 11.622C5.61867 11.8927 5.10067 12.0467 4.494 12.084V13.218H3.738V12.084C2.88867 12.0093 2.198 11.7387 1.666 11.272C1.134 10.796 0.863333 10.194 0.854 9.466H2.212C2.24933 9.858 2.394 10.1987 2.646 10.488C2.90733 10.7773 3.27133 10.9593 3.738 11.034V7.534C3.11267 7.37533 2.60867 7.212 2.226 7.044C1.84333 6.876 1.51667 6.61467 1.246 6.26C0.975333 5.90533 0.84 5.42933 0.84 4.832C0.84 4.076 1.10133 3.45067 1.624 2.956C2.156 2.46133 2.86067 2.186 3.738 2.13V0.967999H4.494V2.13C5.28733 2.19533 5.92667 2.452 6.412 2.9C6.89733 3.33867 7.17733 3.91267 7.252 4.622H5.894C5.84733 4.29533 5.70267 4.00133 5.46 3.74C5.21733 3.46933 4.89533 3.292 4.494 3.208V6.624C5.11 6.78267 5.60933 6.946 5.992 7.114C6.384 7.27267 6.71067 7.52933 6.972 7.884C7.24267 8.23867 7.378 8.71933 7.378 9.326ZM2.142 4.762C2.142 5.21933 2.27733 5.56933 2.548 5.812C2.81867 6.05467 3.21533 6.25533 3.738 6.414V3.18C3.25267 3.22667 2.86533 3.38533 2.576 3.656C2.28667 3.91733 2.142 4.286 2.142 4.762ZM4.494 11.048C4.998 10.992 5.39 10.81 5.67 10.502C5.95933 10.194 6.104 9.82533 6.104 9.396C6.104 8.93867 5.964 8.58867 5.684 8.346C5.404 8.094 5.00733 7.89333 4.494 7.744V11.048Z" fill="#6B7280" />
                              </svg>
                                <span className="text-gray-500 text-base absolute z-10 right-2 top-2">USD</span>
                                <Input id="label4" className="md:min-w-[370px] pl-8 pr-14 border-gray-500 text-gray-600" placeholder="0.00" />
                              </div>
                              <label htmlFor="#label4" className="text-sm">Base price  (Friday-Sunday)</label>
                              <div className="relative z-0 md:mr-auto"><svg className="absolute scale-125 z-10 top-3 left-2" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.378 9.326C7.378 9.774 7.266 10.2033 7.042 10.614C6.818 11.0153 6.48667 11.3513 6.048 11.622C5.61867 11.8927 5.10067 12.0467 4.494 12.084V13.218H3.738V12.084C2.88867 12.0093 2.198 11.7387 1.666 11.272C1.134 10.796 0.863333 10.194 0.854 9.466H2.212C2.24933 9.858 2.394 10.1987 2.646 10.488C2.90733 10.7773 3.27133 10.9593 3.738 11.034V7.534C3.11267 7.37533 2.60867 7.212 2.226 7.044C1.84333 6.876 1.51667 6.61467 1.246 6.26C0.975333 5.90533 0.84 5.42933 0.84 4.832C0.84 4.076 1.10133 3.45067 1.624 2.956C2.156 2.46133 2.86067 2.186 3.738 2.13V0.967999H4.494V2.13C5.28733 2.19533 5.92667 2.452 6.412 2.9C6.89733 3.33867 7.17733 3.91267 7.252 4.622H5.894C5.84733 4.29533 5.70267 4.00133 5.46 3.74C5.21733 3.46933 4.89533 3.292 4.494 3.208V6.624C5.11 6.78267 5.60933 6.946 5.992 7.114C6.384 7.27267 6.71067 7.52933 6.972 7.884C7.24267 8.23867 7.378 8.71933 7.378 9.326ZM2.142 4.762C2.142 5.21933 2.27733 5.56933 2.548 5.812C2.81867 6.05467 3.21533 6.25533 3.738 6.414V3.18C3.25267 3.22667 2.86533 3.38533 2.576 3.656C2.28667 3.91733 2.142 4.286 2.142 4.762ZM4.494 11.048C4.998 10.992 5.39 10.81 5.67 10.502C5.95933 10.194 6.104 9.82533 6.104 9.396C6.104 8.93867 5.964 8.58867 5.684 8.346C5.404 8.094 5.00733 7.89333 4.494 7.744V11.048Z" fill="#6B7280" />
                              </svg>
                                <span className="text-gray-500 text-base absolute z-10 right-2 top-2">USD</span>
                                <Input id="label4" className="md:min-w-[370px] pl-8 pr-14 border-gray-500 text-gray-600" placeholder="0.00" />
                              </div>
                              <label htmlFor="#label4" className="text-sm">Long term price (Monthly discount) </label>
                              <div className="relative z-0 md:mr-auto"><svg className="absolute scale-125 z-10 top-3 left-2" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.378 9.326C7.378 9.774 7.266 10.2033 7.042 10.614C6.818 11.0153 6.48667 11.3513 6.048 11.622C5.61867 11.8927 5.10067 12.0467 4.494 12.084V13.218H3.738V12.084C2.88867 12.0093 2.198 11.7387 1.666 11.272C1.134 10.796 0.863333 10.194 0.854 9.466H2.212C2.24933 9.858 2.394 10.1987 2.646 10.488C2.90733 10.7773 3.27133 10.9593 3.738 11.034V7.534C3.11267 7.37533 2.60867 7.212 2.226 7.044C1.84333 6.876 1.51667 6.61467 1.246 6.26C0.975333 5.90533 0.84 5.42933 0.84 4.832C0.84 4.076 1.10133 3.45067 1.624 2.956C2.156 2.46133 2.86067 2.186 3.738 2.13V0.967999H4.494V2.13C5.28733 2.19533 5.92667 2.452 6.412 2.9C6.89733 3.33867 7.17733 3.91267 7.252 4.622H5.894C5.84733 4.29533 5.70267 4.00133 5.46 3.74C5.21733 3.46933 4.89533 3.292 4.494 3.208V6.624C5.11 6.78267 5.60933 6.946 5.992 7.114C6.384 7.27267 6.71067 7.52933 6.972 7.884C7.24267 8.23867 7.378 8.71933 7.378 9.326ZM2.142 4.762C2.142 5.21933 2.27733 5.56933 2.548 5.812C2.81867 6.05467 3.21533 6.25533 3.738 6.414V3.18C3.25267 3.22667 2.86533 3.38533 2.576 3.656C2.28667 3.91733 2.142 4.286 2.142 4.762ZM4.494 11.048C4.998 10.992 5.39 10.81 5.67 10.502C5.95933 10.194 6.104 9.82533 6.104 9.396C6.104 8.93867 5.964 8.58867 5.684 8.346C5.404 8.094 5.00733 7.89333 4.494 7.744V11.048Z" fill="#6B7280" />
                              </svg>
                                <span className="text-gray-500 text-sm absolute z-10 right-2 top-2.5">every month</span>
                                <Input id="label4" className="md:min-w-[370px] border pl-8 pr-24 border-gray-500 text-gray-600" placeholder="0.00" />
                              </div>
                            </div>

                            <DialogActions>
                              <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                              <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>

                            </DialogActions>
                          </div>
                          : count === 9 ?
                            <div className="p-4 md:min-w-[600px]">
                              <div className="flex font-bold text-2xl"><h1>09</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                              <div className="mx-4 flex flex-col gap-1">
                                <h1 className="lg:text-[24px] text-xl mt-4 font-bold md:whitespace-nowrap">How long can guests stay?</h1>
                                <div className="text-sm font-thin mb-6">Shorter trips can mean more reservations, but you'll turn over your space more often.</div>
                                <NcInputNumber
                                  className="w-full"
                                  // defaultValue={guestAdultsInputValue}
                                  // onChange={(value) => handleChangeData(value, "guestAdults")}
                                  max={10}
                                  min={1}
                                  label="Nights min"
                                />
                                <NcInputNumber
                                  className="w-full"
                                  // defaultValue={guestAdultsInputValue}
                                  // onChange={(value) => handleChangeData(value, "guestAdults")}
                                  max={10}
                                  min={1}
                                  label="Nights max"
                                />
                                <h4 className="font-semibold mt-4">Pictures of the placec</h4>
                                <div className="text-sm font-thin mb-6">Editing your calendar is easy—just select a date to block or unblock it. You can always make changes after you publish.</div>
                              </div>

                              <DialogActions>
                                <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                                <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Continue</div>

                              </DialogActions>
                            </div>
                            : <div className="p-4 md:min-w-[600px]">
                              <div className="flex font-bold text-2xl"><h1>10</h1><div className="font-normal mt-1 text-lg">/10</div></div>
                              <div className="mx-4 flex flex-col gap-1">
                                <h1 className="lg:text-[24px] text-xl mt-4 font-bold md:whitespace-nowrap">Congratulations 🎉</h1>
                                <div className="text-sm font-thin mb-6">Excellent,congratulations on completing the listing, it is waiting to be reviewed for publication</div>
                                {/* <h4 className="font-semibold">General amenities</h4> */}
                              </div>

                              <DialogActions>
                                <div onClick={handlePrev} className="px-4 rounded-full cursor-pointer py-2 border-[1px] border-gray-300 mr-4">Go Back</div>
                                <div onClick={handleNext} className="px-4 py-2 cursor-pointer text-white rounded-full bg-[#41C5F2]">Submit</div>

                              </DialogActions>
                            </div>
                            }</Box>
          </Modal>
    
  </div>
  );
};

export default ButtonSubmit;
