import { ActivityModel } from "./BookTripModel/ActivityModel";

export interface TripPackageModel {
  type: "tripPackage";
  id: number;
  numberOfActivities: number;
  isBookMarked: boolean;
  images: string[];
  heading: string;
  description: string;
  uniqueId: string;
  area: string;
  rating: string;
  manager: string;
  hotelType: string;
  meal: string;
  activity: string;
  itineraryType: string;
  itinerarySubType: string;
  duration: string;
  activities: ActivityModel[];
  accommodationList: string[];
  personCount: string;
  cancelPayment?: boolean;
  cancelPaymentamount?: string;
  cancelPaymentRemark?: string;
  cancelPaymentTransaction?: string;
  cancelPaymentDate?: string;
  price: string;
}

export function instanceOfTripPackageModel(
  data: any
): data is TripPackageModel {
  return data.type === "tripPackage";
}

export function createTripPackageObject(data: any): TripPackageModel {
  /**
   *  Data has images property which hold array of objects. Each object has a "package_image"
   * property which hold the image url. So, we have to first create an array of image url.
   *
   */
  const images: string[] = [];
  for (let image of data.images) {
    images.push(image.package_images);
  }

  const result: TripPackageModel = {
    type: "tripPackage",
    id: data.id,
    numberOfActivities: data.number_of_activities,
    images: images,
    heading: data.heading,
    isBookMarked: data.is_bookmarked,
    description: data.description,
    uniqueId: data.unique_id,
    area: data.area,
    rating: data.rating,
    manager: data.managed_by,
    hotelType: data.hotel_type,
    meal: data.meal,
    activity: data.activity,
    itineraryType: data.itinerary_type,
    itinerarySubType: data.itinerary_sub_type,
    duration: data.duration,
    activities: [],
    accommodationList: data.accommodation_list,
    personCount: data.person_count,
    price: data.price,
  };

  return result;
}
