import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/Subscribe.png";
import trip from "images/logo.png"
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import axios from "axios";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const [email,setEmail] = useState("") 
  const [success,setSuccess] = useState(false)

  const data ={
  email: email,
  page_name:"subscribe"
  }

  const handleAPI = () => {
    setSuccess(false)
    axios.post("/wft_user/contactform-create",data)
    .then(res => {
      setSuccess(true)
      setEmail('We Received your Email')
    })
    .catch(err=>{
      alert("Server Error")
    })
  }

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row md:p-10 lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <img src={trip} alt=""/>
        <h2 className="font-semibold text-4xl mt-5">Subscribe for Updates</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
        We send you new package updates and special offers, not just that you will get access to our travel community 
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
            Experience unique cultures
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="yellow" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
            Connect with fellow travelers
            </span>
          </li>
        </ul>
        <div className="mt-10 relative max-w-sm">
          <Input
            value={email}
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
            onChange={(e) => setEmail(e.target.value)}
            className={`${!success?'bg-white':'bg-[#F19838]'}`}
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px] bg-[#F19838]"
            onClick={handleAPI} 
          >
            <i className={`las text-xl ${!success?"la-arrow-right":"la-check"}`}></i>
          </ButtonCircle>
        </div>
      </div>
      <div className="flex">
        <NcImage src={rightImg} alt=""/>
      </div>
    </div>
  );
};

export default SectionSubscribe2;
