import Glide from "@glidejs/glide";
/*import Heading from "components/Heading/Heading";*/
import React, { FC, useState } from "react";
import { useEffect } from "react";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import useNcId from "hooks/useNcId";
import axios from "axios";

export interface SectionClientSayProps {
  className?: string;
  uniqueClassName: string;
}

const DEMO_DATA = [
  {
    id: 1,
    clientName: "DHARMESH SHAH",
    clientAddress: "CTO and Founder of HubSpot",
    content: "Success is making those who believed in you look brilliant",
  },
  {
    id: 2,
    clientName: "DHARMESH SHAH",
    clientAddress: "CTO and Founder of HubSpot",
    content: "Success is making those who believed in you look brilliant",
  },
  {
    id: 3,
    clientName: "DHARMESH SHAH",
    clientAddress: "CTO and Founder of HubSpot",
    content: "Success is making those who believed in you look brilliant",
  },
];

const SectionClientSay1: FC<SectionClientSayProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const [quotation, setQuotation] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  useEffect(() => {
    axios
      .get("/wft_admin/about-us-list")
      .then((response) => {
        setQuotation(response.data[0].quotation);
        setName(response.data[0].name);
        setDesignation(response.data[0].designation);
        console.log(response.data);
        console.log(quotation, "USER name");
      })
      .catch((error) => {
        alert(error);
      });
  });
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
        }).mount();
      }, 10);
    }
  }, []);

  const renderBg = () => {
    return <div className="hidden md:block"></div>;
  };

  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      {/*<Heading desc="Lorem ipsum dolor sit amet, consectetur" isCenter>
      Lorem ipsum dolor sit 
  </Heading>*/}
      <div className="relative md:mb-16 max-w-2xl mx-auto">
        {renderBg()}
        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          <img
            className="opacity-50 md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-1"
            src={quotationImg}
            alt=""
          />
          <img
            className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
            src={quotationImg2}
            alt=""
          />
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              <li className="glide__slide flex flex-col items-center text-center">
                <span className="block text-2xl">
                  "A mind stretched by new experiences can never go back to it's
                  old dimensions. <span className="text-violet-600 font-semibold">Travel, Learn, Grow.</span>"
                </span>
                <span className="block mt-8 text-2xl font-semibold ">
                  Prajna Brata Kar
                </span>
                {/* <div className="flex items-center space-x-2 text-lg mt-2 text-neutral-400">
                  <span>{designation}</span>
                </div> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionClientSay1;
