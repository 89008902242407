import TripPackageCard from "components/TripPackage/TripPackageCard";
import { getUserTrips } from "controller/accountTripsController";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import { TripPackageModel } from "models/TripPackageModel";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpErrorAction } from "utils/controllerResponseHandler";

export default function PreviousTrips({
  tripType,
  showTripCancelButton = false
}: {
  tripType: "upcoming" | "previous" | "canceled";
  showTripCancelButton?:boolean
}) {
  const [tripList, setTripList] = useState<TripPackageModel[]>([]);
  const navigate = useNavigate();
  async function handlePreviousTripList() {
    const res = await getUserTrips(tripType);
    if (!instanceOfHttpResponse(res)) {
      setTripList(res);
    } else {
      httpErrorAction(res, navigate);
    }
  }
  useEffect(() => {
    handlePreviousTripList();
  }, []);
  return (
    <div>
      {tripList.length > 0 ? (
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
          {tripList.map((data, index) => (
            <TripPackageCard key={index} data={data} showCancelButton={showTripCancelButton} />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">No Trip Found</p>
      )}
    </div>
  );
}
