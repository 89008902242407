import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe5 from "components/SectionSubscribe2/SectionSubscribe5";
import SectionStatistic1 from "./SectionStatistic1";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Way Find Trips</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">

          <SectionSubscribe5 />

          <SectionStatistic1/>    

        
      </div>
    </div>
  );
};

export default PageAbout;
