import axios from "axios";
import { blogApi, blogDetailApi } from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { createBlogObject } from "models/BlogModel/BlogModel";
import { BlogModel } from "models/BlogModel/BlogModel";
import { HttpResponse, instanceOfHttpResponse } from "models/HttpStatusModel";


/**
 * @param page page number
 * @returns Blog list or HttpStatus
 */

export async function getBlogList(page:number) {
  const res = await asyncHandler(`${blogApi}&page=${page}`,"GET");
  const result: [BlogModel[] | null, HttpResponse | null] = [null, null];
  if (!instanceOfHttpResponse(res)) {
    const blogList: BlogModel[] = [];
    for (let data of res.data.results) {
      const blog: BlogModel = createBlogObject(data);
      blogList.push(blog);
    }
    result[0] = blogList;
  } else {
    result[1] = res;
  }
  return result;
}

/**
 * 
 * @param id Blog id
 * @returns  blog details or HttpStatus
 */

export async function getBlogDetail(id: string) {
  const res = await asyncHandler(`${blogDetailApi}/${id}`,"GET");
  const result: [BlogModel | null, HttpResponse | null] = [null, null];
  if (!instanceOfHttpResponse(res)) {
    const blog: BlogModel = createBlogObject(res.data);
    result[0] = blog;
  } else {
    result[1] = res;
  }
  return result;
}
