import axios from "axios";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import { TaxonomyType } from "data/types";
import React, { useEffect, useState } from "react";
import Day from "images/3N4D.png";
import Day1 from "images/4N5D.png";
import Day2 from "images/5N6D.png";
import Day3 from "images/6N7D.png";
import Day5 from "images/7N8D.png";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: `/packagecollection?duration=3N4D&package_type=Economy`,
    name: "3N4D - Haven",
    taxonomy: "category",
    count: 13,
    thumbnail: Day,
  },
  {
    id: "2",
    href: `/packagecollection?duration=4N5D&package_type=Economy`,
    name: "4N5D - Hideout",
    taxonomy: "category",
    count: 4,
    thumbnail: Day1,
  },
  {
    id: "3",
    href: `/packagecollection?duration=5N6D&package_type=Economy`,
    name: "5N6D - Voyage",
    taxonomy: "category",
    count: 3,
    thumbnail: Day2,
  },
  {
    id: "4",
    href: `/packagecollection?duration=6N7D&package_type=Economy`,
    name: "6N7D - Aventure",
    taxonomy: "category",
    count: 2,
    thumbnail: Day3,
  },
  {
    id: "6",
    href: "/packagecollection?duration=7N8D&package_type=Economy",
    name: "7N8D - Discovery",
    taxonomy: "category",
    count: 6,
    thumbnail: Day5,
  },
];

const CollectionPackage = () => {
  const [collectionpackage, setCollectionpackage] =
    useState<TaxonomyType[]>(DEMO_CATS);
  useEffect(() => {
    axios
      .get("/wft_user/user-dashboard")
      .then((response) => {
        const newArr = [...collectionpackage];

        for (let x of collectionpackage) {
          if (x.name === "Basic Packages") {
            x.count = response.data["basic_pkg_count"] as number;
          }
          if (x.name === "Luxury Packages") {
            x.count = response.data["luxury_pkg_count"] as number;
          }
          if (x.name === "Elit Packages") {
            x.count = response.data["elite_pkg_count"] as number;
          }
          if (x.name === "Group Packages") {
            x.count = response.data["group_pkg_count"] as number;
          }
          if (x.name === "Customize Packages") {
            x.count = response.data["cutomize_pkg_count"] as number;
          }
        }
        setCollectionpackage(newArr);
      })
      .catch((error) => {
        alert(error);
      });
  }, []);
  return (
    <div>
      <SectionSliderNewCategories
        categories={collectionpackage}
        uniqueClassName="PageHome_s1"
      />
    </div>
  );
};

export default CollectionPackage;
