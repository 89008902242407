import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TripPackageModel } from "models/TripPackageModel";

interface InitialState {
  popularPackageList: TripPackageModel[];
}

const initialState: InitialState = {
  popularPackageList: [],
};

const createPackageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    setPopularPackageList: (
      state,
      action: PayloadAction<TripPackageModel[]>
    ) => {
      state.popularPackageList = action.payload;
    },
  },
});

export default createPackageSlice.reducer;
export const { setPopularPackageList } = createPackageSlice.actions;
