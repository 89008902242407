import React from "react";
import { FerryTravelModel } from "models/BookTripModel/FerryTravelMode";
import ferrySvg from "../../../images/activity/ferrysvg.svg";
import ferryImg from "../../../images/activity/ferry.jpeg";

export default function BookTripFerryTravel({
  ferryTravel,
}: {
  ferryTravel: FerryTravelModel;
}) {
  return (
    <div>
      <div className="flex items-center">
        <img src={ferrySvg} alt="hotel" height={30} width={30} />
        <p className="ml-2">
           {ferryTravel.source}-{ferryTravel.dest}
        </p>
      </div>
      {ferryTravel.heading !== null && <p className="text-sm leading-1 text-gray-600 mt-2">{ferryTravel.heading}</p>}
      <div className="mt-6 flex sm:flex-row flex-col">
        <img
          src={ferryImg}
          alt="img"
          width="150"
          height="150"
          className="rounded-sm"
        />
        <div className=" sm:mt-0 mt-2  sm:ml-4">
          <div className="flex items-center mt-4">
            <div className="flex flex-col items-center">
              <p className="mt-2 text-gray-600 text-sm">Ferry type</p>
              <p className="mt-2 font-semibold text-sm">
                {ferryTravel.ferryType}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col ">
        <h4 className="font-semibold">Inclusion</h4>
        <p>{ferryTravel.description}</p>
      </div>
    </div>
  );
}
