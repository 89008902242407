import rossIsland from "images/discoverplaces/ross/RossIsland.jpg"
export default function RossIsland() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">Ross Island</h1>
        <p className="mt-6 text-sm sm:text-base">
          Ross Island is a small island located in the Andaman and Nicobar
          Islands, an archipelago in the Bay of Bengal. The island is situated
          about 2 km east of Port Blair, the capital city of the Andaman and
          Nicobar Islands. Ross Island is a popular tourist destination, known
          for its historical significance, natural beauty, and adventure
          activities. In this article, we will provide a comprehensive overview
          of Ross Island, including its location, history, attractions,
          activities, and more.
        </p>
      </div>
      <img
        src={rossIsland}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">Location</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Ross Island is located in the Andaman and Nicobar Islands, an
            archipelago of 572 islands in the Bay of Bengal. The island is
            situated about 2 km east of Port Blair, the capital city of the
            Andaman and Nicobar Islands. Ross Island is accessible by boat from
            Port Blair.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Historical significance</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Ross Island was named after Sir Daniel Ross, a marine surveyor who
            surveyed the island in 1788. The island was developed as a
            self-sufficient settlement by the British Raj in the Andaman and
            Nicobar Islands. Ross Island was the administrative headquarters of
            the British government in the Andaman and Nicobar Islands from 1858
            to 1942.
          </p>
          <p>
            During this period, the island served as the centre of British
            colonial power in the region. The British built a number of colonial
            buildings on the island, including a church, a hospital, a bakery, a
            printing press, and various administrative buildings. The British
            established their administrative headquarters on Ross Island, which
            was equipped with facilities like a church, bakery, swimming pool,
            tennis court, and more.
          </p>
          <p>
            The island also served as a penal colony during the colonial era.
            The British used Ross Island to exile political prisoners and
            convicts from India. Many of these prisoners were subjected to
            inhumane treatment, and some of them died on the island.
          </p>
          <p>
            Ross Island was abandoned after an earthquake in 1941, and the
            British shifted their administrative headquarters to Port Blair. In
            1942, during World War II, the Japanese army invaded the Andaman and
            Nicobar Islands and captured Ross Island. The Japanese used the
            island as a military base and built a number of military structures,
            including bunkers, gun emplacements, and underground tunnels. The
            Japanese also forced the British prisoners of war to work on the
            construction of these structures.
          </p>
          <p>
            After the end of World War II, the British regained control of the
            Andaman and Nicobar Islands, but they did not return to Ross Island.
            The island was left abandoned, and the buildings and other
            structures were left to decay. After India gained independence in
            1947, the Indian government took over the island, and it was
            declared a protected area in 1977.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Attractions</h2>
        <p className="mt-2 text-justify text-sm sm:text-base">
          Ross Island is known for its natural beauty, historical significance,
          and adventure activities. Here are some of the top attractions on Ross
          Island:
        </p>
        <ol className="mt-2 list-decimal">
          <li>
            <span className="font-semibold">British-era runins:</span> Ross
            Island has several British-era ruins, including a church, bakery,
            swimming pool, tennis court, and more. These ruins are a testament
            to the island's colonial past and provide a glimpse into the
            island's history.
          </li>
          <li>
            <span className="font-semibold">Ross Island Museum:</span>The Ross
            Island Museum displays artifacts and photographs from the British
            era and provides information about the island's history. The museum
            also has a souvenir shop where visitors can purchase souvenirs and
            gifts.
          </li>
          <li>
            <span className="font-semibold">Trekking:</span> Ross Island offers
            several trekking trails that allow visitors to explore the island's
            natural beauty and historical sites. The trails are well-marked and
            offer stunning views of the island's flora and fauna.
          </li>
          <li>
            <span className="font-semibold">British Cemetery:</span> The British
            Cemetery on Ross Island is the final resting place of many British
            soldiers and civilians who lived and worked on the island during the
            colonial era. The cemetery is a somber reminder of the island's dark
            past and the suffering that many people endured.
          </li>
          <li>
            <span className="font-semibold">Japanese Bunkers:</span>The Japanese
            Bunkers on Ross Island are a series of underground tunnels and
            bunkers that were built by the Japanese army during World War II.
            Visitors can explore the tunnels and learn about the history of the
            island during the war.
          </li>
          <li>
            <span className="font-semibold">Deer Park:</span>The Deer Park on
            Ross Island is a beautiful park that is home to a large population
            of spotted deer. Visitors can watch the deer grazing and playing in
            the park, and they can also take a leisurely stroll through the
            park's lush greenery.
          </li>
        </ol>
        <h2 className="text-xl font-semibold mt-14">Accommodation</h2>
        <p className="mt-2 text-justify text-sm sm:text-base">
          Ross Island does not have any accommodation options, as it is a
          protected area. However, visitors can stay in Port Blair, which is
          located just 2 km away from Ross Island. Port Blair offers a range of
          accommodation options, including budget hotels, mid-range hotels, and
          luxury resorts.
        </p>
        <h2 className="text-xl font-semibold mt-14">How to Reach</h2>
        <div>
          <h3 className="text-lg font-semibold mt-4">By Boat</h3>
          <p className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            The Natural Bridge is formed by the natural erosion of the limestone
            rocks over millions of years. The wind and waves have created a
            natural archway that spans across the sea, and the bridge appears as
            if it has been carved out of the rock. The bridge is a unique
            natural phenomenon and is considered one of the most picturesque
            spots on the island.
          </p>
          <h3 className="text-lg font-semibold mt-4">Travel Tips</h3>
          <ul className="mt-2 list-disc">
            <li>
              Visitors are required to obtain a permit from the Forest
              Department in Port Blair before visiting Ross Island.
            </li>
            <li>
              The boat ride to Ross Island can be rough during the monsoon
              season, and visitors are advised to check the weather conditions
              before planning their trip.
            </li>
            <li>
              Visitors are not allowed to stay on the island overnight, and they
              must return to Port Blair before sunset.
            </li>
            <li>
              The island does not have any food or water facilities, so visitors
              are advised to carry their own food and water.
            </li>
            <li>
              The island has limited mobile network coverage, and visitors may
              not be able to make calls or access the internet on the island.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
