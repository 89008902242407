import React from "react";

const tagsColorArr = [
  {
    text: "text-red-700",
    bg: "bg-red-300",
  },
  {
    text: "text-blue-700",
    bg: "bg-blue-300",
  },
  {
    text: "text-yellow-700",
    bg: "bg-yellow-300",
  },
  {
    text: "text-lime-700",
    bg: "bg-lime-300",
  },
  {
    text: "text-purple-700",
    bg: "bg-purple-300",
  },
  {
    text: "text-fuchsia-700",
    bg: "bg-fuchsia-300",
  },
];

function getTagColor(index: number) {
  let pos = index;
  if (pos > tagsColorArr.length - 1) {
    pos = pos - tagsColorArr.length;
  }
  const { text, bg } = tagsColorArr[pos];
  return `${text} ${bg}`;
}


/**
 * 
 * @param index  index value for choosing the color. The value must be 0 to 5
 * @param value   badge text
 * @returns 
 */

export default function CardBadge({
  index,
  value,
  fontsize = 10
}: {
  index: number;
  value: string;
  fontsize?:number
}) {
  return (
    <div
      className={` text-[${fontsize}px] m-2 px-2  rounded-2xl ${getTagColor(index)}`}
      key={index}
    >
      {value}
    </div>
  );
}
