
import  { FC } from "react";
import SectionFounder from "./SectionFounder";
import { Helmet } from "react-helmet";
import SectionClientSay1 from "components/SectionClientSay/SectionClientSay1";


export interface PageAboutProps {
  className?: string;
  about_us?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Way Find Trips</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About us"
          btnText=""
          subHeading={about_us}
        />

          <SectionSubscribe4 />

          <SectionSubscribe6/> */}

        {/* <SectionStatistic /> */}
      

        <div className="text-justify">
          <h1 className="text-center text-4xl  lg:text-5xl font-semibold"> 📖 Our story</h1>
          <div className=" mt-24 text-justify">
            <h4>
              <strong>
                2016:December- I leave Chennai to Join the Family Business in
                Andaman.
              </strong>
            </h4>
            <p className="mt-4">
              I knew very little about the travel industry but was determined to
              make my mark in it. Having left the IT Profession and choosing
              Tourism as my future met with a lot of criticism. The more people
              looked down on me, the more resilient and determined I became.
            </p>
          </div>
          <div className=" mt-12">
            <h4>
              <strong>2017: Setting up the Business</strong>
            </h4>
            <p className="mt-4">
              From creating the website to setting up a reservation team in
              Andaman was an uphill challenge. The network in Andaman in those
              days was on 2G and call congestion was at its peak. We used the
              Gmail lite version to send and receive mails and on our best days
              were able to reply to a few mails. From receiving travelers from
              Airport, to guiding them, driving them around the town, briefing
              them about their tour, no job seems small or less important.
            </p>
            <p className="mt-2">
              Despite the limitations and challenges we all faced as a team, our
              spirit remained unbroken.
            </p>
          </div>
          <div className=" mt-12 text-justify">
            <h4>
              <strong>2018: The special Year </strong>
            </h4>
            <p className="mt-4">
              By meeting different people, I gained an appreciation on how
              travel changes our lives. It is a reflection of one's passions,
              aspirations and desires. The more I interacted with individuals,
              some on their Honeymoon, other on their Anniversary or a family
              vacation I started to realize that understanding their travel
              requirements went beyond simply listening to their requests.
            </p>
            <p className="mt-4">
              Travel is not a one-size-fits-all experience but it is truly a
              deeply personal and subjective pursuit. Each conversation kept
              fueling my collective wanderlust.
              <br />
              It also gave fuel to my understanding that there are two kinds fo
              travels in general:
            </p>
            <ol className="list-decimal mt-4 ml-8">
              <li>
                One who likes to spend more on the Stay rather than the travel
              </li>
              <li>
                Other who likes to spend on travel rather than the stay itself
              </li>
            </ol>
            <p className="mt-4">
              During such an interaction, I met Khushbu. She was deputy Manager
              at HDFC Bank, Mumbai at that time. As a mumbaikar she loved
              traveling and we shared a lot of common interests.
            </p>
          </div>
          <div className=" mt-12 ">
            <h4>
              <strong>2019: Mumbai se aaya mera Dost</strong>
            </h4>
            <p className="mt-4">
              This year was the best year of all. I married Khushbu. It turned
              out to be the best decision I had taken in my entire life. She had
              to quit her high paying job in HDFC and shift to Port Blair as
              transfer to Andaman was not going through.
            </p>
            <p className="mt-4">
              She joined the business and instantly improved it. Her new idea
              and corporate approach to things got us good deals and great
              business. As a seasoned solo traveler herself, she single handedly
              revolutionized the way we curate experiences and connect with
              travelers like you.
            </p>
            <p className="mt-4">
              She introduced personalized itineraries, tailored to individual
              preferences, ensuring that every journey was a reflection of the
              traveler's unique identity. We started off-beat destinations such
              as Long Islands, Diglipur and Rangat. Our reviews were filled with
              appreciation for Khushbu and her Team.
            </p>
            <p className="mt-4">
              Our reservation team grew in leaps and bounds. During this same
              year we sealed a package of 152 members group single day arrival,
              the highest ever in the history of Andaman Travel. I couldn’t be
              any prouder.
            </p>
          </div>
          <div className="mt-14">
            <h4>
              <strong>2020 - 21: From Crisis to Courage</strong>
            </h4>
            <p className="mt-4">
              This year was a time of immense challenges, heart-wrenching
              setbacks, and a lot of uncertainty. All our hard work, spirit and
              dedication in those years couldn’t fetch us any salaries. Funds
              weren’t managed well by the firm's management and the entire team
              collapsed, leaving behind a trail of shattered dreams and
              uncertain paths ahead. No salary for almost more than a year. When
              the lockdown got lifted in October, We were the only two left
              working. Soon the Second lockdown stuck and everything we believed
              almost ceased to exist.
            </p>
            <p className="mt-4">
              The internal battle we fought during this time still runs deep. We
              decided to navigate the road to recovery and promised ourselves
              that this crisis will not define us.
            </p>
            <p className="mt-4">
              The story behind the name Wayfind is rooted in the belief that no
              matter where we are in our journey, we can always find our way
              forward.
            </p>
          </div>
          <div className="mt-14">
            <h4>
              <strong>2022: We Found our Way</strong>
            </h4>
            <p className="mt-4">
              We decided to change things as we entered this new era of travel
              post Covid. We started the registration process of your new firm
              WAYFIND TRIPS PRIVATE LIMITED. We all also welcomed our first
              Child during the process.
            </p>
            <p className="mt-4">
              The Core members of Wayfind were found this year.
            </p>
            <p className="mt-4">
              Abhishek Koshy: <br /> The First Candidate we interviewed. The man
              with a golden heart. Punctual, dedicated and determined, he holds
              the record for the fastest package sealed in our firm: First call
              and within 25 min Advance payment. The record was previously held
              by me at 35 mins. You cannot match his level of consistency, Our
              Rahul Dravid.
            </p>
            <p className="mt-4">
              Tanveer Mustafa:
              <br /> The last candidate for the interview. We had closed our
              interview for the day and walked in a guy in formals with his
              resume. We were already settled on the candidate to hire however
              he looked like the ticket worth buying. Since that fateful call,
              he has been an integral part of our team. He almost broke the
              record for the most number of packages in a month (13) held till
              date by Khushbu (16) in his first month itself. He is also known
              for his love for Briyani’s.
            </p>
            <p className="mt-4">
              Murugan S: <br /> The day he came for an interview, there was a
              load shedding(Power Cut) in our office. Our inverter ran dry.
              Murugan sat sweating and filling the interview form. Got rejected
              for the post of Accountant, bumped into me on the way down and now
              an integral part of our team. He has a talent of speech, he can
              sell you anything. He starred in Performer of the month thrice in
              a short span of 9 months. Legend has it whenever his guests
              arrive, it rains.
            </p>
            <p className="mt-4">
              R Sakthi: <br /> The backbone of our team. Hardworking and
              dedicated. The level of commitment with which he takes care of all
              the packages. You can relax if your package has Sakthi’s name on
              it. Our reviews are filled with his appreciation. Once in the Road
              Strike, he carried the client's luggage for 1km with him so that
              the client could catch his flight on time. He is our SakthiMan.
            </p>
            <p className="mt-4">
              Our collective determination will propel us forward, reminding us
              that travel is not just a transaction but an integral catalyst for
              joy, growth, and understanding.
            </p>
            <p className="mt-4">
              WayFind Trips was formed with an idea to help people find their
              way in this world like we did, one adventure at a time and let it
              be a reminder that no matter where we are in our journey, there is
              always a way forward.
            </p>
            <p className="mt-4">
              As we look ahead to the future, we invite you to embark on new
              adventures with us, knowing that our dedication to excellence and
              our commitment to providing extraordinary travel experiences will
              always remain at the core of what we do.
            </p>
            <p className="mt-4">
              Here's to the limitless possibilities that await us on this
              incredible journey of exploration, discovery, and connection.
            </p>
            <p className="mt-4">With gratitude and wanderlust,</p>
            <p className="mt-4">
              Prajna Brata Kar <br /> Director, Co- Founder <br /> Wayfind Trips
              Pvt Ltd
            </p>
          </div>
        </div>

        <SectionFounder />
        <div className="relative py-16">
          <SectionClientSay1 uniqueClassName="PageAbout_" />
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
