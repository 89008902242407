import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
/*import SearchDropdown from "./SearchDropdown";*/
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Sign from "images/Sign.png";
import MenuBar from "shared/MenuBar/MenuBar";
/*import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";*/
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { Link } from "react-router-dom";
import GuestComponent from "shared/Auth/GuestComponent";
import AuthenticatedComponent from "shared/Auth/AuthenticatedComponent";
import { useAppSelector } from "store/reduxHooks";
import Avatar from "shared/Avatar/Avatar";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { profileImageUrl } = useAppSelector((state) => state.profileInfo);
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-8">
          <Logo className=" w-40 h-14" />
        </div>
        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>
        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <Navigation />
          <div className="hidden xl:flex items-center space-x-4">
            {/*<SwitchDarkMode />*/}
            {/*<SearchDropdown />*/}
            <div className="px-1" />
            <GuestComponent>
              <ButtonPrimary href="/signup" className="bg-[#0E1B43]">
                <img src={Sign} alt="" />
                <span className="ml-3">Sign In / Sign Up</span>
              </ButtonPrimary>
            </GuestComponent>
            <AuthenticatedComponent>
              <Link to="/account">
                <button>
                  {profileImageUrl !== null ?  <img
                    src={profileImageUrl}
                    alt="profile"
                    height="50"
                    width="50"
                    className="rounded-full object-fill w-16 h-16"
                  />:<Avatar sizeClass="w-16 h-16" />}
                </button>
              </Link>
            </AuthenticatedComponent>
          </div>

          <div className="flex xl:hidden items-center">
            {/*<SwitchDarkMode />*/}
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
