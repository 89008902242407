import TripPackageCard from "components/TripPackage/TripPackageCard";
import { savePackageList } from "controller/savedListController";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import { TripPackageModel } from "models/TripPackageModel";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpErrorAction } from "utils/controllerResponseHandler";

export default function SavedPackages() {
  const [savedPackages, setSavedPackages] = useState<TripPackageModel[]>([]);
  const [fetchingPackages, setFetchingPackages] = useState(false);
  const navigate = useNavigate();

  async function handleSavedPackageList() {
    setFetchingPackages(true);
    const res = await savePackageList();
    setFetchingPackages(false);
    if (!instanceOfHttpResponse(res)) {
      setSavedPackages(res);
    } else httpErrorAction(res, navigate);
  }

  useEffect(() => {
    handleSavedPackageList();
  }, []);

  if (fetchingPackages) {
    return (
      <div className="flex justify-center">
        <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-10 w-10 mt-2"></div>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {savedPackages.length > 0 &&
        savedPackages.map((data) => (
          <TripPackageCard
            removeBookMarkAction={(tripPackage) => {
              setSavedPackages((prevState) =>
                prevState.filter((data) => data.id !== tripPackage.id)
              );
            }}
            key={data.id}
            data={data}
          />
        ))}
    </div>
  );
}
