import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionSubscribe from "components/SectionSubscribe2/SectionSubscribe";
/*import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";*/
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
/*import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";*/
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
/*import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor"*/
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import SectionGridFeatureProperty from "./SectionGridFeatureProperty";
// import CardCategory6 from "components/CardCategory6/CardCategory6";
import TripPackages from "components/TripPackage/TripPackages";
import { TripPackageType } from "core/Constants";
import Heading from "components/Heading/Heading";
import CollectionPackage from "components/CollectionPackage/CollectionPackage";
import GalleryPage from "components/GalleryPage/GalleryPage";
import DiscoverPlaces from "components/packagePage/DiscoverPlaces";
import gearSvg from "images/gear.svg";
import useFetchProfileInfo from "hooks/useFetchProfileInfo";
// const DEMO_CATS: TaxonomyType[] = [
//   {
//     id: "1",
//     href: `package?package_type=${TripPackageType.Basic}`,
//     name: "Basic Packages",
//     taxonomy: "category",
//     count: 13,
//     thumbnail:
//       "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: `package?package_type=${TripPackageType.Luxury}`,
//     name: "Luxury Packages",
//     taxonomy: "category",
//     count: 4,
//     thumbnail:
//       "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "3",
//     href: `package?package_type=${TripPackageType.Elite}`,
//     name: "Elit Packages",
//     taxonomy: "category",
//     count: 3,
//     thumbnail:
//       "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "4",
//     href: `package?package_type=${TripPackageType.Group}`,
//     name: "Group Packages",
//     taxonomy: "category",
//     count: 2,
//     thumbnail:
//       "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "5",
//     href: "",
//     name: "Customize Packages",
//     taxonomy: "category",
//     count: 6,
//     thumbnail:
//       "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
// ];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];
function PageHome() {

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}

        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION 1 */}
        <CollectionPackage />

        {/* SECTION2 */}

        {/* SECTION */}

        <div className="relative py-16">
          {/* <BackgroundSection /> */}
          <h1 className="m-0 text-gray-700 text-2xl font-semibold sm:text-3xl lg:text-4xl mb-4">
            Popular Packages Chosen By People
          </h1>
          <h4 className="mb-16 text-sm sm:text-base">
            Explore our curated collection of popular packages and discover the
            best of what others have loved!
          </h4>

          {/* <SectionGridFeatureProperty /> */}
          <TripPackages
            packageInfo={{ packageType: TripPackageType.Popular }}
          />
        </div>

        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION */}
        <div className="relative py-16">
          <DiscoverPlaces />
        </div>

        {/* SECTION */}
        <SectionGridCategoryBox gridClassName=" grid-cols-2 md:grid-cols-3 lg:grid-cols-4" />

        {/* SECTION 1 */}
        {/*<div className="relative py-16">
          <BackgroundSection className="dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="More About Andaman Trip"
            subHeading="Lets go the wonderful place in world"
            sliderStyle="style2" 
            uniqueClassName="PageHome_s2"
          />
        </div>*/}

        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION */}
        {/*<div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>*/}

        {/* SECTION */}
        {/*<div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>*/}

        {/* SECTION 1 */}
        <h1 className="text-4xl font-semibold">Blogs & Gallery</h1>
        <GalleryPage />
        {/*<SectionSliderNewCategories
          heading="Testimonials & Gallery"
          categoryCardType="card5"
          itemPerRow={5}
      uniqueClassName="PageHome_s3"
        />*/}

        {/* SECTION */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div> */}
        <SectionSubscribe />
      </div>
    </div>
  );
}

export default PageHome;
