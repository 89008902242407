import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./states/blogState";
import packageReducer from "./states/packageState";
import enquiryReducer from "./states/enquiryState";
import profileInfoReducer from "./states/profileInfoState";
import loginReduder from "./states/loginState";
import forgotPasswordReducer from "./states/forgotPasswordState";
import relatedPackageReducer from "./states/relatedPackagesState";
export const store = configureStore({
  reducer: {
    blog: blogReducer,
    package: packageReducer,
    enquiry: enquiryReducer,
    profileInfo: profileInfoReducer,
    login: loginReduder,
    forgotPassword: forgotPasswordReducer,
    relatedPackage: relatedPackageReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
