import React from "react";
import CommonLayout from "./CommonLayout";
import TripList from "./TripList";

export default function AccounTripsPage() {
  return (
    <CommonLayout>
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Previous Trips</h2>
        </div>

        <TripList tripType="previous" />
      </div>
      <div className="space-y-6 sm:space-y-8 mt-24">
        <div>
          <h2 className="text-3xl font-semibold">Upcoming Trips</h2>
        </div>

        <TripList tripType="upcoming" showTripCancelButton={true} />
      </div>
      <div className="space-y-6 sm:space-y-8 mt-24">
        <div>
          <h2 className="text-3xl font-semibold">Canceled Trips</h2>
        </div>

        <TripList tripType="canceled" />
      </div>
    </CommonLayout>
  );
}
