import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  email: string;
  otp: string;
  isOtpVerified: boolean;
}

const initialState: InitialState = {
  email: "",
  otp: "",
  isOtpVerified: false,
};

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setOtp: (state, action: PayloadAction<string>) => {
      state.otp = action.payload;
    },
    setIsOtpVerified: (state, action: PayloadAction<boolean>) => {
      state.isOtpVerified = action.payload;
    },
    resetForgotPasswordState:()=>initialState
  },
});

export default forgotPasswordSlice.reducer;
export const { setEmail, setIsOtpVerified, setOtp, resetForgotPasswordState } =
  forgotPasswordSlice.actions;
