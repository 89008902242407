import { Activities } from "core/Constants";

export interface SightSeeingModel {
  type: Activities.SIGHTSEEING;
  id: number;
  heading:string|null;
  area: string;
  location: string;
  name: string;
  image: string | null;
  description: string;
  duration: string;
}

export function instanceOfSightSeeingModel(
  data: any
): data is SightSeeingModel {
  return data.type === Activities.SIGHTSEEING;
}

/**
 * 
 * @param data:any response data from the server
 * @returns This will return an object based on SightSeeingModel
 */

export function createSightSeeingObject(data: any): SightSeeingModel {
  const result: SightSeeingModel = {
    type: Activities.SIGHTSEEING,
    heading:data.heading,
    id: data.sightseeing_detail[0].id,
    area: data.sightseeing_detail[0].area,
    location: data.sightseeing_detail[0].location,
    name: data.sightseeing_detail[0].name,
    image: data.sightseeing_detail[0].image,
    description: data.sightseeing_detail[0].sightseeing_description,
    duration: (data.duration / 60).toFixed(1),
  };
  return result;
}
