import { PostDataType } from "data/types";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { BlogModel } from "models/BlogModel/BlogModel";
import { getBlogDetail } from "controller/blogController";
import { getDateFromTimeStamp } from "utils/getDateFromTimeStamp";
import CardBadge from "components/blog/CardBadge";
import * as DOMPurify from "dompurify";

const BlogSingle = () => {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState<BlogModel>({
    type: "blog",
    area: "",
    authorImage: "",
    authorName: "",
    contentOne: "",
    contentThree: "",
    contentTwo: "",
    date: "",
    description: "",
    heading: "",
    id: 0,
    imageOne: "",
    imageTree: "",
    imageTwo: "",
    readTime: 0,
    status: "",
    tagList: [],
  });

  const fetchDetails = useCallback(async (id: string) => {
    const res = await getBlogDetail(id);
    if (res[0] !== null) {
      setBlogDetails(res[0]);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchDetails(id);
    }
  }, [id]);

  const sanitizeHTML = (data: string) => {
    const pureHTML = JSON.parse(data);
    const sanitizedDOM = DOMPurify.sanitize(pureHTML);
    return <div dangerouslySetInnerHTML={{ __html: sanitizedDOM }} />;
  };

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          {/* <Badge href="##" color="purple" name="Traveler" /> */}
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {blogDetails.heading}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {blogDetails.description}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <div className="ml-3">
                <div className="flex items-center">
                  {blogDetails.authorName !== null && (
                    <p className="block font-semibold">
                      {blogDetails.authorName}
                    </p>
                  )}
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {getDateFromTimeStamp(blogDetails.date)}
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  {blogDetails.readTime !== null && (
                    <span className="text-neutral-700 dark:text-neutral-300">
                      {blogDetails.readTime} min read
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <div className="prose lg:prose-lg text-justify prose-p:my-0">
          {blogDetails.contentOne && sanitizeHTML(blogDetails.contentOne)}
        </div>

        {blogDetails.imageTwo !== null && (
          <img
            src={blogDetails.imageTwo}
            alt="nc blog"
            className="rounded-2xl w-full"
          />
        )}

        {blogDetails.contentTwo !== null && <p>{blogDetails.contentTwo}</p>}
        {blogDetails.imageTree !== null && (
          <img
            src={blogDetails.imageTree}
            alt="nc blog"
            className="rounded-2xl w-full"
          />
        )}
        {blogDetails.contentThree !== null && <p>{blogDetails.contentThree}</p>}
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Garden
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Jewelry
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Tools
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Way Find</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={blogDetails.imageOne}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {blogDetails.tagList !== null && (
          <div className="mt-4 flex flex-wrap max-w-screen-md mx-auto">
            {blogDetails.tagList.length > 0 &&
              blogDetails.tagList.map((data, index) => (
                <CardBadge
                  value={data}
                  index={index}
                  key={index}
                  fontsize={12}
                />
              ))}
          </div>
        )}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/* {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()} */}
      </div>
      {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
      
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
     
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BlogSingle;
