import {
  jobDetailsAPI,
  jobListAPI,
  sendJobApplicantDetailAPI,
} from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { HttpResponse, instanceOfHttpResponse } from "models/HttpStatusModel";
import { JobModel, createJobObj } from "models/JobModel";

export async function getJobList() {
  const res = await asyncHandler(jobListAPI, "GET");
  if (!instanceOfHttpResponse(res)) {
    const jobArr: JobModel[] = [];
    for (let data of res.data) {
      jobArr.push(createJobObj(data));
    }
    return jobArr;
  } else {
    return res;
  }
}

export async function getJobDetails(jobId: string) {
  const res = await asyncHandler(`${jobDetailsAPI}/${jobId}`, "GET");
  if (!instanceOfHttpResponse(res)) {
    return createJobObj(res.data);
  } else {
    return res;
  }
}

export async function sendApplicantDetails(
  jobId: string,
  applicantName: string,
  applicantEmail: string,
  applicantContactNumber: string,
  reasonToJoin: string,
  resumeFile: File
) {
  const formData = new FormData();
  formData.append("job", jobId);
  formData.append("applicant_name", applicantName);
  formData.append("applicant_email", applicantEmail);
  formData.append("applicant_contact", applicantContactNumber);
  reasonToJoin !== "" && formData.append("why_join", reasonToJoin);
  formData.append("resume", resumeFile);
  const res = await asyncHandler(sendJobApplicantDetailAPI, "POST", formData);
  if (!instanceOfHttpResponse(res)) {
    const result: HttpResponse = {
      type: "httpStatus",
      statusCode: res.status,
      error: null,
      success: "You application is submitted",
    };
    return result;
  } else {
    return res;
  }
}
