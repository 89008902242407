import neliImg from "images/discoverplaces/neil/Neil.jpg";

export default function NeilIsland() {
  return (
    <div>
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">Neil Island</h1>
        <p className="mt-6 text-sm sm:text-base text-justify">
          Neil Island, also known as Shaheed Dweep, is a picturesque island
          located in the Andaman and Nicobar Islands, India. It is situated
          approximately 40 kilometers (25 miles) east of Port Blair, the capital
          city of the Andaman and Nicobar Islands, and is easily accessible by
          ferry from Port Blair.
        </p>
        <p className="mt-2 text-sm sm:text-base text-justify">
          The island is known for its pristine beaches, crystal-clear waters,
          and lush green forests, making it a popular destination for
          eco-tourism and adventure sports. The island is relatively small, with
          a total area of approximately 18.9 square kilometers (7.3 square
          miles), and a population of around 3,000 people, most of whom are
          engaged in fishing and agriculture.
        </p>
      </div>
      <img
        src={neliImg}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">History</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            The island was named after James George Smith Neill, a British
            soldier who served in the Indian Army during the Indian Rebellion of
            1857. The island was initially inhabited by the indigenous tribes of
            the Andaman and Nicobar Islands, including the Nicobarese and
            Shompen tribes, who have lived on the island for thousands of years.
          </p>
          <p>
            In the early 20th century, the island was used as a penal colony by
            the British, with many political prisoners being sent to the island.
            During World War II, the island was occupied by the Japanese, who
            used it as a military base.
          </p>
          <p>
            After India gained independence in 1947, the island became a part of
            the Andaman and Nicobar Islands, and has since developed into a
            popular tourist destination.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Tourism</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Neil Island is known for its beautiful beaches, which offer a range
            of activities, including swimming, snorkeling, scuba diving, and sea
            walking. The island's beaches are relatively unspoiled, with clear
            blue waters and white sandy shores.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Laxmanpur Beach</h2>
        <p className="mt-2 text-justify text-sm sm:text-base">
          Laxmanpur Beach is a stunning and serene beach located on the western
          coast of Neil Island in the Andaman and Nicobar Islands, India. The
          beach is known for its picturesque sunsets, crystal-clear waters, and
          soft, white sand.
        </p>
        <p className="mt-2 text-justify text-sm sm:text-base">
          The beach is approximately 2 kilometers (1.2 miles) from Neil Island's
          main jetty and is easily accessible by foot, bicycle, or motorbike.
          Visitors can also hire a taxi or auto-rickshaw to reach the beach.
        </p>
        <h2 className="text-2xl font-semibold mt-14">Attractions:</h2>
        <div>
          <h3 className="text-lg font-semibold mt-8">Sunset Point</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              One of the main attractions of Laxmanpur Beach is the Sunset
              Point, where visitors can witness a spectacular sunset view over
              the Arabian Sea. The point is located at the southern end of the
              beach and is a popular spot for photography.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Beachcombing</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              Laxmanpur Beach is also an ideal spot for beachcombing, with
              plenty of shells and other marine artifacts to be found along the
              shoreline. Visitors can take a leisurely stroll along the beach
              and collect shells as souvenirs of their trip.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Birdwatching</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The beach is also a popular destination for birdwatchers, with a
              variety of bird species found in the surrounding forest and
              mangrove areas. Visitors can spot birds such as the Andaman
              Woodpecker, Andaman Drongo, and Andaman Serpent Eagle.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Bharatpur Beach</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The beach is also a popular destination for birdwatchers, with a
              variety of bird species found in the surrounding forest and
              mangrove areas. Visitors can spot birds such as the Andaman
              Woodpecker, Andaman Drongo, and Andaman Serpent Eagle.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Marine Life</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              Bharatpur Beach is known for its vibrant and diverse marine life,
              making it a popular destination for snorkeling and scuba diving.
              Visitors can witness a variety of marine creatures, including
              colorful fish, sea turtles, and corals. The beach is home to a
              large number of blue coral, a rare and endangered species of coral
              found only in the Andaman and Nicobar Islands.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Water Sports</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The beach offers a range of water sports activities, including jet
              skiing, banana boat rides, and glass-bottom boat rides. Visitors
              can also take a ride on a mechanized boat to explore the nearby
              mangrove forests.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Swimming</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              Bharatpur Beach is a safe and shallow beach, making it an ideal
              spot for swimming. The waters are calm and clear, and the beach
              has a gradual slope, making it perfect for children and
              non-swimmers. Sunbathing and Relaxation.
            </p>
            <p>
              The beach is also an ideal spot for sunbathing and relaxation,
              with plenty of space to stretch out on the soft, white sand.
              Visitors can also rent beach umbrellas and chairs to relax in the
              shade.
            </p>
          </div>
        </div>
        <h2 className="text-2xl font-semibold mt-14">Sitapur Beach:</h2>
        <div>
          <div className="mt-4 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The beach offers a range of water sports activities, including jet
              skiing, banana boat rides, and glass-bottom boat rides. Visitors
              can also take a ride on a mechanized boat to explore the nearby
              mangrove forests.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Scenic Beauty</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The beach offers a range of water sports activities, including jet
              skiing, banana boat rides, and glass-bottom boat rides. Visitors
              can also take a ride on a mechanized boat to explore the nearby
              mangrove forests.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Sunrise Views</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              Sitapur Beach is known for its spectacular sunrise views, making
              it a popular destination for early morning walks and photography.
              Visitors can watch the sun rise from behind the horizon, casting a
              warm and golden glow on the sea and sand
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Relaxation</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              Sitapur Beach is an ideal spot for relaxation, with plenty of
              shaded areas to sit and enjoy the views. Visitors can also rent
              beach chairs and umbrellas to relax in the shade. The beach is
              relatively secluded, offering a peaceful and tranquil atmosphere.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Local Culture</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              Sitapur Beach is surrounded by small villages, and visitors can
              witness the local way of life. The nearby villages are home to a
              vibrant and friendly community, and visitors can interact with the
              locals and learn about their culture and traditions.
            </p>
          </div>
        </div>
        <h2 className="text-2xl font-semibold mt-14">Natural Bridge:</h2>
        <div>
          <div className="mt-4 text-justify space-y-2 text-sm sm:text-base">
            <p>
              Natural Bridge, also known as the Howrah Bridge, is a unique
              geological formation located on the western coast of Neil Island
              in the Andaman and Nicobar Islands. The bridge is a natural rock
              formation created by years of erosion by the sea and wind, and is
              a popular tourist attraction. Here's a detailed guide to Natural
              Bridge:
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Formation</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The Natural Bridge is formed by the natural erosion of the
              limestone rocks over millions of years. The wind and waves have
              created a natural archway that spans across the sea, and the
              bridge appears as if it has been carved out of the rock. The
              bridge is a unique natural phenomenon and is considered one of the
              most picturesque spots on the island.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Scenic Beauty</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The Natural Bridge is surrounded by turquoise blue waters and is a
              great spot for photography. Visitors can take a walk along the
              rocky shore and witness the beauty of the natural archway. The
              beach is also a popular spot for sunsets, and visitors can enjoy
              the warm glow of the setting sun over the sea.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Local Culture</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The nearby villages offer a glimpse into the local way of life.
              Visitors can witness the traditional lifestyle of the local people
              and learn about their culture and traditions. The nearby villages
              also have several small shops selling souvenirs, handicrafts, and
              local produce.
            </p>
          </div>
          <h3 className="text-lg font-semibold mt-4">Accessibility</h3>
          <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
            <p>
              The Natural Bridge is located at a distance of about 5 km from the
              main jetty on Neil Island. Visitors can hire a bike or a car to
              reach the Natural Bridge. The road to the Natural Bridge is scenic
              and offers beautiful views of the island.
            </p>
          </div>
        </div>
        <h3 className="text-lg font-semibold mt-14">Conclusion</h3>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Overall, Neil Island is a must-visit destination for anyone
            traveling to the Andaman and Nicobar Islands. Its pristine beaches,
            crystal-clear waters, and lush green forests offer a unique and
            unforgettable experience, and its friendly and welcoming population
            make it a destination that visitors are sure to remember for years
          </p>
        </div>
      </div>
    </div>
  );
}
