export interface BlogModel {
  type: "blog";
  id: number;
  tagList: string[];
  heading: string;
  description: string;
  authorName: string;
  authorImage: string | null;
  readTime: number;
  date: string;
  area: string;
  status: string;
  contentOne: string;
  imageOne: string;
  contentTwo: string | null;
  imageTwo: string | null;
  contentThree: string | null;
  imageTree: string | null;
}

export function instanceOfBlogModel(data: any): data is BlogModel {
  return data.type === "blog";
}

export function createBlogObject(data: any): BlogModel {
  const result: BlogModel = {
    type: "blog",
    id: data.id,
    tagList: data.tag_list,
    heading: data.heading,
    description: data.description,
    authorName: data.author_name,
    authorImage: data.author_image,
    readTime: data.read_time,
    date: data.created_at,
    area: data.area,
    status: data.blog_status,
    contentOne: data.content_one,
    imageOne: data.image_one,
    contentTwo: data.content_two,
    imageTwo: data.image_two,
    contentThree: data.content_three,
    imageTree: data.image_three,
  };
  return result;
}
