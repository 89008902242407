import React , {useState} from 'react'

const FAQMain = ({elem}) => {
    const [show,setShow] = useState(false)
  return (
    
            <div>
                <div className='break-words not-italic font-semibold xl:text-lg md:text-lg hover:text-[#F19838] text-[#303A42] cursor-pointer'>{elem.question} <span onClick={() => setShow(!show)} className='ml-5'>{show? "—":"+"}</span></div>
                {show && <div className='not-italic font-normal text-base mt-5 text-[#303A42]'>{elem.answer}</div>}
                <hr className='xl:mt-5 md:mt-[3rem] mt-[5rem]'/>
              </div>
  )
}

export default FAQMain
