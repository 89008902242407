import { Modal } from "@mui/material";
import { message } from "antd";
import { cancelTrip } from "controller/TripPackageController";
import { TripPackageModel } from "models/TripPackageModel";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Textarea from "shared/Textarea/Textarea";
import { httpErrorAction } from "utils/controllerResponseHandler";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  tripPackage: TripPackageModel;
}

export default function CancelTripModal({
  showModal,
  setShowModal,
  tripPackage,
}: Props) {
  const navigate = useNavigate();
  const [cancelReason, setCancelReson] = useState("");

  async function handleCancel() {
    const res = await cancelTrip(tripPackage.id, cancelReason);

    if (res.statusCode === 200) {
      message.success(res.success);
      setShowModal(false);
    } else {
      httpErrorAction(res, navigate);
    }
  }
  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <div className="bg-white w-3/4 lg:w-2/4 h-2/4 absolute px-4 sm:px-10 lg:px-24 py-10 rounded-md left-2/4 top-2/4 transform -translate-x-2/4 -translate-y-2/4 overflow-y-auto">
        <h1 className=" font-semibold text-red-400 text-2xl">
          Request to Cancel
        </h1>
        <div className="mt-8">
          <label
            className="text-sm font-semibold text-gray-800"
            htmlFor="reason"
          >
            Describe your reason
          </label>
          <Textarea
            placeholder="Please describe your issue we are here to help you"
            className="mt-2"
            id="reason"
            value={cancelReason}
            onChange={(e) => setCancelReson(e.target.value)}
            rows={8}
          />
        </div>
        <div className="mt-8 flex justify-end">
          <Button className="border border-gray-200 mr-4">
            {" "}
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.364 4.95L11.314 0L12.728 1.414L7.778 6.364L12.728 11.314L11.314 12.728L6.364 7.778L1.414 12.728L0 11.314L4.95 6.364L0 1.414L1.414 0L6.364 4.95Z"
                fill="#AAAAAA"
              />
            </svg>{" "}
            <span className="ml-2" onClick={() => setShowModal(false)}>
              {" "}
              Cancel
            </span>
          </Button>

          <div>
            <ButtonPrimary onClick={handleCancel}>
              <span className="mr-2"> Send Request</span>{" "}
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.392127 7.04697C-0.129873 6.87297 -0.134873 6.59197 0.402127 6.41297L19.4891 0.0509739C20.0181 -0.125026 20.3211 0.170974 20.1731 0.688974L14.7191 19.775C14.5691 20.304 14.2641 20.322 14.0401 19.82L10.4461 11.732L16.4461 3.73197L8.44613 9.73197L0.392127 7.04697Z"
                  fill="white"
                />
              </svg>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </Modal>
  );
}
