import { Activities } from "core/Constants";

export interface RoadTravelModel {
  heading:string|null;
  type: Activities.TRAVEL_BY_CAR | Activities.TRAVEL_BY_BUS;
  id: number;
  carType: string;
  carName: string;
  noOfSeaters: string;
  acNon: string;
  luggageSpace: string;
  primaryMedKit: string;
  source: string;
  dest: string;
  date: string;
  pickupTime: string;
  description: string;
}

export function instanceOfRoadTravelModel(data: any): data is RoadTravelModel {
  return (
    data.type === Activities.TRAVEL_BY_CAR ||
    data.type === Activities.TRAVEL_BY_BUS
  );
}


/**
 * 
 * @param data:any response data from the server
 * @param activity Activity enum  
 * @returns This will return an object based on RoadTravelModel
 */

export function createRoadTravelObject(
  data: any,
  activity: Activities.TRAVEL_BY_CAR | Activities.TRAVEL_BY_BUS
): RoadTravelModel {
  const result: RoadTravelModel = {
    type: activity,
    id: data.id,
    heading:data.heading,
    carType: data.car_detail_json.car_type,
    carName: data.car_detail_json.car_name,
    noOfSeaters: data.car_detail_json.no_of_searters,
    acNon: data.car_detail_json.ac_non,
    luggageSpace: data.car_detail_json.luggage_space,
    primaryMedKit: data.car_detail_json.primary_medkit,
    source: data.car_detail_json.source,
    dest: data.car_detail_json.dest,
    date: data.car_detail_json.date,
    pickupTime: data.car_detail_json.pickup_time,
    description: data.description,
  };
  return result;
}
