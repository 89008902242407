import mountImg from "images/discoverplaces/mountharriet/mountharriet.jpg";
export default function MountHarriet() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">Mount Harriet</h1>
        <p className="mt-6 text-sm sm:text-base">
          Mount Harriet is one of the most popular tourist destinations in the
          Andaman Islands. Located on the eastern side of Port Blair, Mount
          Harriet is the highest peak in the South Andaman region, standing at
          an impressive height of 1,200 feet. The area is a designated national
          park and is known for its breathtaking views of the surrounding
          landscape.
        </p>
      </div>
      <img
        src={mountImg}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700 text-sm sm:text-base space-y-4">
        <p>
          The history of Mount Harriet dates back to the British colonial era.
          During that time, the area was used as a summer headquarters for the
          Chief Commissioner of the Andaman Islands. In 1943, the Japanese
          occupied the Andaman Islands, and Mount Harriet was used as a lookout
          point to keep an eye on the British forces.
        </p>
        <p>
          Today, Mount Harriet is a popular destination for hiking, trekking,
          and nature lovers. The national park is home to a diverse range of
          flora and fauna, including several endemic species found only in the
          Andaman Islands.
        </p>
        <p>
          The trek to the summit of Mount Harriet is a challenging but rewarding
          experience. The trail is well-maintained and takes visitors through
          lush forests, across streams, and up steep inclines. The trek is
          approximately 16 kilometres long and takes around 5-6 hours to
          complete. However, the effort is worth it, as the view from the summit
          is breath-taking. At the summit, visitors can enjoy panoramic views of
          the surrounding landscape, including the Andaman Sea, Ross Island, and
          the North Bay Island. The view is especially stunning during sunrise
          and sunset, when the sky is painted in shades of orange, pink, and
          purple.
        </p>
        <p>
          Apart from the trek to the summit, visitors can also explore the
          national park and its many attractions. The park is home to several
          hiking trails, ranging from easy to difficult. Visitors can also take
          a stroll along the nature trails, which are dotted with several
          viewpoints and offer a chance to spot some of the local wildlife. One
          of the highlights of visiting Mount Harriet is the chance to see some
          of the unique wildlife that inhabits the area. The park is home to
          several species of birds, including the Andaman woodpecker, the
          Andaman crake, and the Andaman treepie. Visitors can also spot several
          species of mammals, including wild pigs, deer, and monkeys.
        </p>
        <p>
          For those interested in history, the park also houses several
          colonial-era buildings, including the Mount Harriet Bungalow and the
          Forest Guest House. These buildings offer a glimpse into the colonial
          past of the Andaman Islands and provide a fascinating insight into the
          region's history.
        </p>
        <p>
          In conclusion, Mount Harriet is a must-visit destination for anyone
          traveling to the Andaman Islands. Its stunning natural beauty, diverse
          wildlife, and rich history make it a perfect place to explore and
          unwind. Visitors should be mindful of the fragile ecosystem of the
          national park and take steps to minimize their impact on the
          environment. With its panoramic views, challenging trekking trails,
          and unique wildlife, Mount Harriet is truly a hidden gem of the
          Andaman Islands.
        </p>
      </div>
    </div>
  );
}
