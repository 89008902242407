import { message } from "antd";
import { verifyForgotPasswordOtp } from "controller/forgotPasswordController";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { setIsOtpVerified, setOtp } from "store/states/forgotPasswordState";
import { httpErrorAction } from "utils/controllerResponseHandler";

export default function ForgotPasswordOtpVerify() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { email, otp } = useAppSelector(
    (state) => state.forgotPassword
  );
  const dispatch = useAppDispatch();
  async function otpVerificationHandler() {
    if (otp !== "") {
      setLoading(true);
      const res = await verifyForgotPasswordOtp(email, otp);
      setLoading(false);
      if (res.statusCode === 200) {
        message.success(res.success);
        dispatch(setIsOtpVerified(true));
        navigate("/forgot-password/reset-password");
      } else {
        httpErrorAction(res, navigate);
      }
    } else {
      message.error("Please provide an otp");
    }
  }


  useEffect(() => {
    if (email === "") {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <h1 className="text-center text-3xl font-semibold mt-20">Verify OTP</h1>
      <div className="max-w-md mx-auto mt-8">
        <div>
          <Input
            type="number"
            placeholder="Paste the OTP"
            value={otp}
            onChange={(e) => dispatch(setOtp(e.target.value))}
          />
        </div>
        <ButtonPrimary
          disabled={loading}
          className="mt-4 w-full"
          onClick={otpVerificationHandler}
        >
          Verify OTP
        </ButtonPrimary>
      </div>
      <div className="flex justify-center mb-20">
        <button
          className=" text-indigo-800 mt-4 "
          onClick={() => navigate(-1)}
        >
          &larr; Previous
        </button>
      
      </div>
    </div>
  );
}
