import { TaxonomyType } from "data/types";
import CardCategory61 from "components/CardCategory6/CardCategory61";
import PackageCard from "./PackageCard";
import TotalCard from "./TotalCard";
import DescriptionCard from "./DescriptionCard";
import OverviewCard from "./OverviewCard";
import BookTripActivity from "components/TripPackage/TripActivity/BookTripActivity";
import { useCallback, useEffect, useState } from "react";
import { TripPackageModel } from "models/TripPackageModel";
import { useParams } from "react-router-dom";
import { getTripPackageData } from "controller/TripPackageController";
import { message } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { useAppSelector } from "store/reduxHooks";

/*import RoommateCrad from "./RoommateCrad";
import HostCard from "./HostCard";
import ReviewCard from "./ReviewCard";
import ThingstoCard from "./ThingstoCard";*/

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail: "Car1.png",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail: "Car4.png",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail: "Car2.png",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail: "Car3.png",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail: "Car2.png",
  },
];

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function BookTripPage() {
  const { id } = useParams();
  const { isLogin } = useAppSelector((state) => state.login);
  const [tripPackageDetails, setTripPackageDetails] =
    useState<TripPackageModel>({
      activities: [],
      activity: "",
      area: "",
      description: "",
      duration: "",
      heading: "",
      hotelType: "",
      id: 0,
      images: [],
      itinerarySubType: "",
      itineraryType: "",
      manager: "",
      meal: "",
      numberOfActivities: 0,
      rating: "",
      type: "tripPackage",
      uniqueId: "",
      accommodationList: [],
      personCount: "",
      price: "",
      isBookMarked: false,
    });

  const fetchBookDetails = useCallback(async (id: string) => {
    if(isLogin !== null){
      const res = await getTripPackageData(
        id,
        isLogin !== null ? isLogin : false
      );
      if (res[0] !== null) {
        setTripPackageDetails(res[0]);
      } else {
        if (res[1] !== null) {
          message.warning(res[1].error);
        }
      }
    }
  }, [isLogin]);

  useEffect(() => {
    if (id) {
      fetchBookDetails(id);
    }
  }, [id, fetchBookDetails]);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION 1 */}
        <div className="relative py-16 ">
          {/* <div className="grid grid-cols-12 gap-6 mt-11">
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
              <CardCategory61 taxonomy={DEMO_CATS_2[0]} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
              <CardCategory61 taxonomy={DEMO_CATS_2[4]} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
              <CardCategory61 taxonomy={DEMO_CATS_2[3]} />
              <CardCategory61 taxonomy={DEMO_CATS_2[1]} />
            </div>
          </div> */}
          {tripPackageDetails.images.length > 0 && (
            <div>
              <Slider {...carouselSettings} arrows={true} autoplay={true}>
                {tripPackageDetails.images.map((data, index) => (
                  <img
                    key={index}
                    src={data}
                    className=" object-cover w-full h-[500px] rounded-lg"
                    alt={`tripImage-${index}`}
                    width="400"
                    height="1200"
                  />
                ))}
              </Slider>
            </div>
          )}
        </div>

        {/* Section 2 */}

        <div className="relative">
          <div className="">
            <div className="xl:flex xl:gap-[7rem]">
              <div>
                {tripPackageDetails.id > 0 &&  <PackageCard tripPackage={tripPackageDetails} />}
                 {tripPackageDetails.id > 0 && <DescriptionCard tripPackage={tripPackageDetails} />}
                 {tripPackageDetails.id > 0 && <OverviewCard tripPackage={tripPackageDetails} />}
              </div>
              {/*<RoommateCrad/>
                    <HostCard/>
                
                    <ReviewCard/>
                    <ThingstoCard/>*/}

              {tripPackageDetails.id > 0 &&  <TotalCard tripPackage={tripPackageDetails} />}
            </div>
            <div>
              {tripPackageDetails.id > 0 && <BookTripActivity activityList={tripPackageDetails.activities} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookTripPage;
