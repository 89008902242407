import React, { useEffect, useState } from "react";
import hiringImg from "../../images/career/hiring.png";
import peopleSvg from "../../images/career/people.svg";
import reloadSvg from "../../images/career/reload.svg";
import hatSvg from "../../images/career/hat.svg";
import barSvg from "../../images/career/bar.svg";
import { fakeJobs } from "./FakeJobData";
import JobCard from "./JobCard";
import { JobModel } from "models/JobModel";
import { getJobList } from "controller/careerController";
import { message } from "antd";

export default function Career() {
  const [jobList, setJobList] = useState<JobModel[]>([]);

  async function handleJobList() {
    const res = await getJobList();
    if (Array.isArray(res)) {
      setJobList(res);
    } else {
      message.error(res.error);
    }
  }

  useEffect(() => {
    handleJobList();
  }, []);

  return (
    <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
      <div className="grid grid-cols-6 mt-24">
        <div className="col-span-6 md:col-span-4">
          <h1 className="text-4xl font-semibold text-gray-800">Careers</h1>
          <p className=" w-full md:w-3/4 text-justify mt-2 text-sm">
            We offer opportunities for growth and development in the travel
            industry, and we are always looking for talented individuals who
            share our values of customer satisfaction, attention to detail, and
            excellent service. Browse our open positions and apply today to
            become part of our team and help us create unforgettable experiences
            for our customers.
          </p>
        </div>
        <div className="col-span-2 md:block hidden">
          <img src={hiringImg} height="350" width="300" alt="career" />
        </div>
      </div>
      <div className="mt-16 md:grid grid-cols-6">
        <div className="col-span-3">
          <div className="w-full md:w-3/4">
            <h4 className="text-sm font-semibold">BENIFITS</h4>
            <h3 className="font-semibold text-2xl">
              Why you Should Join Our Awesome Team
            </h3>
            <p className="mt-4 text-sm">
              Join our Team and Discover Your Career Path in the Exciting World
              of Travel.
            </p>
          </div>
        </div>
        <div className="col-span-3 md:mt-0 mt-12">
          <div className="grid gap-x-8 gap-y-12 grid-cols-2">
            <div>
              <div className=" p-4 shadow-md inline-block">
                <img src={peopleSvg} alt="team work" height="20" width="20" />
              </div>
              <h4 className="mt-4 text-sm font-semibold">Team work</h4>
              <p className="mt-2 text-xs">
                Stronger Together: Achieving Greatness Through Collaboration and
                Teamwork.
              </p>
            </div>
            <div>
              <div className=" p-4 shadow-md inline-block">
                <img
                  src={reloadSvg}
                  alt="secure future"
                  height="20"
                  width="20"
                />
              </div>
              <h4 className="mt-4 text-sm font-semibold">Secured Future</h4>
              <p className="mt-2 text-xs">
                Building a Secure Future: Explore Opportunities with Us for a
                Stable and Fulfilling Career.
              </p>
            </div>
            <div>
              <div className=" p-4 shadow-md inline-block">
                <img
                  src={hatSvg}
                  alt="Learning Opportunity"
                  height="20"
                  width="20"
                />
              </div>
              <h4 className="mt-4 text-sm font-semibold">
                Learning Opportunity
              </h4>
              <p className="mt-2 text-xs">
                Unlock Your Potential: Endless Learning Opportunities for Your
                Personal and Professional Growth.
              </p>
            </div>
            <div>
              <div className=" p-4 shadow-md inline-block">
                <img src={barSvg} alt="Upgrade skills" height="20" width="20" />
              </div>
              <h4 className="mt-4 text-sm font-semibold">Upgrade Skills</h4>
              <p className="mt-2 text-xs">
                Empowering Your Skills: Join Us to Enhance Your Talents and
                Create a Brighter Future.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-32">
        <div className="text-center">
          <h4 className=" font-semibold">COME JOIN US</h4>
          <h3 className="mt-2 font-bold text-3xl">Career Opening</h3>
          <p className="mt-4 text-sm">
            Your Gateway to a Rewarding Career: Explore Exciting Opportunities
            with Way Find Trips.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
        {jobList.length > 0 &&
          jobList.map((data) => <JobCard key={data.id} job={data} />)}
      </div>
    </div>
  );
}
