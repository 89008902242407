import React from 'react'
import PNR from "images/PNR.png"

const TicketPage = () => {
  return (
    <div className='flex flex-col justify-center items-center'>
        <img src={PNR} alt=""/>
        <div className='xl:w-[775px] xl:h-[50px] md:w-[615px] w-[260px] not-italic font-normal text-base text-[#6B7280] mt-11'>Find Your Journey's Status Anytime, Anywhere with PNR Search. You can easily check your trip details and ensure a hassle free journey with us </div>
        <form className='relative flex items-center'>
          <div className='md:w-[64px] md:h-[64px] xl:w-[64px] xl:h-[64px] w-[64px] h-[64px] border-1 bg-[#F19838] justify-center items-center rounded-full absolute xl:ml-[68rem] md:ml-[40rem] ml-[14.5rem] mt-11 cursor-pointer'>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mt-5 ml-5 text-white" 
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
            </svg>
          </div>
          <input type="number" placeholder='Enter You PNR Number' className='w-[305px] xl:w-[1200px] h-[65px] md:w-[750px] mt-11 p-11 shadow-xl rounded-full'/>
        </form>
        
    </div>
  )
}

export default TicketPage
