import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
// import ClearDataButton from "./ClearDataButton";
// import ButtonSubmit from "./ButtonSubmit";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import { PathName } from "routers/types";
import { useDispatch } from "react-redux";
import { setPeoples } from "store/states/enquiryState";
import { useAppSelector } from "store/reduxHooks";


export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: PathName;
  hasButtonSubmit?: boolean;
}

const GuestsInput1: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
}) => {

  const dispatch = useDispatch();
  const {peoples} = useAppSelector(state=>state.enquiry)

  function handleChange(type: "adult" | "infant" | "child", value: number) {
    if (type === "adult") {
      dispatch(
        setPeoples({
          ...peoples,
          adult: value,
        })
      );
    }

    if (type === "child") {
      dispatch(
        setPeoples({
          ...peoples,
          child: value,
        })
      );
    }

    if (type === "infant") {
      dispatch(
        setPeoples({
          ...peoples,
          infant: value,
        })
      );
    }
  }

  /*const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
    onChange && onChange(newValue);
  };*/

  /*const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;*/

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none cursor-pointer ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center ${fieldClassName} `}
              onClick={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400"></div>
              <div className="flex gap-5">
                <p>{peoples.adult} Adult</p>
                <p>{peoples.child} Child</p>
                <p>{peoples.infant} Infant</p>
                {/* <span className="block xl:text-lg font-medium">
                  {totalGuests || ""} Guests
                </span> */}
              </div>
            </Popover.Button>

            {/* <div className="relative">
              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                    setGuestChildrenInputValue(0);
                    setGuestInfantsInputValue(0);
                  }}
                />
              )}
            </div> */}

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && <div className="pr-2 xl:pr-4"></div>}
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={peoples.adult}
                onChange={(value) => handleChange("adult", value)}
                max={10}
                min={1}
                label="Adults"
                desc="Ages 13 or above"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={peoples.child}
                onChange={(value) => handleChange("child", value)}
                max={4}
                label="Children"
                desc="Ages 2–12"
              />

              <NcInputNumber
                className="w-full mt-6"
                defaultValue={peoples.infant}
                onChange={(value) => handleChange("infant", value)}
                max={4}
                label="Infants"
                desc="Ages 0–2"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput1;
