import { Activities } from "core/Constants";
import { ActivityListType } from "models/BookTripModel/ActivityModel";
import BookTripRoadTravel from "./BookTripRoadTravel";
import BookTripAccommodation from "./BookTripAccommodation";
import BookTripSightSeen from "./BookTripSightSeen";
import BookTripFerryTravel from "./BookTripFerryTravel";

export default function BookTripActivityListItem({
  activity,
}: {
  activity: ActivityListType;
}) {
  if (
    activity.type === Activities.TRAVEL_BY_CAR ||
    activity.type === Activities.TRAVEL_BY_BUS
  ) {
    return <BookTripRoadTravel roadTravel={activity} />;
  }
  if (activity.type === Activities.ACCOMMODATION) {
    return <BookTripAccommodation accommodation={activity} />;
  }
  if (activity.type === Activities.SIGHTSEEING) {
    return <BookTripSightSeen sightSeen={activity} />;
  }

  if(activity.type === Activities.TRAVEL_BY_FERRY){
    return <BookTripFerryTravel ferryTravel={activity} />
  }

  return <div>No activity</div>;
}
