import CommonLayout from "./CommonLayout";
import SavedPackages from "./SavedPackages";
import SuggestPackageList from "./SuggestedPackageList";

const AccountSavelists = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div>
            <h2 className="text-3xl font-semibold">Save lists</h2>
          </div>

          <SavedPackages />
        </div>
        <div className="space-y-6 sm:space-y-8 mt-24">
          <div>
            <h2 className="text-3xl font-semibold">Related Packages</h2>
          </div>

          <SuggestPackageList />
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountSavelists;
