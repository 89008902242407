import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import { sendApplicantDetails } from "controller/careerController";
import { message } from "antd";
import Loader from "components/Loader/Loader";
import giftSvg from "../../images/career/gift.svg";
import chairSvg from "../../images/career/chair.svg";

export default function ApplicantForm() {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [applicantName, setApplicantName] = useState("");
  const [applicantContactNumber, setAppicantContactNumber] = useState("");
  const [applicantEmail, setApplicantEmail] = useState("");
  const [joinReason, setJoinReason] = useState("");
  const [resume, setResume] = useState<File>();
  const [loading, setLoading] = useState(false);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (jobId && resume) {
      setLoading(true);
      const result = await sendApplicantDetails(
        jobId,
        applicantName,
        applicantEmail,
        applicantContactNumber,
        joinReason,
        resume
      );
      setLoading(false);
      if (result.statusCode === 200) {
        message.success(result.success);
      } else {
        message.error(result.error);
      }
    }
  }

  return (
    <div className="container relative  mb-24 space-y-16 lg:mb-28">
      <button
        className="px-6 py-2 rounded-md border text-sm border-gray-100 mt-12 text-white bg-gray-800"
        onClick={() => navigate(-1)}
      >
        <span className="mr-2">&larr;</span>
        back
      </button>
      <div className="flex items-center justify-between">
        <div className="mt-8">
          <img src={giftSvg} alt="gift" height="50" width="50" />
          <h1 className="font-semibold text-3xl mt-2">Travel For Travelers</h1>
          <p className="mt-2 text-sm">
            We promise you to give the memorable moments every day.
          </p>
          <p className="mt-2 text-sm">
            Fill the form or send your resume to this below mail id
            <a
              className=" underline text-blue-500 ml-2 cursor-pointer"
              href="mailto:careers@wayfindtrips.com"
            >
              careers@wayfindtrips.com
            </a>
          </p>
        </div>
        <img src={chairSvg} className="md:block hidden" alt="career" height={200} width={200}  />
      </div>
      <form className="mt-8" onSubmit={(e) => onSubmit(e)}>
        <div>
          <Label>Applicant name</Label>
          <Input
            value={applicantName}
            onChange={(e) => setApplicantName(e.target.value)}
            className="mt-2"
            required={true}
          />
        </div>
        <div className="mt-4">
          <Label>Applicant Contact number</Label>
          <Input
            value={applicantContactNumber}
            onChange={(e) => setAppicantContactNumber(e.target.value)}
            className="mt-2"
            required={true}
            type="number"
          />
        </div>
        <div className="mt-4">
          <Label>Application Email</Label>
          <Input
            className="mt-2"
            value={applicantEmail}
            onChange={(e) => setApplicantEmail(e.target.value)}
            required={true}
            type="email"
          />
        </div>
        <div className="mt-4">
          <Label>Describe Why you want to join us</Label>
          <Textarea
            className="mt-2"
            value={joinReason}
            onChange={(e) => setJoinReason(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <Label>Attach Resome (only PDF file accepted)</Label>
          <Input
            className="mt-2"
            type="file"
            onChange={(e) =>
              setResume(e.target.files !== null ? e.target.files[0] : undefined)
            }
            required={true}
          />
        </div>
        <div className="flex justify-end mt-8">
          <button
            type="submit"
            className=" bg-gray-800 py-2 px-6 text-sm text-white rounded-md flex items-center space-x-2"
            disabled={loading}
          >
            {loading && <Loader height="20px" width="20px" />}
            <span> Send details</span>
          </button>
        </div>
      </form>
    </div>
  );
}
