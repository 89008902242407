import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import React, { useState } from "react";
import scubaDivingImg from "images/activity/scuba.png";
import deepSeaDivingImg from "images/activity/deepSeaDiving.png";
import seaWalk from "images/activity/seaWalk.png";
import snorKelingImg from "images/activity/snorKeling.png";
import dolphinImg from "images/activity/dolphin.png";
import submarineImg from "images/activity/submarine.png";
import fishingImg from "images/activity/fishing.png";
import jetSkiImg from "images/activity/jetski.png";

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const DEMO_CATS: TaxonomyType[] = [
    {
      id: "1",
      href: "#",
      name: "Scuba Diving",
      taxonomy: "category",
      count: 4.5,
      thumbnail: scubaDivingImg,
    },
    {
      id: "2",
      href: "#",
      name: "Deep Sea Diving",
      taxonomy: "category",
      count: 1156,
      thumbnail: deepSeaDivingImg,
    },
    {
      id: "2",
      href: "#",
      name: "Sea Walk",
      taxonomy: "category",
      count: 4.5,
      thumbnail: seaWalk,
    },
    {
      id: "2",
      href: "#",
      name: "Snorkeling",
      taxonomy: "category",
      count: 1156,
      thumbnail: snorKelingImg,
    },
    {
      id: "2",
      href: "#",
      name: "Dolphin",
      taxonomy: "category",
      count: 4.5,
      thumbnail: dolphinImg,
    },
    {
      id: "2",
      href: "#",
      name: "SemiSubmarine",
      taxonomy: "category",
      count: 1156,
      thumbnail: submarineImg,
    },
    {
      id: "11",
      href: "#",
      name: "Game Fishing",
      taxonomy: "category",
      count: 4.5,
      thumbnail: fishingImg,
    },
    {
      id: "222",
      href: "#",
      name: "Jet Ski",
      taxonomy: "category",
      count: 1156,
      thumbnail: jetSkiImg,
    },
  ];

  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading
        desc="Discover great places near where you live"
        isCenter={headingCenter}
        className="m-0 text-neutral-900 dark:text-neutral-50 mb-4"
      >
        Experience the Best
      </Heading>
      <h4 className="text-gray-500 text-center">
        We can help you checkmark the multitude of activities to do in Andaman
      </h4>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8 mt-20`}>
        {DEMO_CATS.map((item, i) => (
          <CardComponentName key={i} taxonomy={item} />
        ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
