import { profileInfoAPI, profileInfoUpdateAPI } from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { instanceOfHttpResponse } from "models/HttpStatusModel";
import { ProfileInfo, createProfileInfoObj } from "models/ProfileInfoModel";

export async function fetchProfileInfo() {
  const res = await asyncHandler(profileInfoAPI, "GET", null, true);
  if (!instanceOfHttpResponse(res)) {
    const data = res.data.user.profile;
    const result: ProfileInfo = createProfileInfoObj(data);
    return result;
  } else {
    return res;
  }
}

export async function updateProfileInfo({
  image,
  about,
  email,
  name,
  phoneNumber,
}: { image: File | null } & ProfileInfo) {
  const formData = new FormData();
  image !== null && formData.append("image", image);
  name !== null && formData.append("name", name);
  email !== null && formData.append("email", email);
  phoneNumber !== null && formData.append("phone_no", phoneNumber);
  about !== null && formData.append("about_you", about);
  const res = await asyncHandler(profileInfoUpdateAPI, "PATCH", formData, true);
  if (!instanceOfHttpResponse(res)) {
    const obj = createProfileInfoObj(res.data);
    return obj;
  } else {
    return res;
  }
}
