import React, { useEffect, useState } from "react";
import TripPackageFilter from "./TripPackageFilter";
import TripPackages from "./TripPackages";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TripPackageType } from "core/Constants";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { stringToTripDayAndNight } from "utils/stringToTripDayandNight";

export default function TripPackageCollection() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [packageInfo, setPackageInfo] = useState<{packageType:TripPackageType;duration:string}>({
    packageType:TripPackageType.Empty,
    duration:""
  });
  useEffect(() => {
    const data = searchParams.get("package_type");
    const duration = searchParams.get("duration")
    if (data && duration) {
      setPackageInfo({packageType:data as TripPackageType,duration});
    }
  }, [searchParams]);
  return (
    <div>
      <div className="px-16">
        <div onClick={() => navigate(-1)} className="flex justify-center items-center w-[145px] h-[45px] border-2 drop-shadow-md rounded-xl cursor-pointer">
          <div><KeyboardBackspaceIcon /><span className="ml-2">Back</span></div>
        </div>
        <div className="font-semibold text-xl md:text-3xl text-[#1F2937] py-10">{stringToTripDayAndNight(packageInfo.duration).map((data,index)=><span key={index}>{data}{" "}</span>)} Packages</div>
      </div>
      <TripPackageFilter
        packageType={packageInfo}
        setPackageType={setPackageInfo}
      />
      <TripPackages packageInfo={packageInfo} />
    </div>
  );
}
