export interface ProfileInfo {
  name: string | null;
  email: string;
  phoneNumber: string | null;
  profileImageUrl: string | null;
  about: string | null;
  isVefified:boolean | null;
}

export function createProfileInfoObj(data: any) {
  const result: ProfileInfo = {
    name: data.name,
    phoneNumber: data.phone_no,
    profileImageUrl: data.image,
    about: data.about_you,
    email: data.email,
    isVefified:data.otp_verified
  };
  return result;
}
