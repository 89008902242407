import React, { useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import Review from "images/Review1.png";
import Review1 from "images/Review.png";
import Heading from "components/Heading/Heading";
import Topic from "images/Topic.png";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const ReviewPage = () => {
  const Package = ["Select options", "Tour Review"];
  const [name, setName] = useState("");
  const [headline, setHeadline] = useState("");
  const [review_topic, setReview_topic] = useState("");
  const [overall_rating, setOverall_rating] = useState(0);
  const [tripExp_rating, setTripExp_rating] = useState("");
  const [accomodation_rating, setAccomodation_rating] = useState("");
  const [foodqualtyRating, setFoodqualtyRating] = useState("");
  const [customer_service_rating, setCustomer_service_rating] = useState("");
  const [recommendation_rating, setRecommendation_rating] = useState("");
  const [nexttrip_probability, setNexttrip_probability] = useState("");
  const [review_description, setReview_description] = useState("");

  const data = {
    name: name,
    headline: headline,
    review_topic: review_topic,
    overall_rating: overall_rating,
    tripExp_rating: tripExp_rating,
    accomodation_rating: accomodation_rating,
    foodqualtyRating: foodqualtyRating,
    customer_service_rating: customer_service_rating,
    recommendation_rating: recommendation_rating,
    nexttrip_probability: nexttrip_probability,
    review_description: review_description,
  };

  const handleAPI = () => {
    axios
      .post("/wft_user/review-creation", data)
      .then((res) => {})
      .catch((err) => {
        alert("Server Error");
      });
  };

  return (
    <div>
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16 md:px-16">
          <div className="flex justify-center items-center gap-11">
            <div className="md:block hidden">
              <NcImage src={Review} alt="" />
            </div>
            <div className="flex flex-col">
              <Heading isCenter>📝 Write a Review</Heading>
              <div className="font-normal text-sm text-[#6B7280]">
                Share your experience and help others! Leave a review today and
                make a difference.
              </div>
            </div>
            <div className="md:block hidden">
              <NcImage src={Review1} alt="" />
            </div>
          </div>
          <div className="mt-11 font-medium text-xl">Your Name</div>
          <input
            onChange={(e) => setName(e.target.value)}
            type="text"
            name="name"
            placeholder="Enter your Name"
            className="mt-3"
          />
          <div className="mt-7 font-medium text-xl">Review Topic </div>
          <div className="flex mt-3">
            <NcImage src={Topic} alt="" />
            <select
              name="duration"
              className="ml-3"
              onChange={(e) => setReview_topic(e.target.value)}
            >
              {Package.map((state, index) => {
                return (
                  <option value={state} key={index}>
                    {state}
                  </option>
                );
              })}
            </select>
          </div>
          <hr className="mt-8" />
          <div className="mt-7 font-medium text-xl">Overall rating</div>
          <div className="mt-3">
            <Stack spacing={1}>
              <Rating
                name="overall_rating"
                precision={0.5}
                onChange={(e, value) =>
                  setOverall_rating(value !== null ? value : 0)
                }
              />
            </Stack>
          </div>
          <hr className="mt-8" />
          <div className="mt-7 font-medium text-xl">Add a headline</div>
          <input
            onChange={(e) => setHeadline(e.target.value)}
            type="text"
            name="headline"
            placeholder="What’s most important to know?"
            className="mt-3"
          />
          <hr className="mt-8" />
          <div className="relative">
            <div className="mt-7 font-medium text-xl">
              How was your overall experience on the trip?
            </div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="tripExp_rating"
                onChange={(e) => setTripExp_rating(e.target.value)}
              >
                <FormControlLabel
                  value="Excellent"
                  control={<Radio />}
                  label="Excellent"
                />
                <FormControlLabel
                  value="Good"
                  control={<Radio />}
                  label="Good"
                />
                <FormControlLabel
                  value="Average"
                  control={<Radio />}
                  label="Average"
                />
                <FormControlLabel
                  value="Poor"
                  control={<Radio />}
                  label="Poor"
                />
                <FormControlLabel
                  value="Terrible"
                  control={<Radio />}
                  label="Terrible"
                />
              </RadioGroup>
            </FormControl>
            <hr className="mt-8" />
            <div className="mt-7 font-medium text-xl">
              How would you rate the accommodations provided during your trip?
            </div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="accomodation_rating"
                onChange={(e) => setAccomodation_rating(e.target.value)}
              >
                <FormControlLabel
                  value="Excellent"
                  control={<Radio />}
                  label="Excellent"
                />
                <FormControlLabel
                  value="Good"
                  control={<Radio />}
                  label="Good"
                />
                <FormControlLabel
                  value="Average"
                  control={<Radio />}
                  label="Average"
                />
                <FormControlLabel
                  value="Poor"
                  control={<Radio />}
                  label="Poor"
                />
                <FormControlLabel
                  value="Terrible"
                  control={<Radio />}
                  label="Terrible"
                />
              </RadioGroup>
            </FormControl>
            <hr className="mt-8" />
            <div className="mt-7 font-medium text-xl">
              How would you rate the quality of the meals provided during your
              trip?
            </div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="foodqualtyRating"
                onChange={(e) => setFoodqualtyRating(e.target.value)}
              >
                <FormControlLabel
                  value="Excellent"
                  control={<Radio />}
                  label="Excellent"
                />
                <FormControlLabel
                  value="Good"
                  control={<Radio />}
                  label="Good"
                />
                <FormControlLabel
                  value="Average"
                  control={<Radio />}
                  label="Average"
                />
                <FormControlLabel
                  value="Poor"
                  control={<Radio />}
                  label="Poor"
                />
                <FormControlLabel
                  value="Terrible"
                  control={<Radio />}
                  label="Terrible"
                />
              </RadioGroup>
            </FormControl>
            <hr className="mt-8" />
            <div className="mt-7 font-medium text-xl">
              How would you rate the customer service provided by the tour
              operator?
            </div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="customer_service_rating"
                onChange={(e) => setCustomer_service_rating(e.target.value)}
              >
                <FormControlLabel
                  value="Excellent"
                  control={<Radio />}
                  label="Excellent"
                />
                <FormControlLabel
                  value="Good"
                  control={<Radio />}
                  label="Good"
                />
                <FormControlLabel
                  value="Average"
                  control={<Radio />}
                  label="Average"
                />
                <FormControlLabel
                  value="Poor"
                  control={<Radio />}
                  label="Poor"
                />
                <FormControlLabel
                  value="Terrible"
                  control={<Radio />}
                  label="Terrible"
                />
              </RadioGroup>
            </FormControl>
            <hr className="mt-8" />
            <div className="mt-7 font-medium text-xl">
              How likely are you to recommend this tour to a friend or
              colleague?
            </div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="recommendation_rating"
                onChange={(e) => setRecommendation_rating(e.target.value)}
              >
                <FormControlLabel
                  value="Very likely"
                  control={<Radio />}
                  label="Very likely"
                />
                <FormControlLabel
                  value="Likely"
                  control={<Radio />}
                  label="Likely"
                />
                <FormControlLabel
                  value="Neutral"
                  control={<Radio />}
                  label="Neutral"
                />
                <FormControlLabel
                  value="Unlikely"
                  control={<Radio />}
                  label="Unlikely"
                />
                <FormControlLabel
                  value="Very unlikely"
                  control={<Radio />}
                  label="Very unlikely"
                />
              </RadioGroup>
            </FormControl>
            <hr className="mt-8" />
            <div className="mt-7 font-medium text-xl">
              Would you consider booking another trip with this tour operator in
              the future?
            </div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="nexttrip_probability"
                onChange={(e) => setNexttrip_probability(e.target.value)}
              >
                <FormControlLabel
                  value="Yes, definitely"
                  control={<Radio />}
                  label="Yes, definitely"
                />
                <FormControlLabel
                  value="Maybe"
                  control={<Radio />}
                  label="Maybe"
                />
                <FormControlLabel
                  value="Unsure"
                  control={<Radio />}
                  label="Unsure"
                />
                <FormControlLabel
                  value="No, probably not"
                  control={<Radio />}
                  label="No, probably not"
                />
                <FormControlLabel
                  value="No, definitely not"
                  control={<Radio />}
                  label="No, definitely not"
                />
              </RadioGroup>
            </FormControl>
            <hr className="mt-8" />
          </div>
          <div className="mt-7 font-medium text-xl">Describe you review</div>
          <div className="font-normal text-xs text-[#565E63] mt-2">
            Attach a google photos link of your trip we save it as beautiful
            memory 🙌
          </div>
          <textarea
            onChange={(e) => setReview_description(e.target.value)}
            name="review_description"
            placeholder="What did you like or dislike? What did you benefited from this trip?"
            className="mt-5"
          />
          <div
            onClick={handleAPI}
            className="w-[210px] h-[50px] border-2 rounded-full bg-[#0E1B43] mt-5 justify-center items-center flex cursor-pointer "
          >
            <div className="text-white">Send Review</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPage;
