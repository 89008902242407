import React from 'react'
import ContactForm from './ContactForm'
import ContactDetail from './ContactDetail'

const ContactUsPage = () => {
  return (
    <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
      <div  >
        <ContactForm/>
      </div>

      <div className="relative py-16 px-16">
        <ContactDetail/>
      </div>

    </div>
  )
}

export default ContactUsPage
