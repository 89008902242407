import React from "react";
import NcImage from "shared/NcImage/NcImage";
import Detail from "images/Detail.png";

const ContactDetail = () => {
  return (
    <div>
      <div className="relative flex flex-col lg:flex-row lg:items-center">
        <NcImage src={Detail} alt="" />

        <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
          <div className="font-semibold text-3xl sm:text-4xl">
            🎀 Contact Details{" "}
          </div>
          <h5 className="font-normal text-xl mt-8">🗺 ADDRESS</h5>
          <p className="mt-2 text-[#6B7280]">
            WayFind Trips Private Ltd <br /> B-128, 1st Floor, Eastern Business
            District Neptune Magnet Mall, LBS Marg Bhandup (W) Mumbai: 400078
            <br />
            CIN: U63040MH2023PTC396754
            <br />
            GST: 27AADCW4503C1ZP
            <br />
            PAN: AADCW4503C
           
          </p>
          <h5 className="font-normal text-xl mt-4">💌 EMAIL</h5>
          <p className="mt-2 text-[#6B7280]">hello@wayfindtrips.com</p>
          <h5 className="font-normal text-xl mt-4">☎ PHONE</h5>
          <p className="mt-2 text-[#6B7280]">+91-7400013770</p>
        </div>
      </div>
    </div>
  );
};
export default ContactDetail;
