import React from "react";
import ranghatImg from "images/discoverplaces/ranghat/rangat.jpg";
export default function Ranghat() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">Rangat</h1>
        <p className="mt-6 text-sm sm:text-base">
          Rangat is a small town located on the middle of the east coast of the
          Andaman Islands in India. It is one of the largest towns in the region
          and is known for its beautiful beaches, scenic landscapes, and rich
          biodiversity. The town is a popular tourist destination in the Andaman
          Islands and is home to some of the most popular tourist attractions in
          the area.
        </p>
      </div>
      <img
        src={ranghatImg}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">History</h2>
        <p className="mt-2 text-sm sm:text-base">
          Rangat was once a small village that was largely dependent on
          agriculture and fishing for its livelihood. However, with the growth
          of tourism in the Andaman Islands, Rangat has emerged as a popular
          tourist destination. The town is named after a British administrator
          named Daniel Ross, who discovered the area during his explorations of
          the Andaman Islands in the 19th century.
        </p>
        <h2 className="text-xl font-semibold mt-14">Location</h2>
        <p className="mt-2 text-sm sm:text-base">
          Rangat is located on the middle of the east coast of the Andaman
          Islands, about 170 km by road from the capital city of Port Blair. It
          is situated between the Bay of Bengal and the Andaman Sea. The town is
          surrounded by beautiful forests, rolling hills, and pristine beaches.
        </p>
        <h2 className="text-xl font-semibold mt-14">Climate</h2>
        <p className="mt-2 text-sm sm:text-base">
          The climate in Rangat is tropical and humid, with temperatures ranging
          from 20°C to 32°C. The best time to visit Rangat is between November
          and May, when the weather is dry and the skies are clear.
        </p>
        <h2 className="text-xl font-semibold mt-14">Tourism</h2>
        <div className="mt-2 text-sm sm:text-base space-y-4">
          <p>
            Rangat is a popular tourist destination in the Andaman Islands and
            is home to a number of beautiful beaches, waterfalls, and wildlife
            sanctuaries. Some of the most popular tourist attractions in the
            town include:
          </p>
          <ol className="space-y-2 list-decimal">
            <li>
              <span className="font-semibold">Amkunj Beach - </span> Amkunj
              Beach is a beautiful beach located near Rangat. The beach is
              famous for its golden sand and clear waters, and is a great place
              to relax and enjoy the beauty of the Andaman Islands.
            </li>
            <li>
              <span className="font-semibold">
                Dhani Nallah Mangrove Nature Walkway -{" "}
              </span>{" "}
              The Dhani Nallah Mangrove Nature Walkway is a popular tourist
              attraction located near Rangat. Visitors can take a walk through
              the mangrove forests and see the beautiful flora and fauna that
              inhabit the area.
            </li>
            <li>
              <span className="font-semibold">Panchvati Hills - </span>{" "}
              Panchvati Hills is a scenic hill located near Rangat. The hill is
              famous for its beautiful views of the surrounding forests and
              hills, and is a great place to take a trek.
            </li>
            <li>
              <span className="font-semibold">Yerrata Mangrove Park - </span>
              Yerrata Mangrove Park is a protected area located near Rangat. The
              park is home to a number of endemic species of flora and fauna,
              including the Andaman wild pig, the Andaman hill myna, and the
              Andaman imperial pigeon. Visitors can take a trek through the park
              to explore the beautiful forests and wildlife.
            </li>
            <li>
              <span className="font-semibold">Cuthbert Bay Beach - </span>
              Cuthbert Bay Beach is a popular beach located near Rangat. The
              beach is famous for its crystal-clear waters and beautiful coral
              reefs, which are home to a wide variety of marine life.
            </li>
            <li>
              <span className="font-semibold">Moricedera Beach - </span>{" "}
              Moricedera Beach is another popular beach located near Rangat. The
              beach is famous for its golden sand and clear waters, and is a
              great place to relax and soak up the sun.
            </li>
          </ol>
          <p>
            In addition to these tourist attractions, Rangat is also famous for
            its seafood and is a great place to try fresh seafood dishes.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Transportation</h2>
        <p className="mt-2 text-sm sm:text-base">
          The easiest way to reach Rangat is by road from Port Blair. The
          journey takes about 6-7 hours and passes through beautiful forests and
          rolling hills.
        </p>
      </div>
    </div>
  );
}
