import TripPackageCollection from 'components/TripPackage/TripPackageCollection'
import React from 'react'

const PackageCollection = () => {
  return (
    <div>
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
      <div className="relative py-16 ">
          {/* <SectionGridFeatureProperty1 /> */}
          <TripPackageCollection/>
        </div>
      </div>
    </div>
  )
}

export default PackageCollection
