import React, { useCallback, useEffect, useState } from "react";
import { getAllPackages } from "../../controller/TripPackageController";
import { message } from "antd";
import TripPackageCard from "./TripPackageCard";
import { TripPackageModel } from "models/TripPackageModel";
import { TripPackageType } from "core/Constants";
import { useAppSelector } from "store/reduxHooks";

interface TripPackageProps {
  packageType: TripPackageType;
  duration?: string;
}

export default function TripPackages({
  packageInfo,
}: {
  packageInfo: TripPackageProps;
}) {
  const [packageList, setPackageList] = useState<TripPackageModel[]>([]);
  const { isLogin } = useAppSelector((state) => state.login);

  const fetchPackages = useCallback(async () => {
    if (packageInfo.packageType !== TripPackageType.Empty) {
      const res = await getAllPackages(
        packageInfo.packageType,
        packageInfo.duration && packageInfo.duration,
        isLogin !== null ? isLogin : false
      );
      if (res[0] !== null) {
        setPackageList(res[0]);
      } else {
        if (res[1] !== null) {
          message.warning(res[1].error);
        }
      }
    }
  }, [packageInfo]);

  useEffect(() => {
    if (isLogin !== null) {
      fetchPackages();
    }
  }, [fetchPackages, isLogin]);

  return (
    <div>
      <div className=" mt-16 grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2">
        {packageList.length > 0 &&
          packageList.map((data) => (
            <TripPackageCard  data={data} key={data.id} />
          ))}
      </div>
    </div>
  );
}
