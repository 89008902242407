import React  from "react";
import BookTripDayActivity from "./BookTripDayActivity";
import { ActivityModel } from "models/BookTripModel/ActivityModel";


export default function BookTripActivity({activityList}:{activityList:ActivityModel[]}) {
  return (
    <div className="mt-11">
      <h3 className=" font-semibold font-gray-800 text-xl">
        Day Wise Schedule
      </h3>
      <div>
        {activityList.length > 0 &&
          activityList.map((data, index) => (
            <div key={index} className="mt-12">
              <BookTripDayActivity activity={data} />
            </div>
          ))}
      </div>
    </div>
  );
}
