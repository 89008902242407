import havelockImg from "images/discoverplaces/havelock/Havelock.jpg";
export default function HovelockIsland() {
  return (
    <div>
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">Havelock Island</h1>
        <p className="mt-6 text-sm sm:text-base text-justify">
          Havelock Island is a tropical paradise located in the Andaman and
          Nicobar Islands, an archipelago in the Bay of Bengal, India. With its
          turquoise waters, pristine beaches, lush forests, and diverse marine
          life, the island is a popular destination for tourists from all over
          the world.
        </p>
      </div>
      <img
        src={havelockImg}
        className="w-full object-fill h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">History and Culture</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Havelock Island was named after Sir Henry Havelock, a British
            general who served in India during the 19th century. The island was
            originally inhabited by indigenous tribes, including the Onge,
            Jarawa, and Sentinelese. The tribes have lived in isolation for
            thousands of years, and their culture and way of life have remained
            largely unchanged.
          </p>
          <p>
            The British colonized the Andaman and Nicobar Islands in the 18th
            century and used them as a penal colony. The notorious Cellular Jail
            in Port Blair, the capital of the Andaman and Nicobar Islands, is a
            reminder of this dark chapter in the islands' history. Thousands of
            political prisoners were incarcerated in the jail, and many died due
            to the harsh conditions.
          </p>
          <p>
            The island's culture is a blend of the indigenous tribes' traditions
            and the influences of the various settlers who have lived on the
            island over the centuries. The island's cuisine, for example,
            includes Indian, British, and Southeast Asian influences.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Geography</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Havelock Island is the largest of the Andaman Islands and is located
            approximately 39 kilometers (24 miles) northeast of Port Blair. The
            island has a total area of 113.93 square kilometers (43.99 square
            miles) and is home to around 6,500 people.
          </p>
          <p>
            The island's topography is diverse, with a combination of hills,
            forests, and beaches. The island's highest point is Mount Harriet,
            which stands at 383 meters (1,257 feet) above sea level. The
            island's forests are home to a range of flora and fauna, including
            various species of birds, reptiles, and mammals.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Tourism</h2>
        <p className="mt-2 text-justify text-sm sm:text-base">
          Havelock Island's stunning beaches and crystal clear waters are the
          primary draws for tourists. The island has several beautiful beaches,
          including Radhanagar Beach, Vijaynagar Beach, Kalapather Beach and
          Elephant Beach.
        </p>
        <h2 className="text-xl font-semibold mt-14">Radhanagar Beach</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Radhanagar Beach, also known as Beach No. 7, is one of the most
            beautiful beaches in Asia and has been consistently ranked among the
            world's top beaches by various travel publications. The beach
            features soft white sand, turquoise waters, and spectacular sunsets.
            Vijaynagar Beach is a quieter alternative to Radhanagar Beach and is
            perfect for visitors who want to escape the crowds. The beach is
            surrounded by lush green forests and is an excellent spot for
            swimming and sunbathing.
          </p>
          <p>
            Radhanagar Beach, also known as Beach No. 7, is one of the most
            popular and beautiful beaches on Havelock Island in the Andaman and
            Nicobar Islands. The beach is located on the western coast of the
            island and is approximately 12 kilometers (7.5 miles) from the
            island's main town of Vijaynagar.
          </p>
          <p>
            Radhanagar Beach is a crescent-shaped beach with soft white sand and
            crystal clear blue waters. The beach is surrounded by lush green
            forests and palm trees, which provide shade and create a serene
            atmosphere. The beach is also known for its spectacular sunsets,
            which attract visitors from all over the world.
          </p>
          <p>
            The beach is around 2 kilometers (1.2 miles) long and has plenty of
            space for visitors to relax and soak up the sun. The water at
            Radhanagar Beach is generally calm and safe for swimming, although
            visitors should always exercise caution and follow any warning signs
            or instructions from the lifeguards on duty.
          </p>
          <p>
            Radhanagar Beach is a popular destination for both domestic and
            international tourists and is often crowded during peak season
            (November to April). Visitors should plan to arrive early in the
            morning or later in the afternoon to avoid the crowds.
          </p>
          <p>
            There are several amenities available at Radhanagar Beach, including
            changing rooms, showers, and toilets. Visitors can also find food
            stalls and small shops selling souvenirs and beach gear. Overall,
            Radhanagar Beach is a must-visit destination for anyone traveling to
            Havelock Island. Its stunning natural beauty, tranquil atmosphere,
            and range of activities make it one of the most popular beaches in
            the Andaman and Nicobar Islands.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Kalapather Beach</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Kalapather Beach is a stunning beach located on the eastern coast of
            Havelock Island, in the Andaman and Nicobar Islands of India. The
            beach is known for its scenic beauty, pristine waters, and serene
            environment. Kalapather Beach is situated approximately 6 kilometers
            (3.7 miles) from Havelock Island's main town of Vijaynagar and can
            be easily accessed by road.
          </p>
          <p>
            The beach gets its name from the black rocks (kala pathar) that line
            the shoreline. The rocks are volcanic in origin and are believed to
            be millions of years old. The contrast between the black rocks and
            the white sand beach creates a unique and picturesque landscape.
          </p>
          <p>
            Kalapather Beach is a relatively small beach compared to other
            beaches on Havelock Island, with a length of approximately 2
            kilometers (1.2 miles). However, the beach is relatively secluded
            and quiet, making it an ideal spot for those seeking some peace and
            tranquility away from the crowds. The water at Kalapather Beach is
            generally calm, although visitors should exercise caution when
            swimming as there can be strong currents at times. The beach is not
            ideal for snorkeling or diving due to the rocky seabed and low
            visibility.
          </p>
          <p>
            Visitors to Kalapather Beach can enjoy a range of activities,
            including sunbathing, beachcombing, and taking long walks along the
            shoreline. The beach is also a popular spot for bird watching, with
            a variety of bird species found in the surrounding forests and
            mangroves. There are limited amenities available at Kalapather
            Beach, so visitors are advised to bring their own food and drinks.
            However, there are a few small shops and restaurants nearby where
            visitors can buy snacks and refreshments.
          </p>
          <p>
            Overall, Kalapather Beach is a must-visit destination for nature
            lovers and those seeking a peaceful and secluded beach experience on
            Havelock Island. Its unique landscape, tranquil atmosphere, and
            range of activities make it a popular spot for tourists and locals
            alike.
          </p>
        </div>
        <h2 className="text-xl font-semibold mt-14">Elephant Beach</h2>
        <div className="mt-2 text-justify space-y-2 text-sm sm:text-base">
          <p>
            Elephant Beach is a beautiful beach located on the north-western
            coast of Havelock Island in the Andaman and Nicobar Islands. The
            beach is known for its clear blue waters, white sand, and rich
            marine life, making it a popular spot for snorkeling and scuba
            diving.
          </p>
          <p>
            Elephant Beach is situated approximately 10 kilometers (6.2 miles)
            from the island's main town of Vijaynagar and can be reached by
            speedboat from Havelock Island's main dock. The beach is also
            accessible by foot through a dense forest trail, which takes
            approximately 30-40 minutes to traverse.
          </p>
          <p>
            The beach gets its name from the wild elephants that once roamed the
            area. Today, the elephants have moved deeper into the forest, but
            the beach retains its name. Elephant Beach is relatively small
            compared to other beaches on Havelock Island, with a length of
            approximately 2 kilometers (1.2 miles).
          </p>
          <p>
            The water at Elephant Beach is calm and safe for swimming, although
            visitors should exercise caution and follow any instructions from
            the lifeguards on duty. The beach is particularly popular among
            snorkelers and scuba divers due to its rich marine life, which
            includes a variety of fish, coral reefs, and sea turtles. In
            addition to snorkeling, visitors to Elephant Beach can also enjoy
            other water sports such as jet skiing and kayaking. The beach is
            also an ideal spot for sunbathing, beachcombing, and picnicking.
            There are several amenities available at Elephant Beach, including
            changing rooms, showers, and toilets. Visitors can also find food
            stalls and small shops selling souvenirs and beach gear.
          </p>
          <p>
            Overall, Elephant Beach is a must-visit destination for anyone
            traveling to Havelock Island, particularly those interested in
            snorkeling and scuba diving. Its pristine waters, rich marine life,
            and range of activities make it one of the most popular beaches in
            the Andaman and Nicobar Islands.
          </p>
          <p>
            Havelock Island is an excellent destination for adventure lovers,
            with a range of activities available, including scuba diving,
            snorkeling, sea-walking, kayaking, and jet skiing. The island's
            diverse marine life, including colorful coral reefs and various
            species of fish, make it a perfect spot for underwater exploration.
          </p>
        </div>
        <p className=" font-semibold mt-14">
          Here are some fun facts about Havelock Island in the Andaman and
          Nicobar Islands:
        </p>
        <ol className="list-decimal space-y-2 mt-2 text-justify">
          <li>
            Havelock Island was named after a British general, Sir Henry
            Havelock, who served during the Indian Rebellion of 1857.
          </li>
          <li>
            The island is home to some of the best beaches in the world,
            including Radhanagar Beach, which was rated the best beach in Asia
            by Time magazine in 2004.
          </li>
          <li>
            The Andaman and Nicobar Islands, including Havelock Island, are home
            to some of the world's most biodiverse coral reefs, with over 500
            species of fish and 200 species of coral.
          </li>
          <li>
            Havelock Island is a popular destination for eco-tourism and
            adventure sports, with opportunities for scuba diving, snorkeling,
            sea walking, and trekking.
          </li>
          <li>
            The island is home to several indigenous tribes, including the Onge,
            Jarwa, and Sentinelese, who have lived in the region for thousands
            of years.
          </li>
          <li>
            Havelock Island is also home to a thriving fishing industry, with
            many locals engaged in traditional fishing methods using boats and
            nets.
          </li>
          <li>
            The island has a rich history, with evidence of human habitation
            dating back to the Neolithic period.
          </li>
          <li>
            Havelock Island was one of the worst-hit areas during the 2004
            Indian Ocean earthquake and tsunami, with significant damage to the
            island's infrastructure and tourism industry. However, the island
            has since made a remarkable recovery and is now a popular
            destination for travelers from around the world.
          </li>
          <li>
            The island is located approximately 2,000 kilometers (1,200 miles)
            from the Indian mainland and is accessible by ferry or seaplane from
            Port Blair, the capital city of the Andaman and Nicobar Islands.
          </li>
          <li>
            Havelock Island is a popular destination for honeymooners, with its
            romantic beaches, clear blue waters, and stunning sunsets providing
            the perfect backdrop for a romantic getaway.
          </li>
        </ol>
      </div>
    </div>
  );
}
