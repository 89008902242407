import longIslandImg from "images/discoverplaces/longisland/longisland.jpg";
export default function LongIsland() {
  return (
    <div className="text-justify">
      <div className="w-3/4 mx-auto text-gray-700">
        <h1 className=" text-2xl sm:text-4xl font-semibold">Long Island</h1>
        <p className="mt-6 text-sm sm:text-base">
          Long Island is a picturesque island located in the Andaman Islands of
          India. It is known for its pristine beaches, crystal clear waters, and
          lush green forests. Long Island is a popular tourist destination in
          the Andaman Islands and is home to a number of unique and interesting
          tourist attractions.
        </p>
      </div>
      <img
        src={longIslandImg}
        className="w-full object-fill  h-96 md:h-[800px] rounded-md my-14"
        alt="island"
        width="1080"
        height="800"
      />
      <div className="w-3/4 mx-auto text-gray-700">
        <h2 className="text-xl font-semibold">History</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base ">
          Long Island was once inhabited by the Onge tribe, one of the
          indigenous tribes of the Andaman Islands. The island was later
          colonized by the British, who used it as a base for their operations
          in the Andaman Islands. Today, Long Island is a popular tourist
          destination and is known for its natural beauty and rich cultural
          heritage.
        </p>
        <h2 className="text-xl font-semibold mt-14">Location</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base ">
          Long Island is located in the Andaman Islands of India, about 80 km
          from the capital city of Port Blair. The island is situated in the
          middle of the Andaman Sea and is surrounded by crystal clear waters
          and coral reefs.
        </p>
        <h2 className="text-xl font-semibold mt-14">Climate</h2>
        <p className="mt-2 text-justify  text-sm sm:text-base ">
          The climate in Long Island is tropical, with temperatures ranging from
          20 to 30 degrees Celsius throughout the year. The monsoon season lasts
          from May to August, and the best time to visit the island is between
          November and May when the weather is pleasant.
        </p>
        <h2 className="text-xl font-semibold mt-14">Tourism</h2>
        <div className="mt-2 text-justify  text-sm sm:text-base space-y-4">
          <p>
            Long Island is a popular tourist destination in the Andaman Islands
            and is home to a number of unique and interesting tourist
            attractions. Some of the most popular tourist attractions in the
            island include:
          </p>
          <ol className="space-y-2 list-decimal">
            <li>
              <span className="font-semibold">Lamiya Bay Beach - </span>Lamiya
              Bay Beach is a beautiful beach located on the western side of Long
              Island. The beach is famous for its crystal clear waters and white
              sand, and is a great place to relax and enjoy the beauty of the
              Andaman Islands.
            </li>
            <li>
              <span className="font-semibold">Merk Bay Beach - </span>Merk Bay
              Beach is another popular beach located on the western side of Long
              Island. The beach is famous for its turquoise waters and is a
              great place for swimming and snorkeling.
            </li>
            <li>
              <span className="font-semibold">Lalaji Bay Beach - </span>Lalaji
              Bay Beach is a secluded beach located on the eastern side of Long
              Island. The beach is known for its tranquil surroundings and is a
              great place to relax and unwind.
            </li>
            <li>
              <span className="font-semibold">Long Island Forest - </span>The
              Long Island Forest is a lush green forest located on the island.
              The forest is home to a number of endemic species of flora and
              fauna, including the Andaman wild pig, the Andaman hill myna, and
              the Andaman imperial pigeon. Visitors can take a trek through the
              forest to explore the beautiful flora and fauna that inhabit the
              area.
            </li>
            <li>
              <span className="font-semibold">Guitar Island - </span>Guitar
              Island is a small island located off the coast of Long Island. The
              island is shaped like a guitar and is famous for its beautiful
              beaches and coral reefs.
            </li>
            <li>
              <span className="font-semibold">
                The Long Island Lighthouse -{" "}
              </span>
              This is a famous lighthouse located on the southern side of Long
              Island. The lighthouse offers a panoramic view of the surrounding
              forests and sea. Visitors can climb up the lighthouse and enjoy
              the breathtaking view. This is a famous lighthouse located on the
              southern side of Long Island. The lighthouse offers a panoramic
              view of the surrounding forests and sea. Visitors can climb up the
              lighthouse and enjoy the breathtaking view.
            </li>
          </ol>
          <p>
            Apart from these tourist attractions,{" "}
            <span className="font-semibold"> Long Island </span> is also famous
            for its eco-tourism initiatives. The island has several
            community-based tourism projects that aim to promote sustainable
            tourism and benefit the local community.
          </p>
          <p>
            Long Island is connected to other parts of the Rangat Jetty through
            sea. Regular ferries and boats ply between Long Island and Rangat.
            There are also a few private boat operators who offer services to
            Long Island.
          </p>
          <p>
            In conclusion, Long Island is a beautiful and serene island in the
            Andaman Islands that offers a unique blend of natural beauty,
            adventure activities, and eco-tourism initiatives. It is a
            must-visit destination for anyone who wants to explore the pristine
            beauty of the Andaman Islands.
          </p>
        </div>
      </div>
    </div>
  );
}
