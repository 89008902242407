import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TripPackageModel } from "models/TripPackageModel";

interface InitialState {
  list: TripPackageModel[];
}

const initialState: InitialState = {
  list: [],
};

const relatedPackageSlice = createSlice({
  name: "relatedPackages",
  initialState,
  reducers: {
    setRelatedPackageList: (
      state,
      action: PayloadAction<TripPackageModel[]>
    ) => {
      state.list = action.payload;
    },
  },
});

export default relatedPackageSlice.reducer;
export const { setRelatedPackageList } = relatedPackageSlice.actions;
