import { TripPackageModel } from 'models/TripPackageModel'

const DescriptionCard = ({tripPackage}:{tripPackage:TripPackageModel}) => {
  
  return (
    <div>
      <div className="border-2 border-[#E5E7EB] rounded-2xl bg-[#FFFFFF] mt-11">
        <div className='m-8 font-semibold text-2xl text-[#111827]'>Description</div>
        <hr className='w-[64px] ml-8'/>
        <div className='m-8 font-normal text-base text-[#4B5563]'>{tripPackage.description}</div>
      </div>
    </div>
  )
}

export default DescriptionCard
