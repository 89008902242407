import { message } from "antd";
import { sendForgotPasswordOtp } from "controller/forgotPasswordController";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { setEmail } from "store/states/forgotPasswordState";
import { httpErrorAction } from "utils/controllerResponseHandler";

export default function ForgotPasswordOtpSend() {
  const { email } = useAppSelector((state) => state.forgotPassword);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { isLogin } = useAppSelector((state) => state.login);

  async function handleSendOtp() {
    if (email !== "") {
      setLoading(true);
      const res = await sendForgotPasswordOtp(email);
      setLoading(false);
      if (res.statusCode === 200) {
        message.success(res.success);
      } else {
        httpErrorAction(res, navigate);
      }
    } else {
      message.error("Please provide an email");
    }
  }

  useEffect(() => {
    if (isLogin) {
      navigate(-1);
    }
  }, [isLogin]);

  return (
    <div className="container mb-24 lg:mb-32">
      <h1 className="text-center text-3xl font-semibold mt-20">
        Forgot password
      </h1>
      <div className="max-w-md mx-auto mt-8">
        <div>
          <label htmlFor="Email">Email</label>
          <Input
            type="Email"
            placeholder="example@exaple.com"
            value={email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
          />
        </div>
        <ButtonPrimary
          disabled={loading}
          onClick={handleSendOtp}
          className="mt-4 w-full"
        >
          Send OTP
        </ButtonPrimary>
      </div>
      <div className="flex justify-center">
        <button
          className=" text-indigo-800 mt-4"
          onClick={() =>
            email !== ""
              ? navigate("/forgot-password/verify-otp")
              : message.error("Please provide an email")
          }
        >
          Next &rarr;
        </button>
      </div>
    </div>
  );
}
