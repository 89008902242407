import {
  emailVerificationAPI,
  resendEmailVerificationOtpAPI,
  signInAPI,
  signUpAPI,
} from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { HttpResponse, instanceOfHttpResponse } from "models/HttpStatusModel";
import { createProfileInfoObj } from "models/ProfileInfoModel";
import { storeToken } from "utils/tokenhandler";

export async function signUp(email: string, password: string) {
  const res = await asyncHandler(signUpAPI, "POST", { email, password });
  if (!instanceOfHttpResponse(res)) {
    storeToken(res.data.token);
    const result: HttpResponse = {
      type: "httpStatus",
      statusCode: res.status,
      error: null,
      success: "Signup success",
    };
    return result;
  } else {
    return res;
  }
}

export async function verifyEmail(email: string, otp: string) {
  const res = await asyncHandler(emailVerificationAPI, "POST", { email, otp });
  if (!instanceOfHttpResponse(res)) {
    storeToken(res.data.user.token);
    return createProfileInfoObj(res.data.user.profile);
  } else {
    return res;
  }
}

export async function resendEmailVerificationOtp(email: string) {
  const res = await asyncHandler(resendEmailVerificationOtpAPI, "POST", {
    email,
  });
  if (!instanceOfHttpResponse(res)) {
    const result: HttpResponse = {
      type: "httpStatus",
      statusCode: res.status,
      error: null,
      success: res.data.message,
    };
    return result;
  } else {
    return res;
  }
}

export async function signIn(email: string, password: string) {
  const res = await asyncHandler(signInAPI, "POST", { email, password });
  if (!instanceOfHttpResponse(res)) {
    storeToken(res.data.user.token);
  
    return createProfileInfoObj(res.data.user.profile);
  } else {
    return res;
  }
}
