import { RoadTravelModel, createRoadTravelObject } from "./roadTravelModel";
import { FerryTravelModel, createFerryTravelObject } from "./FerryTravelMode";
import { FlightTravelModel } from "./flightTravelModel";
import {
  AccommodationModel,
  createAccommodationObject,
} from "./AccomodationModel";
import { SightSeeingModel, createSightSeeingObject } from "./sightSeeingModel";
import { Activities } from "core/Constants";

export type ActivityListType =
  | RoadTravelModel
  | FerryTravelModel
  | FlightTravelModel
  | AccommodationModel
  | SightSeeingModel;

export interface ActivityModel {
  type: "activity";
  day: number;
  activityList: ActivityListType[];
}

export function instanceOfDayModel(data: any): data is ActivityModel {
  return data.type === "activity";
}

export function createActivityObject(data: any, day: number): ActivityModel {
  const activity: ActivityModel = {
    type: "activity",
    day: day,
    activityList: [],
  };
  const keyList = Object.keys(data);
  keyList.forEach((key) => {
    data[key].forEach((data: any) => {
      if (
        data.activity_type === Activities.TRAVEL_BY_CAR ||
        data.activity_type === Activities.TRAVEL_BY_BUS
      ) {
        const carTravel: RoadTravelModel = createRoadTravelObject(
          data,
          data.activity_type === Activities.TRAVEL_BY_CAR
            ? Activities.TRAVEL_BY_CAR
            : Activities.TRAVEL_BY_BUS
        );
        activity.activityList.push(carTravel);
      }
      if (data.activity_type === Activities.ACCOMMODATION) {
        const accommodation: AccommodationModel =
          createAccommodationObject(data);
        activity.activityList.push(accommodation);
      }

      if (data.activity_type === Activities.SIGHTSEEING) {
        const sightSeen: SightSeeingModel = createSightSeeingObject(data);
        activity.activityList.push(sightSeen);
      }
      if (data.activity_type === Activities.TRAVEL_BY_FERRY) {
        const ferryTravel: FerryTravelModel = createFerryTravelObject(data);
        activity.activityList.push(ferryTravel);
      }
    });
  });
  return activity;
}
