import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
// import NcInputNumber from "components/NcInputNumber/NcInputNumber";
// import CheckBox from "newCom/CheckBox.js";
import Call from "images/Call.png";
import Andaman from "images/Andaman.png";
import EnquiryModal from "components/Enquiry/EnquiryModal";
import whatsappSvg from "images/whatsappsvg.svg";
import gearSvg from "images/gear.svg";
export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-6xl !leading-[114%] ">
            Experience the Awesomeness of
          </h2>
          <img src={Andaman} alt="" />
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Explore the world with us. Discover unique destinations, plan your
            trips and create unforgettable memories. Start your adventure today!
          </span>
          <div className="sm:flex items-start sm:items-center  w-full">
            <div className="flex items-center">
              <a href="tel:+917400013770">
                <ButtonPrimary className="bg-[#F19838]">
                  <img src={Call} alt="" />
                  <span className="ml-2">Enquiry for new trip</span>
                </ButtonPrimary>
              </a>
              <a href="https://api.whatsapp.com/send/?phone=7400013770" target="_blank" rel="noreferrer">
                <button className="ml-4">
                  <img src={whatsappSvg} alt="whatsapp" />
                </button>
              </a>
            </div>
            <button onClick={()=>setOpen(true)} className=" w-full sm:w-auto font-semibold text-gray-700 rounded-3xl shadow-md sm:ml-4 px-8 py-2 sm:mt-0 mt-8  lg:hidden flex items-center justify-between">
              <span className="text-base ">Customize Your Trip</span>
              <div className="bg-[#F19838] rounded-full p-3 ml-12">
                <img src={gearSvg} alt="trip" height="15" width="15" />
              </div>
            </button>
          </div>
          <EnquiryModal showModal={open} setShowModal={setOpen} />
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
