import axios from "axios";
import { HttpResponse } from "models/HttpStatusModel";
import { getToken } from "utils/tokenhandler";

/**
 * Sends a request to the API.
 *
 * @param {string} api - The API endpoint URL.
 * @param {string} method - The HTTP method for the request.
 * @param {Object | File} data - The data payload for the request. For GET requests, this field works as parameters.
 * @param {boolean} authorized - Determines if the request should include an authorization token.
 *                              `true` indicates that the token will be passed when fetching data from the API.
 */

export async function asyncHandler(
  api: string,
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  data?: any,
  authorized: boolean = false
) {
  try {
    switch (method) {
      case "POST":
        return await axios.post(api, data, {
          headers: {
            ...handleAxiosAuthorization(authorized),
          },
        });
      case "PUT":
        return await axios.put(api, data, {
          headers: {
            ...handleAxiosAuthorization(authorized),
          },
        });
      case "PATCH":
        return await axios.patch(api, data, {
          headers: {
            ...handleAxiosAuthorization(authorized),
          },
        });
      case "DELETE":
        return await axios.delete(api, {
          headers: {
            ...handleAxiosAuthorization(authorized),
          },
        });
      default:
        return await axios.get(api, {
          headers: {
            ...handleAxiosAuthorization(authorized),
          },
          params: data,
        });
    }
  } catch (err: any) {
    const errRes: HttpResponse = {
      type: "httpStatus",
      statusCode: err.response.status,
      error: handleError(err.response.data.errors),
      success: null,
    };
    return errRes;
  }
}

function handleAxiosAuthorization(condition: boolean) {
  if (condition) {
    return {
      Authorization: `Token ${getToken()}`,
    };
  } else {
    return {};
  }
}

function handleError(data: object) {
  return Object.values(data)[0];
}
