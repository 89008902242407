import { Checkbox } from "antd"
import { useState } from "react"


const CheckBox = ({ className = "", label,checked = false, handleChange=console.log} )=> {

    const [checke, setChecked] = useState(checked)

    

    return (
        <div className="flex gap-2">
        <Checkbox value={checke} onChange={(e)=>{setChecked(!checke); handleChange(checke)}} />
        {label}
        </div>
    )

}


export default CheckBox

