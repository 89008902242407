import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import axios from "axios";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic1: FC<SectionStatisticProps> = ({ className = "" }) => {
  const [value_one,setValue_one] = useState("")
  const [value_two,setValue_two] = useState("")
  const [value_three,setValue_three] = useState("")
  const [column_one,setColumn_one] = useState("")
  const [column_two,setColumn_two] = useState("")
  const [column_three,setColumn_three] = useState("")
  useEffect(()=>{
    axios.get("/wft_admin/portfolio-list").then((response)=>{
      setValue_one(response.data[0].value_one)
      setValue_two(response.data[0].value_two)
      setValue_three(response.data[0].value_three)
      setColumn_one(response.data[0].column_one)
      setColumn_two(response.data[0].column_two)
      setColumn_three(response.data[0].column_three)
      console.log(response.data)
      console.log(value_one,"USER Story")
    }).catch((error)=>{
      alert(error)
    })
  })
  const FOUNDER_DEMO: Statistic[] = [
    {
      id: "1",
      heading: value_one ,
      subHeading:column_one,
        
    },
    {
      id: "2",
      heading: value_two,
      subHeading: column_two,
    },
    {
      id: "3",
      heading: value_three,
      subHeading: column_three
        
    },
  ];

  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc=" We’re impartial and independent, and every day we create distinctive,
          world-class programmes and content"
          isCenter
      >
        🚀  Fun Facts
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic1;
