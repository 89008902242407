
import React, { Dispatch } from "react";

interface Param {
  hotelType: string;
  setHotelType: Dispatch<React.SetStateAction<string>>;
}

export default function HotelType({ hotelType, setHotelType }: Param) {
  return (
    <div className="overflow-hidden">
      <ul className="flex overflow-y-auto">
        <li
          className={`mx-4 ${
            hotelType === "Port Blair" && "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button onClick={() => setHotelType("Port Blair")}>Port Blair</button>
        </li>
        <li
          className={`mx-4 ${
            hotelType === "Havelock" && "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button onClick={() => setHotelType("Havelock")}>Havelock</button>
        </li>
        <li
          className={`mx-4 ${
            hotelType === "Neil Island" && "bg-yellow-300 font-semibold text-gray-700"
          }  px-6 py-2 rounded-xl`}
        >
          <button onClick={() => setHotelType("Neil Island")}>Neil Island</button>
        </li>
     
      </ul>
    </div>
  );
}
