import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import hotelHero from "images/hotel/hotels.png";
import bedImg from "images/bed.svg";
export default function HotelHero() {
  const navigate = useNavigate();

  return (
    <div>
      <div
        onClick={() => navigate(-1)}
        className="flex justify-center items-center w-[145px] h-[45px] border-2 drop-shadow-md rounded-xl cursor-pointer"
      >
        <div>
          <KeyboardBackspaceIcon />
          <span className="ml-2">Back</span>
        </div>
      </div>
      <div
        className=" sm:text-left text-center sm:grid grid-cols-2 gap-20 mt-11
      "
      >
        <div>
          <img src={bedImg} alt="bed" height="35" width="35" />
          <div>
            <h1 className=" font-semibold text-4xl text-gray-700">
              Best Hotel To Stay
            </h1>
            <p className="text-gray-600 leading-6 mt-3">
              We offers a range of comfortable hotels with stunning ocean views
              and exceptional service. Whether you prefer a luxurious resort or
              a budget-friendly accommodation, we have options for everyone.
            </p>
          </div>
        </div>
        <div className="sm:block hidden">
          <img
            src={hotelHero}
            alt="img"
            height="260"
            width="440"
            className="rounded- object-fill"
          />
        </div>
      </div>
    </div>
  );
}
