export interface HttpResponse {
  type: "httpStatus";
  statusCode: number;
  error: null | string;
  success: null | string;
}

export function instanceOfHttpResponse(data: any): data is HttpResponse {
  return data.type === "httpStatus";
}
