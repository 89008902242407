import React from "react";
/*import Share from "images/Share.png"
import Save from "images/Save.png"*/
// import Kar from "images/Kar.png";
import Cloud from "images/Cloud.png";
import Callender from "images/Callender.png";
import Hotel from "images/Hotel.png";
import Meal from "images/Meal.png";
import { TripPackageModel } from "models/TripPackageModel";
import StartRating from "components/StartRating/StartRating";
import { stringToTripDayAndNight } from "utils/stringToTripDayandNight";
import activityImg from "images/activity/activitylogo.svg";
import BookMarkPackage from "components/TripPackage/BookMarkPackage";

const PackageCard = ({ tripPackage }: { tripPackage: TripPackageModel }) => {
  return (
    <div>
      <div className=" border-2 border-[#E5E7EB] rounded-2xl bg-[#FFFFFF]">
        <div className="flex justify-end mr-4 mt-4">
          <BookMarkPackage packageData={tripPackage} />
        </div>
        <div className="xl:flex md:flex">
          <div className="flex">
            <div className="w-[85px] h-[22px] border-2 m-8 rounded-full">
              <p className="font-normal text-xs ml-3 text-[#41C5F2] text-center">
                {tripPackage.itineraryType}
              </p>
            </div>
            <div className="w-[85px] h-[22px] border-2 mt-8 rounded-full">
              <p className="font-normal text-xs ml-3 text-[#41C5F2] text-center">
                {tripPackage.itinerarySubType}
              </p>
            </div>
          </div>
          {/*<div className='flex'>
                          <img className="xl:ml-[20rem] md:ml-[17rem] xl:mt-8 md:mt-8 ml-8 w-[14px] h-[14px]" src={Share} alt=""/>
                          <div className="xl:ml-3 md:ml-3 xl:mt-7 md:mt-7 ml-6 font-normal text-sm text-[#6B7280]">Share</div>
                          <img className="xl:ml-8 md:ml-8 xl:mt-8 md:mt-8 ml-6 w-[14px] h-[14px]"src={Save} alt=""/>
                          <div className="xl:ml-3 md:ml-3 xl:mt-7 md:mt-7 ml-6 font-normal text-sm text-[#6B7280]">Save</div>
                        </div>*/}
        </div>
        <div className="mx-8 font-semibold text-2xl md:text-3xl text-[#1F2937]">
          {tripPackage.heading}
        </div>
        <div className="flex mt-5 ml-8">
          <StartRating reviewCount={0} point={parseFloat(tripPackage.rating)} />
          <p className="text-[#6B7280] ml-2"> {tripPackage.area}</p>
        </div>
        <div className="flex mt-10 ml-8">
          <div className="ml-2 w-max font-medium text-base text-[#6B7280]">
            Managed by{" "}
            <span className="text-[#1F2937]">{tripPackage.manager}</span>
          </div>
        </div>
        <hr className="xl:w-[720px] md:w-[630px] mt-5 ml-8" />
        <div className="flex sm:flex-row flex-col m-8">
          <div className="flex items-center space-x-2">
            <span className="">
              <img className="las la-bed text-lg" src={Cloud} alt="" />
            </span>
            <span className="text-base text-neutral-500 dark:text-neutral-400 flex">
              {stringToTripDayAndNight(tripPackage.duration).map(
                (data, index) => (
                  <p key={index} className=" mx-1 text-[#6B7280]">
                    {data}
                  </p>
                )
              )}
            </span>
          </div>
          <div className="flex sm:my-0 my-4">
            <div className="flex items-center space-x-2">
              <span className="">
                <img
                  className="las la-bed text-lg sm:ml-11"
                  src={activityImg}
                  height={15}
                  width={15}
                  alt=""
                />
              </span>
              <span className="text-base text-neutral-500 dark:text-neutral-400">
                {tripPackage.numberOfActivities} Activities
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="">
                <img
                  className="las la-bed text-lg ml-4 sm:ml-11"
                  src={Hotel}
                  alt=""
                />
              </span>
              <span className="text-base text-neutral-500 dark:text-neutral-400">
                {tripPackage.accommodationList.length}
              </span>
              <span className="text-base text-neutral-500 dark:text-neutral-400">
                Hotels
              </span>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="">
              <img className="las la-bed text-lg sm:ml-11" src={Meal} alt="" />
            </span>
            <span className="text-base text-neutral-500 dark:text-neutral-400 flex">
              {tripPackage.meal.split(",").map((area, index) => (
                <p key={index} className="mx-2  text-[#6B7280]">
                  {area}
                </p>
              ))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
