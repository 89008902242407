import { message } from "antd";
import { resetPassword } from "controller/forgotPasswordController";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { resetForgotPasswordState } from "store/states/forgotPasswordState";

export default function ForgotPasswordResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { isOtpVerified } = useAppSelector((state) => state.forgotPassword);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isOtpVerified) {
      navigate("/login");
    }
  }, []);

  async function passwordResetHandler() {
    if (password !== "" && password === confirmPassword) {
      setLoading(true);
      const res = await resetPassword(password);
      setLoading(false);
      message.success(res.success);
      dispatch(resetForgotPasswordState());
      navigate("/login");
    } else {
      message.error("password does not match");
    }
  }
  return (
    <div className="container mb-24 lg:mb-32">
      <h1 className="text-center text-3xl font-semibold mt-20">
        Reset Password
      </h1>
      <div className="max-w-md mx-auto mt-9">
        <Input
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
        <div className="mt-4">
          <Input
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
          />
        </div>
        <ButtonPrimary
          disabled={loading}
          onClick={passwordResetHandler}
          className="mt-4 w-full"
        >
          Reset Password
        </ButtonPrimary>
      </div>
    </div>
  );
}
