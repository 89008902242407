import React, { FC } from "react";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategoryBox1Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategoryBox1: FC<CardCategoryBox1Props> = ({
  className = "",
  taxonomy,
}) => {
  const { count, name, thumbnail, href = "/" } = taxonomy;
  return (
    <Link
      to={href}
      className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className} `}
      data-nc-id="CardCategoryBox1"
    >
      {/* <Badge
        className="absolute right-2 top-2"
        color="gray"
        name={convertNumbThousand(count)}
      /> */}

      <div className="relative flex-shrink-0 w-14 h-14 md:h-20 md:w-20  rounded-full">
        <NcImage src={thumbnail} containerClassName="absolute inset-0" className=" w-14 h-14  md:h-auto md:w-auto " />
      </div>
      <div className="ml-4 flex-grow ">
        <h2 className=" text-gray-700 font-semibold">
          <span className="sm:text-sm md:text-base text-xs">{name}</span>
        </h2>
        {/* <span
          className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}
        >
          4 Visiting Spots
        </span> */}
      </div>
    </Link>
  );
};

export default CardCategoryBox1;
