import { Activities } from "core/Constants";

export interface FerryTravelModel {
  heading:string|null;
  type: Activities.TRAVEL_BY_FERRY;
  source: string;
  dest: string;
  date: string;
  description: string;
  ferryType: string;
}

export function instanceOfFerryDetailModel(
  data: any
): data is FerryTravelModel {
  return data.type === Activities.TRAVEL_BY_FERRY;
}

export function createFerryTravelObject(data: any) {
  const result: FerryTravelModel = {
    type: Activities.TRAVEL_BY_FERRY,
    heading:data.heading,
    source: data.ferry_detail_json.source,
    dest: data.ferry_detail_json.dest,
    date: data.ferry_detail_json.date,
    description: data.description,
    ferryType: data.ferry_detail_json.type,
  };
  return result;
}
