import {
  forgotPasswordOtpSendAPI,
  forgotPasswordVerifyOtpAPI,
  resetPasswordAPI,
} from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { HttpResponse, instanceOfHttpResponse } from "models/HttpStatusModel";
import { removeToken, storeToken } from "utils/tokenhandler";

export async function sendForgotPasswordOtp(email: string) {
  const res = await asyncHandler(forgotPasswordOtpSendAPI, "POST", { email });
  if (!instanceOfHttpResponse(res)) {
    storeToken(res.data.token);
    const result: HttpResponse = {
      type: "httpStatus",
      statusCode: res.status,
      error: null,
      success: "OTP is sent to your email",
    };
    return result;
  } else {
    return res;
  }
}

export async function verifyForgotPasswordOtp(email: string, otp: string) {
  const res = await asyncHandler(
    forgotPasswordVerifyOtpAPI,
    "POST",
    { email, otp },
    true
  );
  if (!instanceOfHttpResponse(res)) {
    storeToken(res.data.token);
    const result: HttpResponse = {
      type: "httpStatus",
      statusCode: res.status,
      error: null,
      success: res.data.message,
    };
    return result;
  } else {
    return res;
  }
}

export async function resetPassword(password: string) {
  const res = await asyncHandler(resetPasswordAPI, "POST", { password }, true);
  if (!instanceOfHttpResponse(res)) {
    removeToken();
    const result: HttpResponse = {
      type: "httpStatus",
      statusCode: res.status,
      error: null,
      success: res.data.message,
    };
    return result;
  } else {
    return res;
  }
}
