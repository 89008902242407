
import { hotelApi } from "core/backend-api";
import { asyncHandler } from "core/core-functions";
import { TaxonomyType } from "data/types";
import { HttpResponse, instanceOfHttpResponse } from "models/HttpStatusModel";

export async function getHotelList(hotelType: string) {
  const res = await asyncHandler(
    `${hotelApi}?type_of_accomodation=${hotelType}`,
    "GET"
  );
  const result: [TaxonomyType[] | null, HttpResponse | null] = [null, null];
  if (!instanceOfHttpResponse(res)) {
    const accommodationArr: TaxonomyType[] = [];
    for (let data of res.data) {
      const accommodation: TaxonomyType = {
        id: data.id,
        name: data.name,
        href: "#",
        taxonomy: "category",
        location: `${data.location !== null ? `${data.location}, ` : ""} ${
          data.area !== null ? `${data.area}` : ""
        }`,
        desc: data.accommodation_description,
        thumbnail: data.image,
      };
      accommodationArr.push(accommodation);
    }
    result[0] = accommodationArr;
  } else {
    result[1] = res;
  }
  return result;
}
