import React, { useState }  from 'react'
import { TripPackageModel } from 'models/TripPackageModel'
import EnquiryModal from 'components/Enquiry/EnquiryModal';

interface TotalCardProps{
  tripPackage:TripPackageModel
}

const TotalCard = ({tripPackage}:TotalCardProps) => {
  const [openModal,setOpenModal] = useState(false);
  
  return (
    <div>
      <div className=" xl:mt-0 mt-8 xm:mt-0 h-auto border-2 p-8 border-[#E5E7EB] rounded-2xl bg-[#FFFFFF]">

          <div className='font-semibold text-2xl '>{tripPackage.itineraryType}</div>
      
        <div className=' flex mt-6'>
          <div className='font-semibold text-2xl text-[#111827]'>₹ {tripPackage.price} <span className='font-normal text-sm text-[#6B7280]'>/ {tripPackage.personCount}</span></div>
          {/* <SaleOffBadge className='ml-8'/> */}
        </div>
        <hr className='xl:w-[295px] w-[205px] xl:ml-11 ml-5'/>
        <div className='py-5 sm:px-10'>
          <div className='font-semibold text-base'>Hotels:</div>
          <ul className='list-disc text-[#4B5563]'>
            {
              tripPackage.accommodationList.length>0 && tripPackage.accommodationList.map((item,index) => {
                return (
                  <li className='ml-8 mt-5 break-words' key={index}>{item}</li>
                )
              })
            }
          </ul>
        </div>
        <div className='mt-4'>
          <button className='bg-orange-600 w-full sm:text-lg py-4 text-white rounded-3xl' onClick={()=>setOpenModal(true)}>Book now</button>
        </div>
      </div>
      <EnquiryModal showModal={openModal} setShowModal={setOpenModal} />
    </div>
  )
}

export default TotalCard
