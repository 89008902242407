import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import { BlogModel } from "models/BlogModel/BlogModel";
import CardBadge from "components/blog/CardBadge";
import placeHolderImg from "images/placeholder.jpg";

export interface Card13Props {
  className?: string;
  post: BlogModel;
}

const Card13: FC<Card13Props> = ({ className = "", post }) => {

  return (
    <div className={`nc-Card13 relative flex ${className}`} data-nc-id="Card13">
      <div className="flex flex-col h-full py-2">
        <h2 className={`nc-card-title block font-semibold text-base`}>
          <Link to={`/blog-single/${post.id}`} className="line-clamp-2" title={post.heading}>
            {post.heading}
          </Link>
        </h2>
        <span className="hidden sm:block my-3 text-neutral-500 dark:text-neutral-400 ">
          <span className="line-clamp-2"> {post.description}</span>
        </span>
        <span className="mt-4 block sm:hidden text-sm text-neutral-500 ">
          {post.date}
        </span>
        <div className="mt-4 flex flex-wrap">
          {post.tagList !== null && post.tagList.length > 0 ?
            post.tagList.map((data, index) => (
              <CardBadge value={data} index={index} key={index} />
            )):""}
        </div>
        <div className="mt-4 flex items-center">
          <img
            src={
              post.authorImage ? post.authorImage : placeHolderImg
            }
            alt={post.authorName}
            height="40"
            width="40"
            className="rounded-full"
          />
          <p className="text-sm font-semibold ml-2">{post.authorName}</p>
        </div>
      </div>

      <Link
        to={`/blog-single/${post.id}`}
        className={`block relative h-full flex-shrink-0 w-2/5 sm:w-1/3 ml-3 sm:ml-5`}
      >
        <NcImage
          containerClassName="absolute inset-0 "
          className="object-cover w-full h-full rounded-xl sm:rounded-3xl"
          src={post.imageOne}
          alt={post.heading}
        />

      </Link>
    </div>
  );
};

export default Card13;
