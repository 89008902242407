import axios from 'axios'
import React, { useEffect, useState } from 'react'

export interface Statistic {
        id: string;
        heading: string;
        subHeading: string;
      }

const TermsConditionPage = () => {

        const [heading_one,setHeading_one] = useState("")
        const [heading_two,setHeading_two] = useState("")
        const [heading_three,setHeading_three] = useState("")
        const [heading_four,setHeading_four] = useState("")
        const [heading_five,setHeading_five] = useState("")
        const [heading_six,setHeading_six] = useState("")
        const [heading_seven,setHeading_seven] = useState("")
        const [heading_eight,setHeading_eight] = useState("")
        const [content_one,setContent_one] = useState("")
        const [content_two,setContent_two] = useState("")
        const [content_three,setContent_three] = useState("")
        const [content_four,setContent_four] = useState("")
        const [content_five,setContent_five] = useState("")
        const [content_six,setContent_six] = useState("")
        const [content_seven,setContent_seven] = useState("")
        const [content_eight,setContent_eight] = useState("")
        const [cancellation,setCancellation] = useState("")
        const [content_cancellation,setContent_cancellation] = useState("")

        useEffect(()=>{
                axios.get("/wft_admin/termsandconditions-list").then((response)=>{
                  setHeading_one(response.data[0].heading_one)
                  setHeading_two(response.data[0].heading_two)
                  setHeading_three(response.data[0].heading_three)
                  setHeading_four(response.data[0].heading_four)
                  setHeading_five(response.data[0].heading_five)
                  setHeading_six(response.data[0].heading_six)
                  setHeading_seven(response.data[0].heading_seven)
                  setHeading_eight(response.data[0].heading_eight)
                  setContent_one(response.data[0].content_one)
                  setContent_two(response.data[0].content_two)
                  setContent_three(response.data[0].content_three)
                  setContent_four(response.data[0].content_four)
                  setContent_five(response.data[0].content_five)
                  setContent_six(response.data[0].content_six)
                  setContent_seven(response.data[0].content_seven)
                  setContent_eight(response.data[0].content_eight)
                  setCancellation(response.data[0].cancellation)
                  setContent_cancellation(response.data[0].content_cancellation)
                  console.log(response.data)
                  console.log(heading_one,"Terms and Condition")
                }).catch((error)=>{
                  alert(error)
                })
              },[])
              const FOUNDER_DEMO: Statistic[] = [
                {
                  id: "1",
                  heading: "Hotel Check-in & Check-out:",
                  subHeading:"The Check in and check out time of the hotel is 1200 hrs and 0900 hrs at Port Blair/Havelock/ Neil Island; stay beyond the check-out time is purely at the discretion of the hotels. (In Havelock, there may be a time gap between check out and ferry timings, guests are advised to leave their luggage in the hotel and venture out for some optional activity till the ferry timing). For early check in and late check out the payment is to be settled directly by the guest."
                  ,
                    
                },
                {
                  id: "2",
                  heading: "Breakfast & Meals:",
                  subHeading: "The standard time for breakfast in restaurants across Andaman is from 7:15am onwards to 10:00am. Packed breakfast is given to the guest from the hotel in case they leave the hotel in early morning. Numbers of meals are always corresponding to the number of nights booked. Breakfast is not provided on the day of arrival. The breakfast on the first day is given on the next day morning.",
                },
                {
                  id: "3",
                  heading: "Booking Confirmation:",
                  subHeading: "Hotels once confirmed cannot be changed. Guests are requested to check the reviews, pictures and hotel websites before confirming the hotel and advise any change at the time of booking confirmation. The itinerary is fixed and cannot be modified.Suggested hotels are subject to availability at the time of booking. In case the same is not available, then a similar category hotel will be provided."
                  
                    
                },
                {
                  id: "4",
                  heading: "Hotel Policies",
                  subHeading: "A maximum of 3 adults are allowed in one room. The third occupant shall be provided a mattress/rollaway bed. Children between (0 – 5yrs) are generally considered complimentary. Children between (5-12 yrs) are charged on basis of child with/without extra mattress basis. Children above 12yrs are considered adults."
                    
                },
                {
                  id: "5",
                  heading: "Transportation & Services: ",
                  subHeading: "Transportation shall be provided as per the itinerary and will not be at disposal. The vehicle used (A/C or Non A/C) is available for point to point services only and is not at disposal. Guests are requested to follow the timings in the program. Dinner/Shopping trips will be chargedINR 350 extra (depending on distance).Smoking and drinking is not allowed in the vehicle. Guest are suggested to carry towel from hotel if they wish to go for swim. Siting with drenched cloths are not allowed inside car." 
                    
                },
                {
                  id: "6",
                  heading: "Change in Itinerary:",
                  subHeading: "Wayfind Trips Pvt. Ltd reserves the rights to re-arrange itinerary to suit hotel availability without changing the total number of nights in each destination and without compromising any services.due to reasons including but not limited to: Force Majeure events, strikes, fairs, festivals, weather conditions, traffic problems, overbooking of hotels / flights, cancellation / re- routing of flights, closure of /entry restrictions at a place of visit, etc. While we will do our best to make suitable alternate arrangements, we would not be held liable for any refunds/compensation claims arising out of this." 

                },
                {
                  id: "7",
                  heading: "Ferry Changes & Cancellations:",
                  subHeading: "Ferry timings are subject to change at the government's discretion, and we assume no liability for disruption in the tour owing to climatic conditions or any such events beyond our control. Rest assured, we will do our best to implement your itinerary to the fullest and provide you with commensurate back-up options. The cost of the same will need to be borne by the guest. Ferry services & timings are subjected to weather condition. Ferry Operation is purely subjected to Govt. clearance.In case the Private ferries could not be booked, the tickets will be transferred to Govt ferries. In case of any reschedule or change of destination, the travelling guest have to bear the cost or chose another itinerary that suffices their need. The refund for cancellation depends on the advance paid during the time of booking. Passengers furnishing incorrect age details may incur penalty at the time of travelling NOTE: All packages that include ferry tickets are of the base category seats of the ferry/vessel."
                    
                },
                {
                  id: "8",
                  heading: "Speedboat & Services:",
                  subHeading: "The boats to places of visit are managed by a boat association of respective places who need individual guest to fill a consent form after which boats are allotted based on rotation and sharing basis. Due to limited boats guest may have to wait until their boat is allotted and we have no control over the wait time."
                    
                },
                {
                  id: "9",
                  heading: "Travel Documents:",
                  subHeading: "Travel documents used during the time of booking needs to carried in original during the time of visit. Id with photo and address are only considered as valid id proof. It is needed to be carried in hard copy during boarding ferries and places of visit such as cellular jail. It is mandatory to carry the age proof of children and infant (below 02yrs) along with other travel documents. Child without address proof shall be considered for an adult cost."
                    
                },
              ];
  return (
    <div className='nc-PageHome relative overflow-hidden'>
      <div className="container relative space-y-20 mb-24 lg:space-y-15 lg:mb-28">
          <div className='flex flex-col justify-center items-center '>
        <div className='not-italic font-bold text-5xl text-[#303A42] mt-16'>Terms and Conditions</div>
        </div>
        {FOUNDER_DEMO.map((item) => (
                <div key={item.id}>
                        <div className='flex'>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-11'>{item.id}. </div>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-5'>{item.heading}</div>
                        </div>
                        <div className='font-normal text-lg h-max text-[#000000] mt-11 break-words md:mt-5 ml-11'>{item.subHeading}</div>
                </div>
        ))}
        <div>
        <div className='flex'>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-11'>10. </div>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-5'>Timing & Opening: </div>
                        </div>
                        <div className='font-normal text-lg h-max text-[#000000] break-words ml-11 mt-4'>
                          Entry to Cellular jail and Museum closes by 1600hrs and light n sound show is open every day 5:20hrs.
                          <ol className="list-decimal ml-11 mt-4">
                            <li className='break-words'>Light n sound is subjected to weather conditions and tickets issued are non-refundable by Andaman & Nicobar administration.</li>
                            <li className='mt-3'>For English show are only on Mondays, Wednesdays & Fridays.</li>
                          </ol>
                          <div className='mt-5 break-words'>Baratang trips are done in early morning 3:30am onwards. Guest are advised to be prepared and be ready as per scenario.</div>

                        </div>  
        </div>
        <div>
          <div className='flex'>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-11'>11. </div>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-5'>Activities & Refund:</div>
                        </div>
                        <div className='font-normal text-lg h-max text-[#000000] break-words ml-11 mt-4'>For any paid activity booked through us  and which is non-operational due to any unforeseen reason, we will process refund & same should reach the guest within 7 days of processing the refund. Cancellation of any place of visit 4 hours prior to the time of visit will not be refunded as the vehicle was booked for the day for the same trip. 
For any activity which is complimentary and not charged to Wayfind trips & guest, no refund will be processed. 
</div>
</div>
        <div>
          <div className='flex'>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-11'>12. </div>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-5'>Extra Expenses: </div>
                        </div>
                        <div className='font-normal text-lg h-max text-[#000000] break-words ml-11 mt-4'>The package price doesn’t include special dinner or mandatory charges at time levied by the hotels especially during New Year and Christmas or any special occasions. The same will be intimated to the traveling guest by Wayfind travel experts during their date of travel. 
The booking price does not include: Expenses of personal nature, such as laundry, telephone calls, room service, alcoholic beverages, mini bar charges, tips, portage, camera fees etc. 

</div>
</div>
        <div>
          <div className='flex'>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-11'>13.</div>
                          <div className='not-italic font-semibold text-xl text-[#303A42] ml-5'>Challenges of Andaman:</div>
                        </div>
                        <div className='font-normal text-lg h-max text-[#000000] break-words ml-11 mt-4'>Due to restriction on new vehicles on the islands by State Transport Department Of Andaman, you may be provided relatively older vehicles for transfers/sightseeing. 
 
</div>
</div>
        
      </div>
        
    </div>
    
  )
}

export default TermsConditionPage
