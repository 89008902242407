import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { placeHolderImages } from "data/placeHolderImages";
import { TripPackageModel } from "models/TripPackageModel";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import { stringToTripDayAndNight } from "utils/stringToTripDayandNight";
import BookMarkPackage from "./BookMarkPackage";
import CancelTripModal from "./CancelTripModal";
import moment from "moment";

export default function TripPackageCard({
  data,
  removeBookMarkAction,
  showCancelButton = false,
}: {
  data: TripPackageModel;
  removeBookMarkAction?: (data: TripPackageModel) => void;
  showCancelButton?: boolean;
}) {
  const [showTripCancelModal, setShowTripCancelModal] = useState(false);
  return (
    <div>
      <div
        className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
        data-nc-id="PropertyCardH"
      >
        <div className="h-full w-full flex flex-col sm:flex-row">
          <div className="flex-shrink-0 p-3 w-full sm:w-64">
            <GallerySlider
              ratioClass="aspect-w-1 aspect-h-1"
              galleryImgs={data.images ? data.images : placeHolderImages}
              className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
              uniqueID={`PropertyCardH_${data.id}`}
              href={"#"}
            />
          </div>

          <div className="flex-grow p-3 sm:pr-6  h-full">
            <div className="flex justify-end">
              <BookMarkPackage
                packageData={data}
                removeBookMarkAction={removeBookMarkAction}
              />
            </div>
            <Link to={`/booktrip/${data.id}`}>
              <div className="flex items-center justify-between space-x-3">
                <div>
                  <p className="text-sm text-[#6B7280]">{data.area}</p>
                  <h1 className="text-xl font-semibold">{data.heading}</h1>
                </div>
                <StartRating reviewCount={0} point={parseFloat(data.rating)} />
              </div>
            </Link>
            <h2 className="space-x-2 text-sm font-medium capitalize mt-2">
              <span className="line-clamp-2 text-[#6B7280] ">
                {data.description}
              </span>
            </h2>
            <div className=" flex text-sm mt-4">
              {data.meal.split(",").map((area, index) => (
                <p key={index} className="mx-2  text-xs text-[#6B7280]">
                  {area}
                </p>
              ))}
            </div>
            <div className="flex text-sm mt-2">
              {stringToTripDayAndNight(data.duration).map((data, index) => (
                <p key={index} className="mx-2  text-xs text-[#6B7280]">
                  {data}
                </p>
              ))}
            </div>
            <div className="flex w-full  items-center mt-4">
              <Badge
                name={
                  <div>
                    <span className="ml-1">{data.itineraryType}</span>
                  </div>
                }
              />
              <Badge
                className="ml-4"
                name={
                  <div>
                    <span className="ml-1">{data.itinerarySubType}</span>
                  </div>
                }
                color="yellow"
              />
            </div>
            {data.cancelPayment && <p className="mt-4 font-semibold text-green-600 text-xs">{data.cancelPaymentamount} INR refunded on {moment(data.cancelPaymentDate).format("DD/MM/YYYY")}</p>}
            <div className="flex justify-end mt-4">
              {showCancelButton && (
                <button
                  className="border-2 border-red-600 px-2 rounded-md bg-red-100 text-red-600 mr-2"
                  onClick={() => setShowTripCancelModal(true)}
                >
                  cancel request
                </button>
              )}
              <p className="border-2 text-green-600 font-semibold border-green-600 px-6 py-2 rounded-md">
                &#8377; {data.price}
              </p>
            </div>
          </div>
        </div>
      </div>
      <CancelTripModal
        showModal={showTripCancelModal}
        setShowModal={setShowTripCancelModal}
        tripPackage={data}
      />
    </div>
  );
}
