import React from 'react'
import FAQPage2 from './FAQPage2'
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import { TaxonomyType } from "data/types";
import neilImg from "images/discoverplaces/neil/neil-sm.jpg";
import havelockImg from "images/discoverplaces/havelock/havelock-sm.jpg";
import jollybuoyImg from "images/discoverplaces/jollybuoy/jollybouy-sm.jpg";
import portBlairImg from "images/discoverplaces/portblair/portblair-sm.jpg";
import ranghatImg from "images/discoverplaces/ranghat/rangat-sm.jpg";
const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Lorem ipsum dolor sit amet, sale imperdiet cu mea ",
    taxonomy: "category",
    count: 17288,
    thumbnail: neilImg,
    listingType: "experiences",
  },
  {
    id: "2",
    href: "#",
    name: "Lorem ipsum dolor sit amet, sale imperdiet cu mea",
    taxonomy: "category",
    count: 2118,
    thumbnail: havelockImg,
    listingType: "experiences",
  },
  {
    id: "3",
    href: "#",
    name: "Lorem ipsum dolor sit amet, sale imperdiet cu mea",
    taxonomy: "category",
    count: 36612,
    thumbnail: jollybuoyImg,
    listingType: "experiences",
  },
  {
    id: "5",
    href: "#",
    name: "Lorem ipsum dolor sit amet, sale imperdiet cu mea",
    taxonomy: "category",
    count: 188288,
    thumbnail: portBlairImg,
    listingType: "experiences",
  },
  {
    id: "4",
    href: "#",
    name: "Lorem ipsum dolor sit amet, sale imperdiet cu mea",
    taxonomy: "category",
    count: 188288,
    thumbnail: ranghatImg,
    listingType: "experiences",
  },
];

const FAQPage1 = () => {
  return (
    <div>
      <div className='container relative space-y-24 lg:space-y-28 mt-16'>
        <FAQPage2/>
      </div>

      <div className="relative py-16 px-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Helpful Blogs clear all you doubts "
            subHeading="Explore thousands of destinations around the world"
            categoryCardType="card41"
            itemPerRow={4}
            categories={DEMO_CATS}
            sliderStyle="style2"
            uniqueClassName="ListingExperiencesPage"
          />
        </div>
{/* 
      <div className='relative py-16 px-16'>
        <PageContact/>
      </div> */}

    </div>
  )
}

export default FAQPage1
